import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { PatientInfosStoreProps, PatientInfosDispatchProps } from './PatientInfos.model'
import { bottomPanelContactsActions } from '../../../store/ui/bottomPanelContacts'
import { bottomPanelDrugsActions } from '../../../store/ui/bottomPanelDrugs'
import { bottomPanelPosologyActions } from '../../../store/ui/bottomPanelPosology/bottomPanelPosology.actions'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'
import { domainPrescriptionActions } from '../../../store/domain/prescription'
import {
  patientCurrentTreatmentsPageSelector,
  uiPatientCurrentTreatmentsActions,
} from '../../../store/ui/patientCurrentTreatments'
import { patientCurrentTreatmentsActions } from '../../../store/cache/patientCurrentTreatments'
import { medicalEventUiActions } from '../../../store/ui/medicalEvents'
import { patientManualPrescriptionsActions } from '../../../store/cache/patientManualPrescriptions'
import {
  patientManualPrescriptionsPageSelector,
  uiPatientManualPrescriptionsActions,
} from '../../../store/ui/patientManualPrescriptions'
import { PatientInfos } from './PatientInfos.component'
import { clipboardActions } from '../../../store/clipboard'

const mapStateToProps = (state: RootState): PatientInfosStoreProps => ({
  currentTreatments: patientCurrentTreatmentsPageSelector(state),
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
  manualPrescriptions: patientManualPrescriptionsPageSelector(state),
})
const mapDispatchToProps: PatientInfosDispatchProps = {
  openEditContactForm: bottomPanelContactsActions.openEditContactForm,
  getCurrentTreatments: uiPatientCurrentTreatmentsActions.getCurrentTreatments,
  clearCurrentTreatments: uiPatientCurrentTreatmentsActions.clearPagination,
  deleteCurrentTreatment: patientCurrentTreatmentsActions.actions.apiDeleteItem,
  openPosolosyBottomPanel: bottomPanelPosologyActions.open,
  openPosologyBottomPanelFromPrescription:
    domainPrescriptionActions.openPosologyBottomPanelFromPrescription,
  searchDrug: bottomPanelDrugsActions.searchDrug,
  selectMedicalEventDocument: medicalEventUiActions.selectMedicalEventDocument,
  terminateTreatment: uiPatientCurrentTreatmentsActions.terminateTreatment,
  getManualPrescriptions: patientManualPrescriptionsActions.actions.getPaginatedItems,
  clearManualPrescriptions: uiPatientManualPrescriptionsActions.clearPagination,
  copyTextToClipboard: clipboardActions.copyTextToClipboard,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientInfos)

export { withConnect as PatientInfos }
