import { RootState } from '../../reducers'
import { AnswerValue } from '../../../model/AnswerValue'
import { IdentityIdentifier } from '../../restux/restux.model'

export const documentAnswerValuesSelector = (state: RootState) => state.domain.documentAnswerValues

export const medicalEventAnswerValuesLoadingSelector = (
  state: RootState,
  documentId: IdentityIdentifier,
) => {
  const answerValues = documentAnswerValuesListSelector(state, documentId)
  return answerValues === undefined
}

export const documentAnswerValuesListSelector = (
  state: RootState,
  documentId: IdentityIdentifier,
): ReadonlyArray<AnswerValue> | undefined => {
  const answerValuesResource = documentAnswerValuesSelector(state)

  const answerValues = answerValuesResource[documentId]

  return answerValues
}

export const createAnswerValuesByQuestionIdSelector =
  (documentId: number, questionIds: number[]) => (state: RootState) => {
    const answerValues = documentAnswerValuesListSelector(state, documentId)
    if (!answerValues) {
      return undefined
    }
    return answerValues.filter((answerValue) => questionIds.includes(answerValue.questionId))
  }

export const createAnswerValuesByAnswerIdsSelector =
  (documentId: number, answerIds: number[]) => (state: RootState) => {
    const answerValues = documentAnswerValuesListSelector(state, documentId)
    if (!answerValues) {
      return undefined
    }
    return answerValues.filter((answerValue) => answerIds.includes(answerValue.answerId))
  }
