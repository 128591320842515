import React, { FunctionComponent } from 'react'
import { IconButton } from '../../../components/shared'
import { CommonVariableActions } from '../CommonVariableActions'
import { QuestionVariableConfigurationProps } from './QuestionVariableConfiguration.model'
import styles from './QuestionVariableConfiguration.module.scss'

export const QuestionVariableConfiguration: FunctionComponent<
  QuestionVariableConfigurationProps
> = ({ variableId, renderTitle, readonly, onConfigureQuestion, ...restProps }) => (
  <CommonVariableActions {...restProps}>
    <div className={styles.top}>
      {renderTitle}
      {onConfigureQuestion && !readonly && (
        <div className="ml-2">
          <IconButton
            onClick={() => onConfigureQuestion(variableId)}
            icon="gear"
            size="nano"
            theme="primary"
          />
        </div>
      )}
    </div>
  </CommonVariableActions>
)
