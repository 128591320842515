import { HookQueryOptions, QueryOptions } from './Query.model'

export const queryOptionsMapper = (options: HookQueryOptions): QueryOptions => {
  const { infinityStale, ...reactQueryOptions } = options

  return {
    ...reactQueryOptions,
    ...(infinityStale && { staleTime: Infinity }),
  }
}
