import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { deleteQuestionnaireTemplate } from '../../../data/questionnaireTemplates'

interface QuestionnaireTemplateMutateVariable {
  questionnaireTemplateId: number
}

export const useDeleteQuestionnaireTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, QuestionnaireTemplateMutateVariable>(
    async ({ questionnaireTemplateId }) => {
      const { ok } = await deleteQuestionnaireTemplate(questionnaireTemplateId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression du questionnaire a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { questionnaireTemplateId }) => {
        queryClient.invalidateQueries(questionnaireTemplatesKeys.lists)
        queryClient.removeQueries(questionnaireTemplatesKeys.detail(questionnaireTemplateId))
      },
    },
  )
}
