import { connect } from 'react-redux'
import { PatientDetailsPage } from './PatientDetailsPage.component'
import { PatientDetailsDispatchProps } from './PatientDetailsPage.model'
import { bottomPanelResourceHistoryActions } from '../../../../store/ui/bottomPanelResourceHistory'

const mapDispatchToProps: PatientDetailsDispatchProps = {
  openBottomPanelResourceHistory: bottomPanelResourceHistoryActions.open,
}
const withConnect = connect(null, mapDispatchToProps)(PatientDetailsPage)
export { withConnect as PatientDetailsPage }
