import styled from '../../../../styles/legacy/styled/styled'

export const FormFooter = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  bottom: 0;
  padding: 0.5rem 1rem;
  background-color: var(--fw-color-shades-shade7);

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  @media ${({ theme }) => theme.mediaQueries.notMobile} {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0 auto;
    width: calc(100% - (2 * 20px));
  }
`
