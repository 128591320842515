import { AnswerInstanceResource, AnswerInstanceSerializedResource } from './answerInstance.model'
import { parseToValueJSON } from '../../../misc/editor.utilities'
import { serializeQuestionTemplate } from '../../questionTemplates/mapper/questionTemplates.mapper'
import { ApiResponse } from 'apisauce'
import { QuestionTemplateResource } from '../../questionTemplates/mapper/questionTemplates.model'
import { AnswerTemplate } from '../../../model/Questionnaire'
import { deserializeQuestionInstances } from '../../../store/cache/questionInstances/api/QuestionInstance.mapper'

export function deserializeAnswerInstance(answerTemplate: AnswerInstanceResource): AnswerTemplate {
  return {
    ...answerTemplate,
    textOutput: answerTemplate.textOutput ? parseToValueJSON(answerTemplate.textOutput) : undefined,
    childQuestions: answerTemplate.childQuestions
      ? deserializeQuestionInstances(answerTemplate.childQuestions)
      : undefined,
  }
}

export function deserializeAnswerInstanceResponse(
  response: ApiResponse<AnswerInstanceResource>,
): ApiResponse<AnswerTemplate> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeAnswerInstance(data),
  }
}

export function serializeAnswerInstance(
  answerTemplate: Partial<AnswerTemplate>,
): Partial<AnswerInstanceSerializedResource> {
  const { imageFile, ...values } = answerTemplate
  return {
    ...values,
    textOutput:
      answerTemplate.textOutput === null ? null : JSON.stringify(answerTemplate.textOutput), // valeur "null" sert à supprimer le texte géneré de l'API
    imageFileId: imageFile ? imageFile.id : imageFile,
    childQuestions: answerTemplate.childQuestions
      ? (answerTemplate.childQuestions.map(
          serializeQuestionTemplate,
        ) as unknown as QuestionTemplateResource[])
      : undefined,
  }
}
