import React, { FunctionComponent, useMemo, useState } from 'react'

import { isDefined } from '../../../../misc/functions.utilities'
import { QuestionWrapper } from '../../atoms/QuestionWrapper/index'
import { QuestionTitle } from '../../atoms/QuestionTitle'

import { TextLibreProps } from './TextLibre.model'
import { TextArea } from '../../../shared'
import { debounce } from 'lodash'

export const TextLibre: FunctionComponent<TextLibreProps> = ({
  mode,
  onEdit,
  onChangeQuestionImportance,
  onDelete,
  question,
  answerValue,
  setAnswerValue,
  disabled,
  isReadonly,
}) => {
  const [value, setValue] = useState<TextLibreProps['answerValue']>()

  const debouncedUpdate = useMemo(
    () =>
      debounce((value: string | null) => {
        if (value !== null && value !== answerValue) {
          setAnswerValue(value)
        }
      }, 400),
    [answerValue, setAnswerValue],
  )

  function handleChangeValue(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const newValue = event.currentTarget.value
    const isInitialChangeValue = value === undefined && newValue === ''
    if (!isInitialChangeValue) {
      setValue(newValue)
      debouncedUpdate(newValue)
    }
  }

  return (
    <QuestionWrapper
      mode={mode}
      question={question}
      filled={isDefined(answerValue)}
      title={<QuestionTitle question={question} />}
      onDelete={isReadonly ? undefined : onDelete}
      onEdit={isReadonly ? undefined : onEdit}
      onChangeQuestionImportance={isReadonly ? undefined : onChangeQuestionImportance}
    >
      <TextArea
        name={question.title}
        testId="text_area-question"
        onChange={handleChangeValue}
        disabled={disabled}
        value={value ?? answerValue ?? undefined}
        placeholder="Votre réponse"
        autoResize
      />
    </QuestionWrapper>
  )
}
