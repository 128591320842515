import { Reducer, AnyAction } from 'redux'

export interface ReducerHandler<TState> {
  [type: string]: Reducer<TState, any> // Need to set any to support the weird object format reducer
}

export function handlersReducer<TState>(
  actionTypes: { [key: string]: string },
  reduxHandler: Reducer<TState, AnyAction>,
): ReducerHandler<TState> {
  return Object.values(actionTypes).reduce(
    (acc, actionType) => ({ ...acc, [actionType]: reduxHandler }),
    {},
  )
}

const createReducer =
  <TState>(initialState: TState, handlers: ReducerHandler<TState>) =>
  (state = initialState, action: AnyAction): TState => {
    const caseReducer = handlers[action.type]
    return caseReducer ? caseReducer(state, action) : state
  }

export default createReducer
