import styled from '../../../../../styles/legacy/styled/styled'
import { GridElement } from '../GridElement'

// Comme pour le GridLayout, sert dans les cas où le margin intégré aux composants (notamment SelectInput)
//  gêne une mise en page propre :c
export const GridElementWithoutChildMargin = styled(GridElement)`
  & > div {
    margin: 0;
  }
`
