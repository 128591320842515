import { PropsWithChildren, useMemo } from 'react'
import classNames from 'classnames/bind'
import ReactDOM from 'react-dom'
import { BOTTOM_PANEL_CONTENT_ROOT, BottomPanelOwnProps } from './BottomPanel.model'
import styles from './BottomPanel.module.scss'
import { TopBar } from './TopBar'
import { useTimeout, useToggle } from 'react-use'
import { useScrollLock } from '../../../../hooks/utils/useScrollLock.hook'

const cx = classNames.bind(styles)

export const BottomPanel = ({
  isUnmounting,
  children,
  title,
  onRequestClose,
  actions,
  displayCloseButton = true,
  rootId = 'bottom-panel-root',
  hint,
  hintTooltip,
}: PropsWithChildren<BottomPanelOwnProps>) => {
  const [isMaximized, toggleMaximized] = useToggle(false)
  const root = useMemo(() => document.getElementById(rootId), [rootId])

  // Pas idéal, voir https://fwhealth.atlassian.net/browse/DEV-3824
  // Force le composant à se rendre deux fois au montage, déclenchant la transition CSS au montage
  const [hasRenderedTwice] = useTimeout(25)

  useScrollLock(true)

  const hidden = isUnmounting || !hasRenderedTwice()

  return root
    ? ReactDOM.createPortal(
        <>
          <div className={cx(styles.overlay, { hidden })} />
          <div
            className={cx(styles.container, {
              hidden,
              maximized: isMaximized,
            })}
          >
            <TopBar
              hidden={isUnmounting}
              onRequestClose={onRequestClose}
              onRequestExpand={toggleMaximized}
              maximized={isMaximized}
              actions={actions}
              title={title}
              displayCloseButton={displayCloseButton}
              hint={hint}
              hintTooltip={hintTooltip}
            />
            <div
              id={BOTTOM_PANEL_CONTENT_ROOT}
              className={cx(styles.content, { hidden: isUnmounting, maximized: isMaximized })}
            >
              {children}
            </div>
            <div className={cx(styles.mobileActions, { hidden: isUnmounting })}></div>
          </div>
        </>,
        root,
      )
    : null
}
