import { format, parse } from 'date-fns'
import { Patient, Sex, Title } from '../../model/Patient'
import { StellairVitalCardInfos } from '../../model/Stellair'

export const parseStellairCardInfos = (
  cardInfo: StellairVitalCardInfos,
): Array<Partial<Patient>> => {
  const beneficiaries = cardInfo.beneficiaire

  return beneficiaries.map((beneficiary) => {
    const patient: Partial<Patient> = {
      inseeNumber: cardInfo.nir.replace(/\s/g, ''),
      birthPlaceCode: cardInfo.nir.replace(/\s/g, '').substring(5, 10),
      birthFirstName: beneficiary.prenom,
      birthLastName: beneficiary.nomFamille ? beneficiary.nomFamille : beneficiary.nomUsuel,
      usedLastName: beneficiary.nomFamille ? beneficiary.nomUsuel : undefined,
      birthDate: format(
        parse(beneficiary.dateNaissanceInterprete, 'dd/MM/yyyy', new Date()),
        'yyyy-MM-dd',
      ),
    }

    if (beneficiary.qualite === 'Assuré') {
      const sexInd = cardInfo.nir.charAt(0)

      return {
        ...patient,
        sex: (sexInd === '1' && Sex.MALE) || (sexInd === '2' && Sex.FEMALE) || Sex.UNKNOWN,
        title: (sexInd === '1' && Title.MR) || (sexInd === '2' && Title.MS) || Title.NONE,
      }
    }

    return patient
  })
}
