const BaseThemes = {
  light: {
    active: {
      colors: {
        default: 'text-shades-2',
        backgroundColor: 'bg-shades-7',
        textColor: 'text-shades-white',
        focusColor: 'focus:ring-shades-2',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:bg-shades-6',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-5',
        backgroundColor: 'bg-shades-7',
        textColor: 'text-shades-white',
        focusColor: 'focus:ring-shades-5',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:bg-shades-5',
      },
    },
  },
  dark: {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-primary-dark',
        textColor: 'text-primary-dark',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-shades-4',
        hover: 'hover:bg-shades-6',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-4',
        backgroundColor: 'bg-shades-2',
        textColor: 'text-shades-2',
        focusColor: 'focus:ring-shades-4',
      },
      borderColors: {
        default: 'border-shades-4',
        hover: 'hover:bg-shades-4',
      },
    },
  },
  transparent: {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-transparent',
        textColor: 'text-transparent',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-shades-4',
        hover: 'hover:bg-shades-5',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-5',
        backgroundColor: 'bg-transparent',
        textColor: 'text-transparent',
        focusColor: 'focus:ring-shades-5',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:bg-shades-5',
      },
    },
  },
  'transparent-light': {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-transparent',
        textColor: 'text-transparent',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-shades-white',
        hover: 'hover:bg-shades-5',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-5',
        backgroundColor: 'bg-transparent',
        textColor: 'text-transparent',
        focusColor: 'focus:ring-shades-5',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:bg-shades-5',
      },
    },
  },
  'transparent-dark': {
    active: {
      colors: {
        default: 'text-shades-2',
        backgroundColor: 'bg-transparent',
        textColor: 'text-transparent',
        focusColor: 'focus:ring-shades-2',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:bg-shades-6',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-5',
        backgroundColor: 'bg-transparent',
        textColor: 'text-transparent',
        focusColor: 'focus:ring-shades-5',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:bg-shades-5',
      },
    },
  },
  primary: {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-primary-default',
        textColor: 'text-primary-default',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-shades-5',
        hover: 'hover:border-shades-6',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-primary-washed',
        textColor: 'text-primary-washed',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-primary-washed',
        hover: 'hover:bg-primary-washed',
      },
    },
  },
  secondary: {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-secondary-default',
        textColor: 'text-secondary-default',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-secondary-light',
        hover: 'hover:bg-secondary-light',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-secondary-washed',
        textColor: 'text-secondary-washed',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-secondary-washed',
        hover: 'hover:bg-secondary-washed',
      },
    },
  },
  warning: {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-status-warning',
        textColor: 'text-status-warning',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-transparent',
        hover: 'hover:bg-opacity-50',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-status-warning bg-opacity-10',
        textColor: 'text-status-warning text-opacity-10',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-transparent',
        hover: 'hover:bg-opacity-50',
      },
    },
  },
  invalid: {
    active: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-status-invalid',
        textColor: 'text-status-invalid',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-transparent',
        hover: 'hover:bg-opacity-50',
      },
    },
    disabled: {
      colors: {
        default: 'text-shades-white',
        backgroundColor: 'bg-status-invalid bg-opacity-10',
        textColor: 'text-status-invalid text-opacity-10',
        focusColor: 'focus:ring-shades-white',
      },
      borderColors: {
        default: 'border-transparent',
        hover: 'hover:bg-opacity-50',
      },
    },
  },
}

export default BaseThemes
