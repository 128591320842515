import { deserializeQuestion } from '@follow/cdk'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'
import { QuestionTemplate } from '../../../../model/Questionnaire'
import {
  QuestionTemplateCreationResource,
  QuestionTemplateResource,
} from './QuestionTemplates.model'

export { deserializeQuestion }

export function deserializeQuestionTemplates(
  questionTemplates: QuestionTemplateResource[],
): QuestionTemplate[] {
  return questionTemplates.map(deserializeQuestion)
}

export function serializeQuestionTemplate({
  answers,
  parentAnswerId,
  ...restProps
}: Partial<QuestionTemplate>): Partial<QuestionTemplateCreationResource> {
  return {
    ...restProps,
    parentAnswerId: parentAnswerId,
    answers: answers && answers.map(({ id }) => id),
  }
}

export function deserializeQuestionTemplateResponse(
  response: ApiResponse<QuestionTemplateResource>,
): ApiResponse<QuestionTemplate> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestion(data),
  }
}

export function deserializeQuestionTemplatesPageResponse(
  questionsPage: ApiResponse<PaginatedList<QuestionTemplateResource>>,
) {
  const { data, ...responseRest } = questionsPage
  // Error case => no deserialization
  if (!questionsPage.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: deserializeQuestionTemplates(data.items) },
  }
}
