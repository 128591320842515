import { ApiResponse } from 'apisauce'
import { parseISO } from 'date-fns'
import { PaginatedList } from '../../../model/Pagination'
import { Treatment } from '../../../model/Treatment'
import { SerializedTreatment } from '../patientTreatments.model'

export const deserializePatientTreatmentsData = (item: SerializedTreatment): Treatment => ({
  ...item,
  startDate: parseISO(item.startDate),
  endDate: parseISO(item.endDate),
})

export const deserializePatientTreatmentsPaginatedResponse = (
  response: ApiResponse<PaginatedList<SerializedTreatment>>,
): ApiResponse<PaginatedList<Treatment>> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializePatientTreatmentsData) },
  }
}
