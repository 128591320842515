/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useContext, useEffect, useState } from 'react'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { PARENT_NODE_ID, IMAGE_NODE_ID } from './ImageResize.model'
import { ResizeHandle } from './ResizeHandle'
import styles from './ImageResize.module.scss'
import classNames from 'classnames'
import { EditorConfigContext } from '@/context'

const BASE64_URL_PATTERN = /data:.*;base64/

export const ImageResizeComponent: FC<NodeViewProps> = ({ node, updateAttributes }) => {
  const { float, textAlign, src, ...imgAttrs } = node.attrs

  const { disabled, authenticatedImageRequest } = useContext(EditorConfigContext)

  const [base64, setBase64] = useState<string | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (BASE64_URL_PATTERN.test(src)) {
      setBase64(src)
    } else {
      const fetcher = authenticatedImageRequest
      fetcher(src)
        .then(setBase64)
        .catch((error) => {
          console.error(error, JSON.stringify(error))
        })
        .finally(() => {
          setLoaded(true)
        })
    }
  }, [authenticatedImageRequest, src])

  const setImageWidth = (width: number) => updateAttributes({ width })

  return (
    <NodeViewWrapper
      className={classNames(
        styles['image_line'],
        styles[`float_${float}`],
        styles[`align_${textAlign}`],
      )}
    >
      {loaded && (
        <>
          {base64 ? (
            <div className={styles['image_resizer']} id={PARENT_NODE_ID}>
              <img
                {...imgAttrs}
                src={base64}
                className={styles['image']}
                id={IMAGE_NODE_ID}
                draggable
                data-drag-handle=""
              />
              {!disabled && (
                <div>
                  <ResizeHandle orientation="right" onDragEnd={setImageWidth} />
                  <ResizeHandle orientation="left" onDragEnd={setImageWidth} />
                </div>
              )}
            </div>
          ) : (
            <span
              title="Erreur lors du chargement de l'image"
              className={styles['error']}
              draggable
              data-drag-handle=""
            >
              !
            </span>
          )}
        </>
      )}
    </NodeViewWrapper>
  )
}
