import { useContext, useMemo } from 'react'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { isAdmin, isAssistant, isDoctor } from '../../../misc/user.utilities'
import {
  PreferencesAdmin,
  PreferencesAssistant,
  PreferencesDoctor,
} from '../../../model/Preference'

type Options = Partial<{
  user: 'current' | 'logged' | 'usurped'
}>

type UserType = keyof PrefDict

type PrefDict = {
  doctor: PreferencesDoctor
  assistant: PreferencesAssistant
  admin: PreferencesAdmin
}

export const useUserPreference = <
  UT extends UserType,
  Pref extends PrefDict[UT] = PrefDict[UT],
  Key extends keyof Pref = keyof Pref,
>(
  role: UT,
  key: Key,
  options?: Options,
): Pref[Key] | undefined => {
  const { currentUser, loggedUser, usurpedUser } = useContext(ConnectedUserContext)
  const targetedUser = useMemo(() => {
    const user = options?.user ?? 'current'
    switch (user) {
      case 'current':
        return currentUser
      case 'logged':
        return loggedUser
      case 'usurped':
        return usurpedUser
    }
  }, [currentUser, loggedUser, options?.user, usurpedUser])

  const preference = useMemo(() => {
    if (!isDefined(targetedUser)) return undefined

    if (role === 'doctor' && isDoctor(targetedUser)) {
      return (targetedUser.preferences as Pref)[key]
    }
    if (role === 'assistant' && isAssistant(targetedUser)) {
      return (targetedUser.preferences as Pref)[key]
    }
    if (role === 'admin' && isAdmin(targetedUser)) {
      return (targetedUser.preferences as Pref)[key]
    }
    return undefined
  }, [key, role, targetedUser])

  return preference
}
