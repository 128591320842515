import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnaireTemplatesKeys } from '../questionnaireTemplates/questionnaireTemplates.keys'
import { questionTemplatesKeys } from './questionTemplate.keys'
import { deleteQuestionTemplate } from '../../../data/questionTemplates'

interface QuestionTemplateMutateVariable {
  questionTemplateId: number
  questionnaireTemplateIds?: number[]
}

export const useDeleteQuestionTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, QuestionTemplateMutateVariable>(
    async ({ questionTemplateId }) => {
      const { ok } = await deleteQuestionTemplate(questionTemplateId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la question a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { questionTemplateId, questionnaireTemplateIds }) => {
        queryClient.invalidateQueries(questionTemplatesKeys.lists)
        queryClient.invalidateQueries(questionTemplatesKeys.detail(questionTemplateId))
        questionnaireTemplateIds?.map((id) =>
          queryClient.invalidateQueries(questionnaireTemplatesKeys.detail(id)),
        )
      },
    },
  )
}
