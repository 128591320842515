import React, { FunctionComponent } from 'react'
import { DocumentFormCard } from '../Cards/DocumentFormCard.component'
import { LabelFormCard } from '../Cards/LabelFormCard.component'
import { QuestionFormCard } from '../Cards/QuestionFormCard.component'
import { QuestionnaireFormCard } from '../Cards/QuestionnaireFormCard.component'
import { ExportFiltersProps } from './ExportFilters.model'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  exportFiltersStateAtom,
  exportFilterRemoveQuestionnaire,
  exportFilterRemoveDocumentTemplate,
  exportFilterRemoveQuestion,
  exportFilterQuestionSetAnswerValue,
  exportFiltersSetTags,
} from '../../../state/configurableExport'
import { useGetTags } from '../../../hooks/queries/tag'
import {
  SearchExportFilterDocumentTemplateQuestion,
  SearchExportFilterQuestionnaire,
} from '../BottomSearchPanel'
import { useToggle } from 'react-use'

export const ExportFilters: FunctionComponent<ExportFiltersProps> = ({
  openSearchFilterDocument,
}) => {
  const [isOpenSearchExportFilterQuestionnaire, toggleOpenSearchExportFilterQuestionnaire] =
    useToggle(false)
  const [
    isOpenSearchExportFilterDocumentTemplateQuestion,
    toggleOpenSearchExportFilterDocumentTemplateQuestion,
  ] = useToggle(false)
  const filters = useAtomValue(exportFiltersStateAtom)

  const setTagFilter = useSetAtom(exportFiltersSetTags)
  const removeQuestionnaire = useSetAtom(exportFilterRemoveQuestionnaire)
  const removeDocumentTemplate = useSetAtom(exportFilterRemoveDocumentTemplate)
  const removeQuestion = useSetAtom(exportFilterRemoveQuestion)
  const setAnswerValue = useSetAtom(exportFilterQuestionSetAnswerValue)
  const { tagList: tags } = useGetTags()

  return (
    <div className="flex flex-col gap-10">
      <div>
        <h1>Filtrage des patients à exporter</h1>
        <p>
          Un patient doit correspondre à tous les filtres pour être exporté. Aucun filtre correspond
          à un export de tous vos patients.
        </p>
      </div>
      <LabelFormCard tags={tags} tagValues={filters.patientLabels} setTagValues={setTagFilter} />
      <DocumentFormCard
        description="Les patients doivent posséder tous les modèles de document suivants dans leurs événements médicaux :"
        documentTemplates={filters.documentTemplates}
        addDocument={openSearchFilterDocument}
        removeDocument={removeDocumentTemplate}
      />
      <QuestionnaireFormCard
        description="Les patients doivent posséder tous les questionnaires suivants dans leurs événements médicaux :"
        questionnaires={filters.questionnaires}
        addQuestionnaire={toggleOpenSearchExportFilterQuestionnaire}
        removeQuestionnaire={removeQuestionnaire}
      />
      <SearchExportFilterQuestionnaire
        open={isOpenSearchExportFilterQuestionnaire}
        onRequestClose={toggleOpenSearchExportFilterQuestionnaire}
      />
      <QuestionFormCard
        questions={filters.questions}
        addQuestion={toggleOpenSearchExportFilterDocumentTemplateQuestion}
        removeQuestion={removeQuestion}
        updateFilterQuestion={setAnswerValue}
      />
      <SearchExportFilterDocumentTemplateQuestion
        open={isOpenSearchExportFilterDocumentTemplateQuestion}
        onRequestClose={toggleOpenSearchExportFilterDocumentTemplateQuestion}
      />
    </div>
  )
}
