import uuidv4 from 'uuid/v4'

import {
  SurveyScore,
  SurveyBranchConfig,
  SurveyBranchItemType,
  SurveyQuestionAnswer,
  SurveyQuestionConfig,
  SurveyBranchItem,
} from './survey.model'

export const generateUuid = () => uuidv4()

export const buildSurveyScores = (scoresIds: number[]) =>
  scoresIds.map((id) => ({
    type: SurveyBranchItemType.Score,
    uuid: generateUuid(),
    id,
    excludedFields: [],
    fields: [],
    beforeText: '',
  })) as SurveyScore[]

export const addScoresToTree = (
  scores: SurveyScore[],
  tree: SurveyBranchConfig[],
  branchUuid: string,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : { ...branch, questions: [...branch.questions, ...scores] },
  )

export const removeBranchItemFromTree = (
  item: SurveyBranchItem,
  tree: SurveyBranchConfig[],
  branchUuid: string,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : { ...branch, questions: branch.questions.filter((elem) => elem !== item) },
  )

export const buildSurveyQuestion = (
  label: string,
  answers: SurveyQuestionAnswer[],
): SurveyQuestionConfig => ({
  type: SurveyBranchItemType.Question,
  uuid: generateUuid(),
  label,
  answers,
})

export const addQuestionToTree = (
  question: SurveyQuestionConfig,
  tree: SurveyBranchConfig[],
  branchUuid: string,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid ? branch : { ...branch, questions: [...branch.questions, question] },
  )

// This function add new branches in tree from array of branches uuids
// It also checks if the branches already exists and filter it in the case of an update
export const createBranchesInTree = (
  tree: SurveyBranchConfig[],
  fromBranchUuid: string,
  newBranchesUuids: string[],
): SurveyBranchConfig[] => {
  const fromBranch = tree.find((branch) => branch.uuid === fromBranchUuid)
  const newBranchesFilteredUuids = newBranchesUuids.filter(
    (uuid) => !tree.find((branch) => branch.uuid === uuid),
  )
  if (!fromBranch) {
    throw new Error('Branch cannot be found')
  }
  const newBranches = newBranchesFilteredUuids.map((uuid) => ({
    uuid,
    level: fromBranch.level + 1,
    questions: [],
  }))
  return [...tree, ...newBranches]
}

export const filterUnusedBranches = (tree: SurveyBranchConfig[], uuids: string[]) => {
  // We get all the possible answers branches uuids in the current tree
  const usedBranchesUuids = tree
    .reduce(
      (acc, branch) => [
        ...acc,
        ...(branch.questions.filter(
          (item) => item.type === SurveyBranchItemType.Question,
        ) as SurveyQuestionConfig[]),
      ],
      [] as SurveyQuestionConfig[],
    )
    .reduce(
      (acc, item: SurveyQuestionConfig) => [...acc, ...item.answers],
      [] as SurveyQuestionAnswer[],
    )
    .map((answer) => answer.branchUuid)
  // We filter the current tree to remove the used branches uuids and the root branch
  const unusedBranchesUuids = tree
    .filter(
      (branch) => !usedBranchesUuids.find((uuid) => uuid === branch.uuid) && branch.level !== 0,
    )
    .map((branch) => branch.uuid)
  // We return the filtered tree
  return removeBranchesFromTree(tree, unusedBranchesUuids)
}

// this function deletes branches in the tree with an array of uuids
export const removeBranchesFromTree = (tree: SurveyBranchConfig[], branchesUuids: string[]) =>
  tree.filter((branch) => !branchesUuids.find((uuid) => uuid === branch.uuid))

export const editQuestionInTree = (
  oldQuestion: SurveyQuestionConfig,
  newQuestion: SurveyQuestionConfig,
  tree: SurveyBranchConfig[],
  branchUuid: string,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : {
          ...branch,
          questions: branch.questions.map((q) => (q !== oldQuestion ? q : newQuestion)),
        },
  )

export const toggleFieldInTree = (
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  fieldId: number,
  checked: boolean,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : {
          ...branch,
          questions: branch.questions.map((item) =>
            item.type !== SurveyBranchItemType.Score
              ? item
              : item.uuid !== scoreUuid
                ? item
                : {
                    ...item,
                    excludedFields: !checked
                      ? [...item.excludedFields, fieldId]
                      : item.excludedFields.filter((id) => id !== fieldId),
                  },
          ),
        },
  )

export const updateScoreExcludedFields = (
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  fieldsIds: number[],
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : {
          ...branch,
          questions: branch.questions.map((item) =>
            item.type !== SurveyBranchItemType.Score
              ? item
              : item.uuid !== scoreUuid
                ? item
                : {
                    ...item,
                    excludedFields: fieldsIds,
                  },
          ),
        },
  )

export const updateFieldLabelInTree = (
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  fieldId: number,
  label: string,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : {
          ...branch,
          questions: branch.questions.map((item) =>
            item.type !== SurveyBranchItemType.Score
              ? item
              : item.uuid !== scoreUuid
                ? item
                : {
                    ...item,
                    fields: [
                      ...item.fields.filter((fieldLabel) => fieldLabel.id !== fieldId),
                      { id: fieldId, value: label },
                    ],
                  },
          ),
        },
  )

export const updateScoreIntroductionInTree = (
  tree: SurveyBranchConfig[],
  branchUuid: string,
  scoreUuid: string,
  introduction: string,
) =>
  tree.map((branch) =>
    branch.uuid !== branchUuid
      ? branch
      : {
          ...branch,
          questions: branch.questions.map((item) =>
            item.type !== SurveyBranchItemType.Score
              ? item
              : item.uuid !== scoreUuid
                ? item
                : {
                    ...item,
                    beforeText: introduction,
                  },
          ),
        },
  )

export const updateSurveyBranchItemsInTree = (
  tree: SurveyBranchConfig[],
  branchUuid: string,
  items: SurveyBranchItem[],
) => tree.map((branch) => (branch.uuid !== branchUuid ? branch : { ...branch, questions: items }))
