import { useCallback, useState } from 'react'
import { isEqual } from 'lodash'
import { isDefined } from '../../../../misc/functions.utilities'
import createSearchPanel, { FiltersType } from '../../../../containers/SearchPanel'
import { LightQuestionnaire } from '../../../../model/Questionnaire'
import { QuestionnaireListItem } from '../../../questionnaire'
import { BottomPanel } from '../BottomPanel'
import {
  QuestionnaireTemplateQueryFilters,
  SearchQuestionnaireBottomPanelProps,
} from './SearchQuestionnaireBottomPanel.model'
import { useGetInfiniteQuestionnaireTemplates } from '../../../../hooks/queries/questionnaireTemplates'

const SearchPanel = createSearchPanel<LightQuestionnaire>()

export const SearchQuestionnaireBottomPanel = ({
  isUnmounting,
  onRequestClose,
  onSelect,
}: SearchQuestionnaireBottomPanelProps) => {
  const [queryFilters, setQueryFilters] = useState<QuestionnaireTemplateQueryFilters>({})
  const {
    query: { isLoading, fetchNextPage },
    questionnaireTemplateList: items,
    paginationState,
    cancelPendingQuery,
  } = useGetInfiniteQuestionnaireTemplates({ filters: queryFilters })

  const handleFiltersChange = useCallback(
    (filters) => {
      const newQueryFilters: QuestionnaireTemplateQueryFilters = {
        ...(filters.search && { search: filters.search }),
        ...(isDefined(filters.private) && { private: filters.private }),
        ...(filters.tagIds.length && { tagIds: filters.tagIds.join(',') }),
      }

      if (!isEqual(queryFilters, newQueryFilters)) {
        cancelPendingQuery()
        setQueryFilters(newQueryFilters)
      }
    },
    [cancelPendingQuery, queryFilters],
  )

  const onPrimaryAction = (selectedItem?: LightQuestionnaire) => {
    if (selectedItem) {
      onSelect(selectedItem)
    }
  }

  return (
    <BottomPanel
      title={'Ajouter un template de questionnaire'}
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
    >
      <SearchPanel
        items={items}
        loading={isLoading}
        itemCount={paginationState?.itemCount ?? null}
        pageCount={paginationState?.pageCount ?? null}
        currentPage={paginationState?.currentPage ?? 0}
        onPrimaryAction={onPrimaryAction}
        getItems={() => fetchNextPage()}
        onFiltersChange={handleFiltersChange}
        renderItem={(item) => <QuestionnaireListItem item={item} />}
        filterCapabilities={[FiltersType.Title, FiltersType.Visibility, FiltersType.Tags]}
      />
    </BottomPanel>
  )
}
