import getApi from '../../api/apisauceConfig'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { singleItemPageLimit } from '../../constants'
import { LightQuestionnaireTemplate } from '../../model/QuestionnaireTemplate'

export const getQuestionnaireTemplates = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<LightQuestionnaireTemplate>>(
    '/questionnaire_templates',
    {
      page,
      limit,
      ...filters,
    },
    { signal },
  )
