import { FontFamily } from '@follow/farte'
import {
  AnswerInstance,
  AnswerTemplate,
  QuestionConfig,
  QuestionInstance,
  QuestionTemplate,
} from '../../../model/Questionnaire'
import { AnswerTemplateMutationPayload } from '../../../data/answerTemplates/mapper/answerTemplate.model'
import { AnswerInstanceMutationPayload } from '../../../data/answerInstances/mapper/answerInstance.model'

export const EDIT_QUESTION_DEBOUNCE_LATENCY = 500

export interface EditQuestionPanelOwnProps {
  question: QuestionTemplate | QuestionInstance
  questionLevelDefaultLabel: string
  placeholder: string
  isAdmin: boolean
  canHandleScore: boolean
  handleAddAnswer: () => void
  handleAddImageToAnswer: (answer: AnswerTemplate | AnswerInstance, file: File) => void
  handleDeleteAnswer: (answer: AnswerTemplate | AnswerInstance) => void
  HandleRemoveImageFromAnswer: (answer: AnswerTemplate | AnswerInstance) => void
  handleUpdateAnswer: (
    id: number,
    payload: AnswerTemplateMutationPayload | AnswerInstanceMutationPayload,
  ) => void
  handleUpdateQuestion: (question: Partial<QuestionTemplate | QuestionInstance>) => void
  onConfigValueChange: (config: QuestionConfig) => void
}

export interface EditQuestionPanelStateProps {
  editorFontFamily: FontFamily
}

export type EditQuestionPanelProps = EditQuestionPanelOwnProps & EditQuestionPanelStateProps
