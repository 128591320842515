interface ApiErrorOptions {
  status?: number
  cause?: unknown
}

export class ApiError extends Error {
  public options: ApiErrorOptions = { status: 500 }

  constructor(message: string, options?: ApiErrorOptions) {
    super(message)
    this.name = 'ApiError'
    if (options) this.options = { ...this.options, ...options }
  }
}
