import { HOTJAR_ID, HOTJAR_VERSION } from '../environment/analytics'
import { preventAnalyticLoading } from './utils'
import Hotjar from '@hotjar/browser'
import { developmentMode } from '../misc/environment.utilities'
import { isFinite } from 'lodash'

export function initHotjar() {
  if (preventAnalyticLoading(document)) {
    return
  }

  try {
    const parsedHotjarId = Number.parseInt(HOTJAR_ID ?? '')
    if (!isFinite(parsedHotjarId)) {
      throw new Error('Unable to parse Hotjar Site Id')
    }
    Hotjar.init(parsedHotjarId, HOTJAR_VERSION, {
      debug: developmentMode,
    })
  } catch (e) {
    console.error('Hotjar init failed :', e)
  }
}
