import { Filters } from '../../../model/Filters'

const all = ['questionnaire_instances']
const lists = [...all, 'list']
const list = (filters?: Filters) => [...lists, filters]
const detail = (id: number) => [...all, id]

export const questionnaireInstancesKeys = {
  all,
  lists,
  list,
  detail,
}
