import React, { FunctionComponent } from 'react'

import { CheckboxState, CheckboxProps } from './Checkbox.model'
import BaseThemes from '../../../../design-system/themes/base.theme'
import classNames from 'classnames'
import { Icon } from '../../Icon/Icon.component'
import { getCssVariable } from '../../../../design-system/utils'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  checked,
  disabled,
  theme = 'primary',
  label,
  title,
  onChange,
  testId,
}) => {
  const selectedTheme = disabled ? BaseThemes[theme].disabled : BaseThemes[theme].active

  return (
    <div className="relative flex items-center justify-start w-fit" title={title}>
      <input
        type="checkbox"
        className={classNames(
          selectedTheme.colors.focusColor,
          `form-checkbox absolute w-full h-full ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } appearance-none`,
        )}
        checked={!!checked}
        disabled={disabled}
        onChange={onChange}
        data-test-id={addTestIdSuffix('input', testId)}
      />
      <div className="w-6 h-6">
        <div
          className={classNames(
            'w-full h-full relative rounded-md overflow-hidden flex items-center justify-center pointer-events-none',
            selectedTheme.borderColors.default,
            { 'border-2 bg-white': !checked, [selectedTheme.colors.backgroundColor]: !!checked },
          )}
        >
          {checked === CheckboxState.CHECKED && (
            <Icon icon="check" size="normal" color={getCssVariable('shades', 'white')} />
          )}
          {checked === CheckboxState.INDETERMINATE && (
            <Icon icon="dash" size="normal" color={getCssVariable('shades', 'white')} />
          )}
        </div>
      </div>
      {label && <span className="text-shades-2 ml-3 text-sm font-medium">{label}</span>}
    </div>
  )
}
