import { contactsSagas } from './contacts'
import { documentInstancesSagas } from './documentInstances'
import { documentTemplatesSagas } from './documentTemplates'
import { filesSagas } from './files'
import { userMeTeamsSagas } from './users'
import { questionnairesSagas } from './questionnaires'
import { questionInstancesSagas } from './questionInstances'
import { tagsSagas } from './tags'
import { medicalEventsSagas } from './medicalEvents'
import { documentCategoriesSagas } from './documentCategories'
import { atcClassificationSagas } from './atcClassification'
import { citiesSagas } from './cities'
import { mailContactsSagas } from './mailContacts'
import { patientSearchSagas } from './patientSearch'
import { patientCurrentTreatmentsSagas } from './patientCurrentTreatments'
import { patientManualPrescriptionsSagas } from './patientManualPrescriptions'
import { resourceHistorySagas } from './resourceHistory'
import { countriesSagas } from './countries'
import { mailContactSearchSagas } from './mailContactSearch'
import { questionTemplatesSagas } from './questionTemplates'

export const cacheSagas = [
  ...Object.values(atcClassificationSagas),
  ...Object.values(citiesSagas),
  ...Object.values(contactsSagas),
  ...Object.values(countriesSagas),
  ...Object.values(documentInstancesSagas),
  ...Object.values(documentTemplatesSagas),
  ...Object.values(documentCategoriesSagas),
  ...Object.values(mailContactsSagas),
  ...Object.values(filesSagas),
  ...Object.values(medicalEventsSagas),
  ...Object.values(patientCurrentTreatmentsSagas),
  ...Object.values(patientManualPrescriptionsSagas),
  ...Object.values(patientSearchSagas),
  ...Object.values(questionnairesSagas),
  ...Object.values(questionInstancesSagas),
  ...Object.values(questionTemplatesSagas),
  ...Object.values(resourceHistorySagas),
  ...Object.values(userMeTeamsSagas),
  ...Object.values(tagsSagas),
  ...Object.values(mailContactSearchSagas),
]
