import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import classNames from 'classnames/bind'

import { Colors } from '../../../../colors'
import { MenuChip, ChipLabel, Heading, Chevron, ValidationModal } from '../../../shared'
import { Questionnaire, QuestionnaireInstance } from '../../../../model/Questionnaire'
import { breakMedium, breakSmall } from '../../../../misc/responsive'
import { SelectedContentType } from '../../../../store/ui/medicalEvents/medicalEventContent'

import { FiltersWrapper } from '../../../shared/filters/FiltersWrapper'
import { QuestionnaireLabel } from '../../../shared/filters/QuestionnaireLabel'

import { QuestionnaireFiltersProps } from './ContentFilters.model'
import { useWidthObserver } from '../../../../hooks/utils'
import { AddContentButton } from '../../../shared/buttons/AddContentButton'
import styles from './ContentFilters.module.scss'
import { MedicalEventDocumentType } from '../../../../model/MedicalEvent'
import { canAddContentToDocument } from '../../../../misc/documents.utilities'
import { useNavigate } from 'react-router-dom'
import {
  extractOwnerOrCreatorNameFromFwDocumentFile,
  generateOwnerCreateNameTitle,
} from '../../../../store/domain/medicalEvents/medicalEvents.utils'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { WithFilledIndicatorQuoteLine } from '../../../../model/Acte'

const classNamesCx = classNames.bind(styles)

export const ContentFilters: FunctionComponent<QuestionnaireFiltersProps> = ({
  medicalEvent,
  collapsed,
  documentQuestionList,
  questionnaires,
  prescriptions,
  actes,
  initializeFiltersFromUrl,
  selectedContentFilter,
  selectMedicalEventContent,
  deleteQuestionnaireFromSelectedDocument,
  deleteDrugFromSelectedDocument,
  deleteQuoteLineFromSelectedDocument,
  selectedMedicalEventDocument,
}) => {
  const [quoteLineToDelete, setQuoteLineToDelete] = useState<WithFilledIndicatorQuoteLine | null>(
    null,
  )
  const isTablette = useWidthObserver(breakMedium)
  const isPhone = useWidthObserver(breakSmall)
  const navigate = useNavigate()

  const { currentUser } = useContext(ConnectedUserContext)

  useEffect(() => {
    initializeFiltersFromUrl()
  }, [initializeFiltersFromUrl])

  const displayDeleteIcon =
    selectedMedicalEventDocument?.type === MedicalEventDocumentType.FW_DOCUMENT &&
    selectedMedicalEventDocument.item.locked

  const handleManageQuestionnaire =
    ({ questionnaireTemplateId }: QuestionnaireInstance) =>
    () => {
      navigate(`/managers/questionnaires/${questionnaireTemplateId}`)
    }

  const deleteQuestionnaireHandler = (id: number) => () => {
    if (window.confirm('Êtes vous sûr(e) de vouloir retirer ce questionnaire du document?')) {
      deleteQuestionnaireFromSelectedDocument(id)
    }
  }

  const handleQuoteLineDeletionCancel = useCallback(() => setQuoteLineToDelete(null), [])
  const handleQuoteLineToDelete = useCallback(
    (quoteLine: WithFilledIndicatorQuoteLine) => () => setQuoteLineToDelete(quoteLine),
    [],
  )

  const handleDeleteQuoteLineFromSelectedDocument = useCallback(() => {
    if (!displayDeleteIcon && medicalEvent?.isDeletable) {
      quoteLineToDelete && deleteQuoteLineFromSelectedDocument(quoteLineToDelete.variableUuid)
    }
    setQuoteLineToDelete(null)
  }, [
    deleteQuoteLineFromSelectedDocument,
    displayDeleteIcon,
    medicalEvent?.isDeletable,
    quoteLineToDelete,
  ])

  const canConfigureScore = ({ scoreConfig }: Questionnaire) =>
    medicalEvent?.isEditable && !isPhone && !scoreConfig
  const isVariables = documentQuestionList && documentQuestionList.questions.length > 0
  const headingLabel =
    selectedMedicalEventDocument &&
    selectedMedicalEventDocument.type === MedicalEventDocumentType.FW_DOCUMENT &&
    `Contenu de ${selectedMedicalEventDocument.item.title}`

  const diplayAddVariableButton =
    selectedMedicalEventDocument?.type === MedicalEventDocumentType.FW_DOCUMENT &&
    !!medicalEvent?.isEditable &&
    canAddContentToDocument(selectedMedicalEventDocument.item.contentType)

  const creatorAndOwnerNameExtracted = extractOwnerOrCreatorNameFromFwDocumentFile(
    selectedMedicalEventDocument,
    currentUser,
  )

  const ownerAndCreatorName = generateOwnerCreateNameTitle(
    creatorAndOwnerNameExtracted.ownerName,
    creatorAndOwnerNameExtracted.creatorName,
  )

  return (
    <>
      <div className={classNamesCx(styles.container, { collapsed })}>
        <Heading size={5} theme="dark" weight="regular">
          {headingLabel}
        </Heading>
        {ownerAndCreatorName && (
          <span className={styles.ownerCreatorName}>{ownerAndCreatorName}</span>
        )}
        <div className={styles.content}>
          <FiltersWrapper variance="variant2">
            <MenuChip
              label="Éditeur de texte"
              testId="button-chip-editor"
              selected={selectedContentFilter.type === SelectedContentType.EDITOR}
              onClick={() => selectMedicalEventContent({ type: SelectedContentType.EDITOR })}
              icon="text"
              collapsed={true}
              colorPreset="dark"
            />

            <div className={styles.separator} />
            {isVariables && (
              <MenuChip
                label="Autres variables"
                collapsed={true}
                selected={selectedContentFilter.type === SelectedContentType.VARIABLES}
                onClick={() => selectMedicalEventContent({ type: SelectedContentType.VARIABLES })}
                dotted={!documentQuestionList || !documentQuestionList.isFilled}
                colorPreset="dark"
                testId="button-chip-variables"
                // scrollIntoViewOnSelect={true}
              />
            )}
            {questionnaires &&
              questionnaires.map((questionnaire, index) => (
                <MenuChip
                  key={index}
                  icon={questionnaire.scoreConfig ? 'calculator' : undefined}
                  label={questionnaire.title}
                  renderLabel={(label) => (
                    <QuestionnaireLabel
                      documentId={
                        documentQuestionList ? documentQuestionList.documentId : undefined
                      }
                      label={label}
                      questionnaire={questionnaire}
                    />
                  )}
                  collapsed={
                    selectedContentFilter.type !== SelectedContentType.QUESTIONNAIRE ||
                    selectedContentFilter.questionnaireId !== questionnaire.id
                  }
                  selected={
                    selectedContentFilter.type === SelectedContentType.QUESTIONNAIRE &&
                    selectedContentFilter.questionnaireId === questionnaire.id
                  }
                  onClick={() =>
                    selectMedicalEventContent({
                      type: SelectedContentType.QUESTIONNAIRE,
                      questionnaireId: questionnaire.id,
                    })
                  }
                  onConfigure={
                    // On ne peut pas éditer un score à point
                    canConfigureScore(questionnaire)
                      ? handleManageQuestionnaire(questionnaire)
                      : undefined
                  }
                  onDelete={
                    !displayDeleteIcon && medicalEvent?.isDeletable
                      ? deleteQuestionnaireHandler(questionnaire.id)
                      : undefined
                  }
                  dotted={!questionnaire.isFilled}
                  colorPreset="dark"
                  testId={`button-chip-questionnaire-${questionnaire.id}`}
                  // scrollIntoViewOnSelect={true}
                />
              ))}
            {prescriptions &&
              prescriptions.map((prescription, index) => (
                <MenuChip
                  key={index}
                  icon={prescription.drugs.length === 1 ? 'pills' : 'multipills'}
                  label={
                    prescription.drugs.length === 1
                      ? prescription.drugs[0].name
                      : `${prescription.drugs.length} médicaments`
                  }
                  renderLabel={(label) => <ChipLabel label={label} />}
                  collapsed={
                    displayDeleteIcon ||
                    selectedContentFilter.type !== SelectedContentType.PRESCRIPTION ||
                    selectedContentFilter.prescriptionId !== prescription.uuid
                  }
                  selected={
                    selectedContentFilter.type === SelectedContentType.PRESCRIPTION &&
                    selectedContentFilter.prescriptionId === prescription.uuid
                  }
                  onClick={() => {
                    selectMedicalEventContent({
                      type: SelectedContentType.PRESCRIPTION,
                      prescriptionId: prescription.uuid,
                      intervalId: prescription.posologyIntervals[0]?.id,
                    })
                  }}
                  onDelete={
                    !displayDeleteIcon && medicalEvent?.isDeletable
                      ? () => deleteDrugFromSelectedDocument(prescription)
                      : undefined
                  }
                  dotted={false}
                  colorPreset="dark"
                  testId={`button-chip-prescription-${prescription.id}`}
                />
              ))}
            {actes &&
              actes.map((acte) => (
                <MenuChip
                  key={acte.id}
                  icon="calculator"
                  label={acte.codeActe}
                  renderLabel={(label) => <ChipLabel label={label} />}
                  collapsed={
                    displayDeleteIcon ||
                    selectedContentFilter.type !== SelectedContentType.ACTE ||
                    selectedContentFilter.acteId !== acte.id
                  }
                  selected={
                    selectedContentFilter.type === SelectedContentType.ACTE &&
                    selectedContentFilter.acteId === acte.id
                  }
                  onClick={() => {
                    selectMedicalEventContent({
                      type: SelectedContentType.ACTE,
                      acteId: acte.id,
                    })
                  }}
                  onDelete={handleQuoteLineToDelete(acte)}
                  dotted={!acte.isFilled}
                  colorPreset="dark"
                  testId={`button-chip-acte-${acte.id}`}
                />
              ))}
            <ValidationModal
              display={!!quoteLineToDelete}
              title={`Êtes vous sûr de vouloir supprimer le code CCAM ${quoteLineToDelete?.codeActe}?`}
              onSubmit={handleDeleteQuoteLineFromSelectedDocument}
              onClose={handleQuoteLineDeletionCancel}
            />
          </FiltersWrapper>
          {diplayAddVariableButton && (
            <AddContentButton
              label="Variable"
              isSmallScreen={isTablette}
              testId="button-add-variable"
              onClick={() =>
                selectMedicalEventContent({
                  type: SelectedContentType.ADD_VARIABLE_PANEL,
                })
              }
            />
          )}
        </div>
      </div>
      {!collapsed && <Chevron color={Colors.dsLight7} />}
    </>
  )
}
