import { ADMIN_ROLES, Roles } from '../model/Roles'
import { UserAssistant } from '../model/User'

export function hasSuperAdminRole(roles: Roles[]) {
  return roles.includes(Roles.SUPER_ADMIN)
}

export function hasAdminRole(roles: Roles[]) {
  return (
    hasSuperAdminRole(roles) ||
    roles.includes(Roles.ADMIN_TECH) ||
    roles.includes(Roles.ADMIN_CSM) ||
    roles.includes(Roles.ADMIN_SALE) ||
    roles.includes(Roles.ADMIN_OFFICE_MANAGER)
  )
}

export function hasStaffRole(roles: Roles[]) {
  return hasAdminRole(roles) || hasExtDsmRole(roles)
}

export function hasDoctorRole(roles: Roles[]) {
  return roles.includes(Roles.DOCTOR)
}

export function hasAssistantRole(roles: Roles[]) {
  return roles.includes(Roles.ASSISTANT)
}

export function hasExtDsmRole(roles: Roles[]) {
  return roles.includes(Roles.EXT_DSM)
}

export function hasSpecificRoles(currentRoles: Roles[], expectedRoles: Roles[]) {
  return expectedRoles.some((role) => currentRoles.includes(role))
}

export const isDetachedAssistant = (user: UserAssistant) =>
  user.mainRole === Roles.ASSISTANT && user.doctors.length < 1

export const isAdminRole = (role: Roles): role is (typeof ADMIN_ROLES)[number] => {
  const admins: Roles[] = [...ADMIN_ROLES]
  return admins.includes(role)
}

export const isExtDsmRole = (role: Roles): role is typeof Roles.EXT_DSM => {
  return role === Roles.EXT_DSM
}
