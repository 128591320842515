import { EditorValueJSON, VariableModel } from '@follow/farte'
import { Prescription, PrescriptionVariable } from '../../../model/Prescription'
import { ManualPrescription } from '../../../model/ManualPrescription'
import { QuoteLine, SelectedQuoteLinePayload } from '../../../model/Acte'
import {
  QuestionInstance,
  Questionnaire,
  QuestionnaireInstance,
  QuestionnaireTemplate,
  QuestionTemplate,
} from '../../../model/Questionnaire'

const resourceName = '@DOMAIN/EDITOR'

export const DomainEditorActionTypes = {
  SET_PENDING_VARIABLES: `${resourceName}/SET_PENDING_VARIABLES`,
  CLEAR_PENDING_VARIABLES: `${resourceName}/CLEAR_PENDING_VARIABLES`,
}

export interface EditableDocument {
  template: EditorValueJSON
  prescriptions: ReadonlyArray<Prescription>
  manualPrescriptions: ReadonlyArray<ManualPrescription>
  questionnaires: ReadonlyArray<QuestionnaireTemplate | QuestionnaireInstance>
  variables: ReadonlyArray<QuestionTemplate>
  quoteLines: ReadonlyArray<QuoteLine>
  updatedAt: string
}

export interface DocumentVariables {
  questionnaires: ReadonlyArray<Questionnaire>
  variables: ReadonlyArray<QuestionInstance> | ReadonlyArray<QuestionTemplate>
}

export interface FarteVariableWithTitle extends VariableModel {
  title: string
}

export interface SingleSourceOfTruthOutput {
  template: EditorValueJSON
  prescriptionUuids: string[]
  manualPrescriptionUuids: string[]
  questionnaireTemplateIds: number[] | undefined
  questionnaireInstanceIds: number[] | undefined
  variableIds: number[]
  quoteLinesUuids: string[]
}

export interface DomainEditorState {
  pendingVariables: PendingVariables
}

export interface PendingVariables {
  prescriptions: Array<PrescriptionVariable>
  manualPrescriptions: Array<ManualPrescription>
  quoteLines: Array<SelectedQuoteLinePayload>
}
