import { connect } from 'react-redux'
import { RootState } from '../../../store'

import { EditQuestionInstancePanelStateProps } from './EditQuestionInstancePanel.model'

import { EditQuestionInstancePanel as EditQuestionInstancePanelComponent } from './EditQuestionInstancePanel.component'
import { isAdminSelector, isExtDsmSelector } from '../../../store/ui/adminUsers/index'

const mapStateToProps = (state: RootState): EditQuestionInstancePanelStateProps => ({
  isExtDsm: isExtDsmSelector(state),
  isAdmin: isAdminSelector(state),
})

export const EditQuestionInstancePanel = connect(
  mapStateToProps,
  {},
)(EditQuestionInstancePanelComponent)
