import {
  QuestionnaireTemplateResource,
  QuestionnaireTemplateSerializedResource,
} from './questionnaireTemplate.model'
import { ApiResponse } from 'apisauce'
import { deserializeQuestionTemplates } from '../../questionTemplates/mapper/questionTemplates.mapper'
import { QuestionnaireTemplate } from '../../../model/Questionnaire'

export function deserializeQuestionnaireTemplate(
  resource: QuestionnaireTemplateResource,
): QuestionnaireTemplate {
  return {
    ...resource,
    questions: deserializeQuestionTemplates(resource.questions),
  }
}

export function serializeQuestionnaireTemplate(
  questionnaireTemplate: Partial<QuestionnaireTemplate>,
): Partial<QuestionnaireTemplateSerializedResource> {
  return {
    ...questionnaireTemplate,
    questionTemplateIds:
      questionnaireTemplate.questions && questionnaireTemplate.questions.map(({ id }) => id),
  }
}

export function deserializeQuestionnaireTemplateResponse(
  response: ApiResponse<QuestionnaireTemplateResource>,
): ApiResponse<QuestionnaireTemplate> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestionnaireTemplate(data),
  }
}
