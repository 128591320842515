import { combineReducers } from 'redux'
import { contactsReducers } from './contacts'
import { documentInstancesReducers } from './documentInstances'
import { documentTemplatesReducers } from './documentTemplates'
import { filesReducers } from './files'
import { CacheState } from './cache.model'
import { userMeTeamsReducers } from './users'
import { questionnairesReducers } from './questionnaires'
import { questionInstancesReducers } from './questionInstances'
import { tagsReducers } from './tags'
import { medicalEventsReducers } from './medicalEvents'
import { documentCategoriesReducers } from './documentCategories'
import { atcClassificationReducers } from './atcClassification'
import { citiesReducers } from './cities'
import { mailContactsReducers } from './mailContacts'
import { patientSearchReducers } from './patientSearch'
import { patientCurrentTreatmentsReducers } from './patientCurrentTreatments'
import { patientManualPrescriptionsReducers } from './patientManualPrescriptions'
import { resourceHistoryReducers } from './resourceHistory'
import { countriesReducers } from './countries'
import { mailContactSearchReducers } from './mailContactSearch'
import { questionTemplatesReducers } from './questionTemplates'

export default combineReducers<CacheState>({
  atcClassification: atcClassificationReducers,
  cities: citiesReducers,
  contacts: contactsReducers,
  countries: countriesReducers,
  documentInstances: documentInstancesReducers,
  documentTemplates: documentTemplatesReducers,
  documentCategories: documentCategoriesReducers,
  files: filesReducers,
  mailContacts: mailContactsReducers,
  medicalEvents: medicalEventsReducers,
  patientCurrentTreatments: patientCurrentTreatmentsReducers,
  patientManualPrescriptions: patientManualPrescriptionsReducers,
  patientSearch: patientSearchReducers,
  questionnaires: questionnairesReducers,
  questions: questionInstancesReducers,
  questionTemplates: questionTemplatesReducers,
  resourceHistory: resourceHistoryReducers,
  tags: tagsReducers,
  userMeTeams: userMeTeamsReducers,
  mailContactSearch: mailContactSearchReducers,
})
