import { takeEvery, call, put, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'

import { addResponseError } from '../../message'
import { getQuestionnaireComputedValue } from '../../cache/documentInstances/api'
import { documentInstanceQuestionnaireSelector } from '../../domain/documentInstances/documentInstances.selectors'

import { questionnaireComputedValuesUiActions } from './questionnaireComputedValues.actions'
import { QuestionnaireComputedValuesUiActionsTypes } from './questionnaireComputedValues.model'
import {
  documentAnswerValuesDomainActions,
  DocumentAnswerValuesDomainActionsTypes,
} from '../../domain/documentAnswerValues'
import { inUseMedicalEventContentIdSelector } from '../medicalEvents/medicalEventContent'

function* fetchQuestionnaireComputedValueWorker({
  documentId,
  questionnaireId,
}: ReturnType<typeof questionnaireComputedValuesUiActions.fetchQuestionnaireComputedValue>) {
  const response: ApiResponse<number> = yield call(
    getQuestionnaireComputedValue,
    documentId,
    questionnaireId,
  )

  if (response.ok) {
    yield put(
      questionnaireComputedValuesUiActions.setQuestionnaireComputedValue(
        documentId,
        questionnaireId,
        response.data,
      ),
    )
  } else if (response.status !== 409) {
    yield put(addResponseError(response))
  }
}

function* fetchQuestionnaireComputedValueWatcher() {
  yield takeEvery(
    QuestionnaireComputedValuesUiActionsTypes.FETCH,
    fetchQuestionnaireComputedValueWorker,
  )
}

function* onRefetchAnswerValueWorker({
  documentId,
}: ReturnType<typeof documentAnswerValuesDomainActions.fetchDocumentAnswerValues>) {
  const inUseMedicalEventQuestionnaireId: ReturnType<typeof inUseMedicalEventContentIdSelector> =
    yield select(inUseMedicalEventContentIdSelector)
  if (documentId && typeof inUseMedicalEventQuestionnaireId === 'number') {
    const questionnaire: ReturnType<typeof documentInstanceQuestionnaireSelector> = yield select(
      documentInstanceQuestionnaireSelector,
      documentId,
      inUseMedicalEventQuestionnaireId,
    )
    if (questionnaire && questionnaire.scoreConfig) {
      yield put(
        questionnaireComputedValuesUiActions.fetchQuestionnaireComputedValue(
          documentId,
          inUseMedicalEventQuestionnaireId,
        ),
      )
    }
  }
}

function* onRefetchAnswerValueWatcher() {
  yield takeEvery(DocumentAnswerValuesDomainActionsTypes.FETCH, onRefetchAnswerValueWorker)
}

export const questionnaireComputedValuesSages = {
  fetchQuestionnaireComputedValueWatcher,
  onRefetchAnswerValueWatcher,
}
