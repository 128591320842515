import React, { FunctionComponent, useMemo } from 'react'
import { SelectInput } from '../../../../shared'
import { Speciality } from '../../../../../model/Professions'
import { ProfessionSpecialitiesSelectProps } from './ProfessionSpecialities.model'
import { useField } from 'formik'
import { SelectOption } from '../../../../../model/SelectOption'
import { useGetSpecialities } from '../../../../../hooks/queries/specialities'

export const ProfessionSpecialitiesSelect: FunctionComponent<ProfessionSpecialitiesSelectProps> = ({
  theme = 'light',
  disabled,
}) => {
  const { query: specialityQuery } = useGetSpecialities()

  const [formValue, { error }, helpers] = useField<Speciality | null>('speciality')

  const selectOptions: Array<SelectOption<Speciality>> | null = useMemo(
    () =>
      specialityQuery.data?.map((speciality) => ({
        key: speciality.id,
        value: speciality,
        label: speciality.description,
      })) ?? null,
    [specialityQuery.data],
  )

  const selectValue = useMemo(
    () => selectOptions?.find((option) => option.value.id === formValue?.value?.id),
    [formValue.value, selectOptions],
  )

  return (
    <SelectInput<Speciality>
      title="Specialité"
      options={selectOptions ?? []}
      disabled={disabled || !selectOptions}
      colorPreset={theme}
      value={selectValue}
      onSelect={({ value }) => {
        helpers.setValue(value)
      }}
      error={error}
    />
  )
}
