import { GridElementProps } from './GridElement.model'
import styled from '../../../../../styles/legacy/styled/styled'

export const GridElement = styled.div<GridElementProps>`
  grid-column: ${({ gridColumn, spanColumn }) =>
    gridColumn ? gridColumn : spanColumn ? `span ${spanColumn}` : '1 / -1'};
  grid-row: ${({ gridRow, spanRow }) => (gridRow ? gridRow : spanRow ? `span ${spanRow}` : '')};
  justify-self: ${({ justifySelf = 'stretch' }) => justifySelf};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
`
