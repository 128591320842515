import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionInstancesKeys } from '../questionInstances/questionInstance.keys'
import {
  AnswerInstanceMutationPayload,
  AnswerInstanceResource,
} from '../../../data/answerInstances/mapper/answerInstance.model'
import { createAnswerInstance } from '../../../data/answerInstances'
import { questionnaireInstancesKeys } from '../questionnaireInstances/questionnaireInstances.keys'

interface AnswerInstanceMutateVariable {
  payload: AnswerInstanceMutationPayload
  questionnaireInstanceId?: number
}

export const useCreateAnswerInstance = () => {
  const queryClient = useQueryClient()

  return useMutation<AnswerInstanceResource, Error, AnswerInstanceMutateVariable>(
    async ({ payload }) => {
      const { data, ok } = await createAnswerInstance(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la réponse a échoué')
      }
      return data
    },
    {
      onSuccess: (answerInstance, { questionnaireInstanceId }) => {
        queryClient.invalidateQueries(questionInstancesKeys.detail(answerInstance.questionId))
        if (questionnaireInstanceId) {
          queryClient.invalidateQueries(questionnaireInstancesKeys.detail(questionnaireInstanceId))
        }
      },
    },
  )
}
