import classNames from 'classnames/bind'
import React, { FunctionComponent, ReactNode /*, useContext*/ } from 'react'
import { QuestionTemplate } from '../../../../model/Questionnaire'
import {
  ChangeQuestionImportanceCallback,
  DeleteQuestionCallback,
  EditQuestionCallback,
  QuestionnaireMode,
} from '../../Questionnaire.model'
import { IconButton } from '../../../shared'

import styles from './QuestionWrapper.module.scss'
import ImportantQuestionAction from '../../../../containers/Question/ImportantQuestionAction/ImportantQuestionAction.component'

const cx = classNames.bind(styles)

interface QuestionWrapperProps {
  children?: ReactNode
  mode: QuestionnaireMode
  question: QuestionTemplate
  filled?: boolean
  title?: React.ReactNode
  onDelete?: DeleteQuestionCallback
  onEdit?: EditQuestionCallback
  onChangeQuestionImportance?: ChangeQuestionImportanceCallback
  subQuestions?: React.ReactNode
}

export const QuestionWrapper: FunctionComponent<QuestionWrapperProps> = ({
  mode,
  question,
  title,
  filled,
  children,
  onDelete,
  onEdit,
  onChangeQuestionImportance,
  subQuestions,
}) => {
  return (
    <div
      data-test-id={`question-${question.id}`}
      className={cx(styles.container, {
        filled,
        shared: question.sharedInMedicalEvent,
      })}
    >
      {mode === 'fill' && (
        <div className={styles.fillIndicatorContainer}>
          <div className={styles.fillIndicator} />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.questionHeader}>
          {title}
          <div className={styles.actions}>
            {onChangeQuestionImportance && (
              <ImportantQuestionAction
                question={question}
                isImportant={!!question.importantInformation}
                onChangeQuestionImportance={onChangeQuestionImportance}
              />
            )}
            {onEdit && (
              <IconButton
                theme="transparent-dark"
                size="micro"
                icon="pencil"
                onClick={() => onEdit(question)}
                testId="button-edit-question"
              />
            )}
            {onDelete && (
              <IconButton
                theme="transparent-dark"
                size="micro"
                icon="trash"
                onClick={() => onDelete(question)}
                testId="button-delete-question"
              />
            )}
          </div>
        </div>
        <div className={styles.answers}>{children}</div>
        {subQuestions}
      </div>
    </div>
  )
}
