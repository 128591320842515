import getApi from '../../api'
import {
  deserializeQuestionnaireTemplateResponse,
  serializeQuestionnaireTemplate,
} from './mapper/questionnaireTemplate.mapper'
import {
  QuestionnaireTemplateMutationPayload,
  QuestionnaireTemplateResource,
} from './mapper/questionnaireTemplate.model'

export const createQuestionnaireTemplate = (
  questionnaireTemplate: QuestionnaireTemplateMutationPayload,
) =>
  getApi()
    .post<QuestionnaireTemplateResource>(
      '/questionnaire_templates',
      serializeQuestionnaireTemplate(questionnaireTemplate),
    )
    .then(deserializeQuestionnaireTemplateResponse)
