import getApi from '../../../../api/index'

import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

import { QuestionTemplate } from '../../../../model/Questionnaire'
import { PaginatedList } from '../../../../model/Pagination'
import {
  deserializeQuestionTemplateResponse,
  deserializeQuestionTemplatesPageResponse,
  serializeQuestionTemplate,
} from './QuestionTemplate.mapper'
import { QuestionTemplateResource } from './QuestionTemplates.model'

export const restuxQuestionTemplatesApiConfig: RestuxApiConfig<QuestionTemplate, QuestionTemplate> =
  {
    url: '/question_templates',
    getPaginatedItems: (route) =>
      getApi()
        .get<PaginatedList<QuestionTemplateResource>>(route)
        .then(deserializeQuestionTemplatesPageResponse),
    getItem: (route) =>
      getApi().get<QuestionTemplateResource>(route).then(deserializeQuestionTemplateResponse),
    createItem: (route, item) =>
      getApi().post<QuestionTemplate>(route, serializeQuestionTemplate(item)),
    updateItem: (route, item) =>
      getApi().put<QuestionTemplate>(route, serializeQuestionTemplate(item)),
    deleteItem: (route) => getApi().delete<void>(route),
  }
