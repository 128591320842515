import React, { FunctionComponent, useMemo } from 'react'
import {
  isPrescriptionVariable,
  isBaseVariable,
  isQuestionnaireVariable,
  isQuestionVariable,
  isManualPrescriptionVariable,
  EditorVariableDisplayConfig,
  retrieveVariableId,
} from '@follow/cdk'
import DrugVariableConfiguration from './DrugVariableConfiguration'
import { QuestionnaireVariableConfiguration } from './QuestionnaireVariableConfiguration'
import { QuestionVariableConfiguration } from './QuestionVariableConfiguration'
import { Heading } from '../../components/shared'
import {
  PendingVariableValue,
  UNDISPLAYABLE,
  VariableConfigurationProps,
} from './VariableConfiguration.model'

import styles from './VariableConfiguration.module.scss'
import BaseVariableConfiguration from './BaseVariableConfiguration'
import { CommonVariableActions } from './CommonVariableActions'
import ManualPrescriptionConfiguaration from './ManualPrescriptionConfiguration'
import { MedicalEventDocumentType } from '../../model/MedicalEvent'
import { VariableType, findVariable, isFixedValueUpToDate, getFixedValue } from '@follow/farte'
import { Inline } from 'slate'

export const VariableConfiguration: FunctionComponent<VariableConfigurationProps> = ({
  isAdmin,
  variableId,
  variableTitle,
  variableContext,
  inUseDocumentTemplate,
  selectedMedicalEventDocument,
  editorValue,
  variablesData,
  onConfigureQuestion,
  onSelectVariableDisplayConfig,
  onCopyVariable,
  onCopyVariableText,
  onChangeVariableToText,
  onChangeVariablePosition,
  onRemoveVariable,
  onUpdateVariableFixedValue,
  hide,
}) => {
  const isManualPrescription = isManualPrescriptionVariable(variableId)
  const isPrescription = isPrescriptionVariable(variableId)
  const isQuestionnaire = isQuestionnaireVariable(variableId)
  const isQuestion = isQuestionVariable(variableId)
  const isBase = isBaseVariable(variableId)
  const displayConfig = variableContext.displayConfig as EditorVariableDisplayConfig
  const titleNode = (
    <div className={styles.titleContainer}>
      <Heading size={3}>{variableTitle}</Heading>
      {isAdmin && <Heading size={5}>{variableId}</Heading>}
    </div>
  )
  const inUseEntity = useMemo(() => {
    if (selectedMedicalEventDocument?.type === MedicalEventDocumentType.FW_DOCUMENT) {
      return selectedMedicalEventDocument.item
    }
    return inUseDocumentTemplate
  }, [inUseDocumentTemplate, selectedMedicalEventDocument])

  const inUseQuestion = useMemo(() => {
    if (inUseEntity?.type === 'farte' && isQuestion) {
      const questionId = retrieveVariableId(variableId)
      return inUseEntity.variables.find(({ id }) => id === questionId)
    }
  }, [inUseEntity, isQuestion, variableId])

  const pendingValue = useMemo<PendingVariableValue>(() => {
    const variable: Inline | undefined = findVariable(editorValue, variableId)
    if (variable) {
      const fixedValue = getFixedValue(variable)
      const data: VariableType | undefined = variablesData[variableId]
      if (data && !isFixedValueUpToDate(data, fixedValue)) {
        if (data.type === 'normal' && data.value !== null) {
          return data.value
        }
        return UNDISPLAYABLE
      }
    }
    return null
  }, [editorValue, variableId, variablesData])

  const handleUpdateVariableFixedValue = useMemo(() => {
    if (!onUpdateVariableFixedValue || pendingValue === null) return undefined
    return () => onUpdateVariableFixedValue(variableId)
  }, [onUpdateVariableFixedValue, pendingValue, variableId])

  const commonProps = {
    onCopyVariable,
    onCopyVariableText,
    onChangeVariableToText,
    onUpdateVariableFixedValue: handleUpdateVariableFixedValue,
  }

  return inUseEntity?.type === 'farte' ? (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        {isManualPrescription && (
          <ManualPrescriptionConfiguaration
            variableId={variableId}
            inUseEntity={inUseEntity}
            onRemoveVariable={onRemoveVariable}
            hide={hide}
            {...commonProps}
          />
        )}
        {isPrescription && (
          <DrugVariableConfiguration
            variableId={variableId}
            displayConfig={displayConfig}
            renderTitle={titleNode}
            inUseEntity={inUseEntity}
            editorValue={editorValue}
            isAdmin={isAdmin}
            variableTitle={variableTitle}
            onSelectVariableDisplayConfig={onSelectVariableDisplayConfig}
            onChangeVariablePosition={onChangeVariablePosition}
            hideContextualMenu={hide}
            {...commonProps}
          />
        )}
        {isQuestionnaire && (
          <QuestionnaireVariableConfiguration
            variableId={variableId}
            displayConfig={displayConfig}
            renderTitle={titleNode}
            questionnaires={inUseEntity.questionnaires}
            onSelectQuestionnaireDisplayConfig={onSelectVariableDisplayConfig}
            {...commonProps}
          />
        )}
        {isQuestion && (
          <QuestionVariableConfiguration
            variableId={variableId}
            readonly={inUseQuestion?.isEditable === false}
            renderTitle={titleNode}
            onConfigureQuestion={onConfigureQuestion}
            {...commonProps}
          />
        )}
        {isBase && (
          <BaseVariableConfiguration
            variableId={variableId}
            renderTitle={titleNode}
            displayConfig={displayConfig}
            pendingValue={pendingValue}
            onDisplayConfigChange={onSelectVariableDisplayConfig}
            {...commonProps}
          />
        )}
        {!isManualPrescription && !isPrescription && !isQuestion && !isQuestionnaire && !isBase && (
          <CommonVariableActions {...commonProps}>{titleNode}</CommonVariableActions>
        )}
      </div>
    </div>
  ) : null
}
