import { useContext, useMemo } from 'react'
import { stellairKeys } from './stellair.keys'
import { useQuery } from '@tanstack/react-query'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { isDefined } from '../../../../misc/functions.utilities'
import { loginStellair } from '../../../../data/vitalCard/stellair/loginStellair'

interface LoginStellairParams {
  code: string | null
}

export const useLoginStellair = ({ code }: LoginStellairParams) => {
  const queryKey = useMemo(() => stellairKeys.login, [])
  const { loggedUser } = useContext(ConnectedUserContext)

  const queryReturn = useQuery(
    queryKey,
    async () => {
      if (!code) return

      const request = await loginStellair(code)

      if (!request.ok) {
        throw new Error()
      }
      window.close()

      return null
    },
    {
      enabled: !!code && isDefined(loggedUser),
    },
  )

  return queryReturn
}
