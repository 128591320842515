import { atom } from 'jotai'
import { ConfigurableExportFilters } from '../../model/ConfigurableExport'
import { LightQuestionnaire, QuestionTemplate } from '../../model/Questionnaire'
import { atomWithReset, RESET } from 'jotai/utils'
import { TagType } from '../../model/Tag'
import { addItemIfMissing } from './exportAtoms.utils'
import { DocumentTemplateListItem } from '../../model/DocumentTemplate'
import { unionBy } from 'lodash'
import { SetAnswerValue } from '../../model/AnswerValue'
import { isDefined } from '../../misc/functions.utilities'

export const EXPORTS_DEFAULT_FILTERS: ConfigurableExportFilters = {
  documentTemplates: [],
  questionnaires: [],
  questions: [],
  patientLabels: [],
}

const exportFiltersAtom = atomWithReset(EXPORTS_DEFAULT_FILTERS)
export const exportFiltersStateAtom = atom(
  (get) => get(exportFiltersAtom),
  (_, set) => set(exportFiltersAtom, RESET),
)

// Tags
export const exportFiltersSetTags = atom(null, (get, set, tags: TagType[]) => {
  const currentValue = get(exportFiltersAtom)
  set(exportFiltersAtom, {
    ...currentValue,
    patientLabels: tags,
  })
})

// Questionnaires
export const exportFilterAddQuestionnaire = atom(null, (get, set, item: LightQuestionnaire) => {
  const currentValue = get(exportFiltersAtom)
  const questionnaires = addItemIfMissing(currentValue.questionnaires, item)
  set(exportFiltersAtom, {
    ...currentValue,
    questionnaires,
  })
})

export const exportFilterRemoveQuestionnaire = atom(null, (get, set, itemId: number) => {
  const currentValue = get(exportFiltersAtom)
  const questionnaires = currentValue.questionnaires.filter(({ id }) => itemId !== id)
  set(exportFiltersAtom, {
    ...currentValue,
    questionnaires,
  })
})

// Questions
export const exportFilterAddQuestion = atom(null, (get, set, item: QuestionTemplate) => {
  const currentValue = get(exportFiltersAtom)
  const questions = addItemIfMissing(currentValue.questions, { ...item, answerValues: null })
  set(exportFiltersAtom, {
    ...currentValue,
    questions,
  })
})

export const exportFilterQuestionSetAnswerValue = atom(
  null,
  (get, set, questionId: number, answerValue: SetAnswerValue) => {
    const currentValue = get(exportFiltersAtom)
    const questionIndex = currentValue.questions.findIndex(({ id }) => id === questionId)
    if (isDefined(questionIndex)) {
      const updatedQuestionList = [...currentValue.questions]
      updatedQuestionList[questionIndex] = {
        ...currentValue.questions[questionIndex],
        answerValues: answerValue.value,
      }
      set(exportFiltersAtom, {
        ...currentValue,
        questions: updatedQuestionList,
      })
    }
  },
)

export const exportFilterRemoveQuestion = atom(null, (get, set, itemId: number) => {
  const currentValue = get(exportFiltersAtom)
  const questions = currentValue.questions.filter(({ id }) => itemId !== id)
  set(exportFiltersAtom, {
    ...currentValue,
    questions,
  })
})

// Document templates
export const exportFilterAddDocumentTemplate = atom(
  null,
  (get, set, items: DocumentTemplateListItem[]) => {
    const currentValue = get(exportFiltersAtom)
    const documentTemplates = unionBy(currentValue.documentTemplates, items, 'id')
    set(exportFiltersAtom, {
      ...currentValue,
      documentTemplates,
    })
  },
)

export const exportFilterRemoveDocumentTemplate = atom(null, (get, set, itemId: number) => {
  const currentValue = get(exportFiltersAtom)
  const documentTemplates = currentValue.documentTemplates.filter(({ id }) => itemId !== id)
  set(exportFiltersAtom, {
    ...currentValue,
    documentTemplates,
  })
})
