import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteAnswerInstance } from '../../../data/answerInstances'
import { questionInstancesKeys } from '../questionInstances/questionInstance.keys'

interface AnswerInstanceMutateVariable {
  id: number
  questionInstanceId: number
  questionnaireInstanceId?: number
}

export const useDeleteAnswerInstance = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, AnswerInstanceMutateVariable>(
    async ({ id }) => {
      const { ok } = await deleteAnswerInstance(id)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la réponse a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { questionInstanceId, questionnaireInstanceId }) => {
        queryClient.invalidateQueries(questionInstancesKeys.detail(questionInstanceId))
        // if (questionnaireId) {
        //   queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
        // }
      },
    },
  )
}
