import React, { FunctionComponent } from 'react'
import { InlineTagProps, InlineTagColors } from './InlineTag.model'
import classNames from 'classnames'

export const InlineTag: FunctionComponent<InlineTagProps> = React.forwardRef<
  HTMLSpanElement,
  InlineTagProps
>(({ color = 'primaryDefault', children, onClick }, ref) => {
  const colors = InlineTagColors[color]

  return (
    <span
      ref={ref}
      className={classNames(
        colors.background,
        colors.color,
        'inline-flex items-center px-2 py-1 rounded text-xs leading-4 font-semibold capitalize h-max w-max',
      )}
      onClick={onClick}
    >
      {children}
    </span>
  )
})
