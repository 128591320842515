import {
  BaseAnswer,
  Question,
  QuestionLevel,
  questionPlaceholder,
  QuestionTemplate,
  QuestionType,
  subQuestionPlaceholder,
} from '../../model/Questionnaire'
import { SetAnswerValue } from '../../model/AnswerValue'
import { MultiSelectOption } from '../../model/SelectOption'

const AUTOCOMPLETE_BREAKPOINT = 8

export enum QuestionnaireMode {
  managerEdit = 'managerEdit',
  fill = 'fill',
  exportForm = 'exportForm',
}

export type AddSubQuestionCallback = (
  type: QuestionType,
  answerId: number,
  displayOrder: number,
) => void

export type ShowSubQuestionsCallback = (show: boolean) => void
export type SetAnswerValueCallback = (questionId: number, answerValue: SetAnswerValue) => void
export type DeleteQuestionCallback = (question: Question) => void
export type EditQuestionCallback = (question: Question) => void
export type ChangeQuestionImportanceCallback = (question: Question, toggle: boolean) => void

export interface QuestionComponentProps {
  mode: QuestionnaireMode
  question: QuestionTemplate
  isReadonly?: boolean
  disabled?: boolean
}

export const isAutocompletable = (answers: ReadonlyArray<BaseAnswer>) =>
  answers.length >= AUTOCOMPLETE_BREAKPOINT && !answers.find((answer) => !!answer.imageFile)

export const hasAtLeastOneImage = (answers: ReadonlyArray<BaseAnswer>) =>
  answers.some((answer) => !!answer.imageFile)

export const getAutocompleteValues = (
  answerValueIds: number[],
  answers: BaseAnswer[],
): MultiSelectOption<number>[] => {
  return answers
    .filter((option) => answerValueIds.find((id) => id === option.id))
    .map((answer) => ({
      value: answer.id,
      label: answer.title,
    }))
}

export const getQuestionLevelPlaceholder = (question: Question | QuestionTemplate) =>
  !!question.parentQuestionId
    ? subQuestionPlaceholder[question.type]
    : questionPlaceholder[question.type]

export const getQuestionLevel = (parentQuestionId: number | null) =>
  !!parentQuestionId ? QuestionLevel.SubQuestion : QuestionLevel.Question
