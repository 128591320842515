import { VitalCardContentGroup } from '../../../../model/Sephira'

export const SEPHIRA_BDAY_FORMAT = 'yyyyMMddHHmm'

export interface SephiraPayload {
  args: {
    DateConsultation: string // "yyyyMMdd"
    NomRessourceLecteur: string
    NomRessourcePS: string
    CodePorteurPS?: string
  }
}

export interface SephiraVitalCardResponse {
  args?: SephiraPayload // args du payload
  groups_o?: Array<VitalCardContentGroup>
  report: {
    exception?: string
    call?: number
    exit?: number
    groups_o: Array<Array<number>>
  }
}
