import { FunctionComponent, useMemo, ReactNode, useState, useEffect } from 'react'
import { AlertBanner, RoundedButton } from '../../shared'
import { PatientMergeBannerProps } from './PatientMergeBanner.model'
import { Patient } from '../../../model/Patient'

import styles from './PatientMergeBanner.module.scss'
import { useNavigate } from 'react-router-dom'
import { useGetPatient, useMergePatient } from '../../../hooks/queries/patients'
import { isDefined } from '../../../misc/functions.utilities'

type Mode = 'suggest' | 'apply'

function getTitle(mode: Mode, patientDuplicatedInfo: Patient | null): string {
  switch (mode) {
    case 'suggest':
      return `Patient similaire détecté chez le Dr ${patientDuplicatedInfo?.owner?.familyName} ${patientDuplicatedInfo?.owner?.firstName}`
    case 'apply':
      return 'Valider la fusion'
  }
}
function getMessage(mode: Mode, isMergeable: boolean): string {
  switch (mode) {
    case 'suggest':
      return `L’identité de ce patient est parfaitement identique à un autre patient. ${
        isMergeable ? 'Voulez vous le fusionner ?' : ''
      }`
    case 'apply':
      return 'Les informations générales du patient similaire rempliront celles qui sont absentes pour patient courant. Les rappels, événements médicaux et leurs contenus seront déplacés sur le patient courant. Cette opération est irréversible. Voulez-vous continuer ?'
  }
}

export const PatientMergeBanner: FunctionComponent<PatientMergeBannerProps> = ({ patient }) => {
  const { mutate: mergePatient } = useMergePatient()
  const {
    query: { data: mergeablePatientData },
  } = useGetPatient({
    id: patient.mergeablePatientId as number,
    enabled: isDefined(patient.mergeablePatientId),
  })
  const {
    query: { data: duplicatedPatientData },
  } = useGetPatient({
    id: patient.duplicatedPatientId as number,
    enabled: isDefined(patient.duplicatedPatientId),
  })
  const [mode, setMode] = useState<Mode>('suggest')
  const [isMergeable, setIsMergeable] = useState(false)
  const [duplicatedPatient, setDuplicatedPatient] = useState<Patient>()
  const [merging, setMerging] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (mergeablePatientData) {
      setIsMergeable(true)
      setDuplicatedPatient(mergeablePatientData)
    } else if (duplicatedPatientData) {
      setDuplicatedPatient(duplicatedPatientData)
    }
  }, [duplicatedPatientData, mergeablePatientData])

  const title = useMemo(
    () => (duplicatedPatient ? getTitle(mode, duplicatedPatient) : ''),
    [mode, duplicatedPatient],
  )

  const text = useMemo(
    () => (duplicatedPatient ? getMessage(mode, isMergeable) : ''),
    [mode, duplicatedPatient, isMergeable],
  )

  if (!patient.mergeablePatientId && !patient.duplicatedPatientId) {
    return null
  }

  const handleMergePatient = () => {
    if (duplicatedPatient && isMergeable) {
      setMerging(true)
      mergePatient({
        patientId: patient.id,
        patientToMergeId: duplicatedPatient.id,
      })
    }
  }
  const handleGoToPatient = () =>
    duplicatedPatient && navigate(`/patients/${duplicatedPatient.id}`, { replace: true })

  function getActionButtons(buttonMode: Mode): ReactNode {
    switch (buttonMode) {
      case 'suggest':
        return (
          <>
            <div className={styles.button}>
              <RoundedButton
                onClick={handleGoToPatient}
                label="Consulter le patient"
                theme="transparent"
              />
            </div>
            {isMergeable && (
              <div className={styles.button}>
                <RoundedButton label="Fusionner" theme="warning" onClick={() => setMode('apply')} />
              </div>
            )}
          </>
        )
      case 'apply':
        return (
          <>
            <div className={styles.button}>
              <RoundedButton
                onClick={() => {
                  setMode('suggest')
                }}
                label="Ne rien faire"
                theme="transparent"
              />
            </div>
            <div className={styles.button}>
              <RoundedButton
                label="Confirmer"
                theme="warning"
                disabled={merging}
                onClick={handleMergePatient}
              />
            </div>
          </>
        )
    }
  }
  return (
    <AlertBanner
      alerts={[
        {
          code: 'alert_patient_need_merged',
          title: title,
          text: text,
          actionButton: getActionButtons(mode),
          color: 'blue',
          icon: 'infoCircle',
        },
      ]}
    />
  )
}
