import { isDefined } from '../../../../misc/functions.utilities'
import { BottomPanel } from '../BottomPanel'
import { Button } from '../../buttons'
import { EditQuestionInstancePanel } from '../../../../containers/Question/EditQuestionInstancePanel'
import { EditQuestionInstanceBottomPanelProps } from './EditQuestionInstanceBottomPanel.model'
import { useGetQuestionInstance } from '../../../../hooks/queries/questionInstances'

export const EditQuestionInstanceBottomPanel = ({
  onRequestClose,
  itemId,
  onItemEdited,
  isUnmounting,
}: EditQuestionInstanceBottomPanelProps) => {
  const {
    query: { data: questionInstance },
  } = useGetQuestionInstance({
    enabled: itemId !== null,
    id: itemId ?? -1,
  })

  const topBarActions = (
    <Button
      onClick={onRequestClose}
      theme="primary"
      label="OK"
      disabled={!questionInstance?.isEditable}
      testId="button-bottom_panel-submit"
    />
  )

  return (
    <BottomPanel
      title={`Éditer la question`}
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
      actions={topBarActions}
      hint={`La question ne sera modifiée que pour cet événement médical.`}
      hintTooltip={
        <>
          Si vous éditez un questionnaire ou une question depuis le manager, alors les modifications
          ne seront pas appliquées aux anciens événement médicaux.
          <br />
          <br />
          Si vous éditez un questionnaire ou une question depuis un évènement médical, alors les
          modifications ne concerneront que cet événement médical.
        </>
      }
    >
      {isDefined(questionInstance) && (
        <EditQuestionInstancePanel
          questionInstance={questionInstance}
          onQuestionEdited={onItemEdited}
        />
      )}
    </BottomPanel>
  )
}
