import React, { FunctionComponent, useState, useContext } from 'react'
import { Qcm, Qcu, Slider, TextLibre } from '.'
import { QuestionType } from '../../../model/Questionnaire'
import { QuestionTemplateProps, CollapsableQuestionProps } from '../QuestionList/QuestionList.model'
import { QuestionContext } from '../context/QuestionContext'
import { DateQuestion } from './DateQuestion'

function getQuestionComponent(props: QuestionTemplateProps) {
  switch (props.questionType) {
    case QuestionType.Text:
      return (
        <TextLibre
          mode={props.mode}
          question={props.question}
          answerValue={props.answerValues}
          setAnswerValue={(value) =>
            props.setAnswerValue &&
            props.questionType &&
            props.setAnswerValue({ questionType: props.questionType, value })
          }
          disabled={props.disabled}
          isReadonly={props.isReadonly}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          onChangeQuestionImportance={props.onChangeQuestionImportance}
        />
      )

    case QuestionType.Slider:
      return (
        <Slider
          mode={props.mode}
          question={props.question}
          answerValue={props.answerValues}
          setAnswerValue={(value) =>
            props.setAnswerValue &&
            props.questionType &&
            props.setAnswerValue({ questionType: props.questionType, value })
          }
          disabled={props.disabled}
          isReadonly={props.isReadonly}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          onChangeQuestionImportance={props.onChangeQuestionImportance}
        />
      )

    case QuestionType.QCU:
    case QuestionType.YesNo:
    case QuestionType.BtnGroup:
      return (
        <Qcu
          mode={props.mode}
          question={props.question}
          answerValueId={props.answerValues}
          selectAnswer={(value) =>
            props.setAnswerValue &&
            props.questionType &&
            props.setAnswerValue({ questionType: props.questionType, value })
          }
          disabled={props.disabled}
          isReadonly={props.isReadonly}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          onChangeQuestionImportance={props.onChangeQuestionImportance}
          onAddSubQuestion={props.onAddSubQuestion}
          subQuestionsShown={!!props.subQuestionsShown}
          onDeleteSubQuestion={props.onDeleteSubQuestion}
          onShowSubQuestions={props.onShowSubQuestions}
          setSubQuestionsAnswerValues={props.setSubQuestionsAnswerValues}
        />
      )

    case QuestionType.QCM:
      return (
        <Qcm
          mode={props.mode}
          question={props.question}
          answerValueIds={props.answerValues}
          selectAnswers={(value) =>
            props.setAnswerValue &&
            props.questionType &&
            props.setAnswerValue({ questionType: props.questionType, value })
          }
          disabled={props.disabled}
          isReadonly={props.isReadonly}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          onChangeQuestionImportance={props.onChangeQuestionImportance}
          onAddSubQuestion={props.onAddSubQuestion}
          subQuestionsShown={!!props.subQuestionsShown}
          onDeleteSubQuestion={props.onDeleteSubQuestion}
          onShowSubQuestions={props.onShowSubQuestions}
          setSubQuestionsAnswerValues={props.setSubQuestionsAnswerValues}
        />
      )
    case QuestionType.Date:
      return (
        <DateQuestion
          mode={props.mode}
          question={props.question}
          answerValue={props.answerValues}
          setAnswerValue={(value) =>
            props.setAnswerValue &&
            props.questionType &&
            props.setAnswerValue({
              questionType: props.questionType,
              value: value?.toISOString(),
            })
          }
          disabled={props.disabled}
          isReadonly={props.isReadonly}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          onChangeQuestionImportance={props.onChangeQuestionImportance}
        />
      )
    default:
      return <div style={{ color: 'red' }}>Type de question non géré : {props.questionType}</div>
  }
}

export const QuestionCard: FunctionComponent<
  Omit<CollapsableQuestionProps, 'onShowSubQuestions'>
> = (props) => {
  const [subQuestionsShown, setSubQuestionsShown] = useState<boolean>(false)

  const questionContext = useContext(QuestionContext)

  // Cast en dur pour contourner le problème Typescript https://github.com/microsoft/TypeScript/issues/28339
  // tslint:disable-next-line: no-object-literal-type-assertion
  const fillQuestionProps: QuestionTemplateProps = {
    ...props,
    subQuestionsShown,
    onShowSubQuestions: props.hideSubQuestions ? undefined : setSubQuestionsShown,
  } as QuestionTemplateProps

  const questionComponent = getQuestionComponent(fillQuestionProps)

  const width = subQuestionsShown || questionContext.singleColumnDisplay ? '100%' : '50%'

  return (
    <div
      ref={props.dndRef}
      style={{
        width,
        cursor: !!props.dndRef ? 'move' : 'auto',
        opacity: props.attenuated ? 0.3 : 1,
        transition: 'opacity 0.2s ease-in',
      }}
    >
      {questionComponent}
    </div>
  )
}
