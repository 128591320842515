import getApi from '../../api'
import {
  QuestionnaireTemplateResource,
  QuestionnaireTemplateToClonePayload,
} from './mapper/questionnaireTemplate.model'
import { deserializeQuestionnaireTemplateResponse } from './mapper/questionnaireTemplate.mapper'

export const cloneQuestionnaireTemplate = (
  questionnaireTemplateToClonePayload: QuestionnaireTemplateToClonePayload,
) =>
  getApi()
    .post<QuestionnaireTemplateResource>(
      '/questionnaire_templates/clone',
      questionnaireTemplateToClonePayload,
    )
    .then(deserializeQuestionnaireTemplateResponse)
