import { QuestionInstanceResource } from './questionInstances.model'
import { QuestionInstance, QuestionTemplate } from '../../../model/Questionnaire'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { deserializeAnswerInstance } from '../../answerInstances/mapper/answerInstance.mapper'
import { QuestionInstanceCreationResource } from '../../../store/cache/questionInstances/api/QuestionInstances.model'

export function deserializeQuestionInstance(
  questionTemplate: QuestionInstanceResource,
): QuestionTemplate {
  return {
    ...questionTemplate,
    answers: questionTemplate.answers.map(deserializeAnswerInstance),
  }
}

export function deserializeQuestionInstances(
  questionTemplates: QuestionInstanceResource[],
): QuestionTemplate[] {
  return questionTemplates.map(deserializeQuestionInstance)
}

export function serializeQuestionInstance({
  answers,
  parentAnswerId,
  ...restProps
}: Partial<QuestionTemplate>): Partial<QuestionInstanceCreationResource> {
  return {
    ...restProps,
    parentAnswerId: parentAnswerId,
    answers: answers && answers.map(({ id }) => id),
  }
}

export function deserializeQuestionInstanceResponse(
  response: ApiResponse<QuestionInstanceResource>,
): ApiResponse<QuestionInstance> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestionInstance(data),
  }
}

export function deserializeQuestionInstancesPageResponse(
  questionTemplatesPage: ApiResponse<PaginatedList<QuestionInstanceResource>>,
) {
  const { data, ...responseRest } = questionTemplatesPage
  // Error case => no deserialization
  if (!questionTemplatesPage.ok || !data) {
    return { ...responseRest, data: null }
  }
  return {
    ...responseRest,
    data: { ...data, items: deserializeQuestionInstances(data.items) },
  }
}
