import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { useNavigate } from 'react-router-dom'
import { getPatientByVitalCardInfos } from '../../../data/patients/getPatientByVitalCardInfos'
import { computePatientDifferenceToVitalCard } from '../../../misc/vitalCard.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { Patient } from '../../../model/Patient'
import { beneficiariesAtom, beneficiaryDifferencesAtom } from '../../../state/vitalCard'
import { useUpdatePatient } from '../patients'

interface MutationVariables {
  patient: Partial<Patient>
  isPatientFormActive: boolean
}

export const useHandleBeneficiary = () => {
  const navigate = useNavigate()
  const setBeneficiaryDifferencesState = useSetAtom(beneficiaryDifferencesAtom)
  const setBeneficiariesState = useSetAtom(beneficiariesAtom)

  const { mutateAsync: updatePatient } = useUpdatePatient()

  const mutation = useMutation<void, Error, MutationVariables>({
    mutationFn: async ({ patient, isPatientFormActive }) => {
      setBeneficiariesState((current) => ({ ...current, selected: patient }))

      // Recherche d'un éventuel patient existant
      const { data, ok, status } = await getPatientByVitalCardInfos(patient)

      if (ok && data) {
        // Patient trouvé
        if (isPatientFormActive) {
          // On hydrate le formulaire avec les données de la carte
          navigate('?vital=true', {
            replace: true,
          })
          return
        }

        // Calcul des differences entre le dossier et les infos de la CV
        const differences = computePatientDifferenceToVitalCard(data, patient)
        if (differences) {
          // Différences trouvées, on ouvre la modale de proposition de mise à jour
          const differencesSelectionPromise = new Promise<boolean>((resolve, reject) => {
            const promiseCallback = (selection: boolean) => resolve(selection)
            setBeneficiaryDifferencesState({
              differences,
              onSelectDifferenceOptions: promiseCallback,
            })
          })
          // On peut alors await le callback, ce qui permettra de poursuivre quand l'utilisateur aura choisi d'appliquer les modifs ou non
          const applyDifferences = await differencesSelectionPromise

          if (applyDifferences) {
            await updatePatient({
              id: data.id,
              payload: differences,
            })
          }
          setBeneficiaryDifferencesState(RESET)
        }

        // Enfin, on redirige vers le dossier du patient
        navigate(`/patients/${data.id}`)
      } else {
        // Si une erreur autre que 404 survient, on redirige vers le formulaire comme comportement par défaut
        //  mais on prévient l'utilisateur que ce n'est pas le "happy path"
        if (status !== 404) {
          throw new UserFriendlyError(
            'warn',
            'Erreur lors de la recherche de patient existant',
            'Impossible de déterminer si un patient existant correspond à ces informations',
          )
        }
        // Pas de patient connu de Follow, redirection vers la création
        navigate('/patients/new?vital=true', {
          replace: isPatientFormActive,
        })
      }
    },
  })

  return mutation
}
