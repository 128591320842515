import React, { FunctionComponent, ReactNode } from 'react'
import { QuestionContext } from '../../../components/questionnaire'

export const WithQuestionContext: FunctionComponent<{
  singleColumnDisplay: boolean
  children?: ReactNode
  scrollToAnswerId?: number
  readOnly?: boolean
}> = ({ singleColumnDisplay, scrollToAnswerId, children, readOnly = false }) => {
  return (
    <QuestionContext.Provider
      value={{
        singleColumnDisplay,
        scrollToAnswerId,
      }}
    >
      {children}
    </QuestionContext.Provider>
  )
}
