import { City, Contact } from '../../../../model/Contact'
import { Address } from '../../../../model/Address'
import { PageList } from '../../../../store/restux/ui'
import { bottomPanelContactsActions } from '../../../../store/ui/bottomPanelContacts'
import { uiCitiesActions } from '../../../../store/ui/cities'
import { uiContactsActions } from '../../../../store/ui/contacts'
import { SelectOption } from '../../../../model/SelectOption'

export interface PatientContactsFormBlockStoreProps {
  predictions: PageList<Contact>
  cities: PageList<City>
}

export interface PatientContactsFormBlockDispatchProps {
  searchContact: typeof uiContactsActions.requestPage
  clearPredictions: typeof uiContactsActions.clearPagination
  openCreateContactForm: typeof bottomPanelContactsActions.openCreateContactForm
  openEditContactForm: typeof bottomPanelContactsActions.openEditContactForm
  searchCity: typeof uiCitiesActions.actions.requestPage
}

export interface PatientContactsFormBlockBase {
  colorPreset?: 'light' | 'dark'
  patientAddress?: Address | null
}

export const CONTACT_VISIBILITY_OPTIONS: SelectOption<boolean | null>[] = [
  { value: null, label: 'Tous' },
  { value: false, label: 'Publics' },
  { value: true, label: 'Privés' },
]

export type PatientContactsFormBlockProps = PatientContactsFormBlockBase &
  PatientContactsFormBlockStoreProps &
  PatientContactsFormBlockDispatchProps
