import { connect } from 'react-redux'
import { PublicContactSearchModal } from './PublicContactSearchModal.component'
import {
  PublicContactSearchModalDispatchProps,
  PublicContactSearchModalStoreProps,
} from './PublicContactSearchModal.model'
import { RootState } from '../../../../store'
import { contactsPageSelector, uiContactsActions } from '../../../../store/ui/contacts'
import { withUnmountTransition } from '../../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): PublicContactSearchModalStoreProps => ({
  contacts: contactsPageSelector(state),
})

const mapDispatchToProps: PublicContactSearchModalDispatchProps = {
  fetchContact: uiContactsActions.requestPage,
  clearContact: uiContactsActions.clearPagination,
}

const withUnmountHoc = withUnmountTransition(PublicContactSearchModal)
const withConnect = connect(mapStateToProps, mapDispatchToProps)(withUnmountHoc)
export { withConnect as PublicContactSearchModal }
