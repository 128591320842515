import { useMutation } from '@tanstack/react-query'
import { getStellairVitalCardInfos } from '../../../../data/vitalCard'
import { UserFriendlyError } from '../../../../model/Error'
import { StellairVitalCardInfos } from '../../../../model/Stellair'
import { stellairLoginPrompt } from '../../../../misc/Stellair/stellair.actions'
import { useCallback } from 'react'
import { VitalCardReadReturn } from '../vitalCard.model'
import { parseStellairCardInfos } from '../../../../misc/Stellair/stellair.mapper'
import { isDefined } from '../../../../misc/functions.utilities'

export const useVitalcardStellair = () => {
  const mutation = useMutation<StellairVitalCardInfos | null, Error>({
    mutationFn: async () => {
      const { data, ok, status } = await getStellairVitalCardInfos()

      if (!data || !ok) {
        if (status === 400 && data?.detail) {
          const subStatus = JSON.parse(data.detail).statusCode
          switch (subStatus) {
            case 401:
              stellairLoginPrompt()
              return null
            case 404:
              throw new UserFriendlyError(
                'error',
                `Erreur de lecture`,
                `Veuillez insérer la carte vitale`,
              )
            case 428:
              throw new UserFriendlyError(
                'error',
                `Erreur de lecture`,
                `Veuillez connecter le terminal de lecture`,
              )
            default:
              throw new UserFriendlyError('error', `Erreur de lecture`, `Une erreur est survenue`)
          }
        }
        throw new UserFriendlyError(
          'error',
          `Erreur de lecture`,
          `Une erreur technique est survenue`,
        )
      }

      return data
    },
  })

  const readVitalCard = useCallback(async (): Promise<VitalCardReadReturn> => {
    const data = await mutation.mutateAsync()

    try {
      return isDefined(data) ? parseStellairCardInfos(data) : null
    } catch (e) {
      throw new UserFriendlyError('error', `Erreur de lecture`, `Le format du patient est invalide`)
    }
  }, [mutation])

  return { mutation, readVitalCard }
}
