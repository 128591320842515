import { FontFamily } from '@follow/farte'
import { FunctionComponent, useEffect, useState } from 'react'

import {
  QuestionType,
  WITH_ANSWERS_QUESTION_TYPES,
  QuestionTemplate,
  AnswerTemplate,
} from '../../../model/Questionnaire'

import { Button, Input, ValidationModal } from '../../../components/shared'

import EditAnswer from '../EditAnswer'

import { EditQuestionPanelProps } from './EditQuestionPanel.model'

import { EditSliderConfig } from '../EditSliderConfig'
import { useDebounce } from 'react-use'

const NO_CODE_QUESTION_TYPES = [QuestionType.QCM, QuestionType.Text]

const EditWithAnswersQuestion: FunctionComponent<{
  answers: AnswerTemplate[]
  canEditPoints: boolean
  isEditable: boolean
  isDeletable: boolean
  editorFontFamily: FontFamily
  isChild: boolean
  questionType: QuestionType
  onAddAnswer: () => void
  onAddImageToAnswer: (answer: AnswerTemplate, file: File) => void
  onDeleteAnswer: (answer: AnswerTemplate) => void
  onUpdateAnswer: (id: number, updates: Partial<AnswerTemplate>) => void
  onRemoveImageFromAnswer: (answer: AnswerTemplate) => void
  onUpdateQuestion: (question: Partial<QuestionTemplate>) => void
}> = ({
  answers,
  canEditPoints,
  editorFontFamily,
  isChild,
  questionType,
  onAddAnswer,
  onAddImageToAnswer,
  onDeleteAnswer,
  onRemoveImageFromAnswer,
  onUpdateAnswer,
  onUpdateQuestion,
  isEditable,
  isDeletable,
}) => {
  const [openSwitchValidation, setOpenSwitchValidation] = useState(false)
  return (
    <>
      <div>
        {answers.map((answer) => (
          <EditAnswer
            key={answer.id}
            answer={answer}
            canEditPoints={canEditPoints}
            editorFontFamily={editorFontFamily}
            isChild={isChild}
            onAddImage={onAddImageToAnswer}
            onRemoveImage={onRemoveImageFromAnswer}
            onDeleteAnswer={onDeleteAnswer}
            onUpdateAnswer={onUpdateAnswer}
            isDeletable={isDeletable}
            isEditable={isEditable}
          />
        ))}
      </div>
      {isEditable && (
        <div className="mt-4 flex w-max">
          <Button
            label="Ajouter un choix"
            icon="add"
            theme="primary"
            onClick={onAddAnswer}
            testId="button-answer-add"
          />
          {questionType === QuestionType.QCU && (
            <div className="ml-2">
              <Button
                icon="shuffle"
                onClick={() => setOpenSwitchValidation(true)}
                theme="dark"
                label="Convertir en QCM"
                testId="button-answer-QCM_convert"
              />
            </div>
          )}
        </div>
      )}
      {isEditable && (
        <ValidationModal
          display={openSwitchValidation}
          title="Voulez-vous convertir cette question en QCM ? Cette action est irréversible."
          testId="modal-QCM_convert"
          onClose={() => setOpenSwitchValidation(false)}
          onSubmit={() => {
            onUpdateQuestion({ type: QuestionType.QCM })
            setOpenSwitchValidation(false)
          }}
        />
      )}
    </>
  )
}
export const EditQuestionPanel: FunctionComponent<EditQuestionPanelProps> = ({
  question: inUseQuestion,
  questionLevelDefaultLabel,
  placeholder,
  isAdmin,
  canHandleScore,
  handleAddAnswer,
  handleAddImageToAnswer,
  handleDeleteAnswer,
  HandleRemoveImageFromAnswer,
  handleUpdateAnswer,
  handleUpdateQuestion,
  onConfigValueChange,
  editorFontFamily,
}) => {
  const [initialized, setInitialized] = useState(false)
  const [title, setTitle] = useState('')
  const [code, setCode] = useState('')

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }
  const handleChangeScore = (e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)

  useEffect(() => {
    if (!initialized && inUseQuestion) {
      if (!title) setTitle(inUseQuestion.title)
      if (!code) setCode(inUseQuestion.code || '')
      setInitialized(true)
    }
  }, [inUseQuestion, code, title, initialized])

  const shouldUpdateTitle: () => boolean = () => inUseQuestion && inUseQuestion.title !== title

  const shouldUpdateCode: () => boolean = () =>
    inUseQuestion &&
    inUseQuestion.code !== code &&
    (!!inUseQuestion.code || code !== '') &&
    !NO_CODE_QUESTION_TYPES.includes(inUseQuestion.type)

  useDebounce(
    () => {
      if (shouldUpdateTitle() || shouldUpdateCode()) {
        handleUpdateQuestion({ title, code })
      }
    },
    500,
    [inUseQuestion, title, code],
  )

  return (
    <div className="py-4 px-8 h-full overflow-y-scroll">
      {inUseQuestion && (
        <>
          <div>
            <Input
              name="title"
              label={questionLevelDefaultLabel}
              value={title}
              placeholder={placeholder}
              onChange={handleChangeTitle}
              colorPreset="dark"
              autofocus
              disabled={!inUseQuestion.isEditable}
              testId="input-question-title"
            />
            {canHandleScore && !NO_CODE_QUESTION_TYPES.includes(inUseQuestion.type) && (
              <div className="max-w-80">
                <Input
                  name="code"
                  label="Code pour le calcul des scores"
                  value={code}
                  onChange={handleChangeScore}
                  colorPreset="dark"
                />
              </div>
            )}
          </div>
          {WITH_ANSWERS_QUESTION_TYPES.includes(inUseQuestion.type) && (
            <EditWithAnswersQuestion
              answers={inUseQuestion.answers}
              canEditPoints={canHandleScore}
              editorFontFamily={editorFontFamily}
              isChild={!!inUseQuestion.parentAnswerId}
              questionType={inUseQuestion.type}
              onAddAnswer={handleAddAnswer}
              onAddImageToAnswer={handleAddImageToAnswer}
              onDeleteAnswer={handleDeleteAnswer}
              onRemoveImageFromAnswer={HandleRemoveImageFromAnswer}
              onUpdateAnswer={handleUpdateAnswer}
              onUpdateQuestion={handleUpdateQuestion}
              isEditable={inUseQuestion.isEditable ?? false}
              isDeletable={inUseQuestion.isDeletable ?? false}
            />
          )}
          {inUseQuestion.type === QuestionType.Slider && inUseQuestion.config && (
            <EditSliderConfig
              questionConfig={inUseQuestion.config}
              isAdmin={isAdmin}
              canEditPoints={canHandleScore}
              onConfigValueChange={onConfigValueChange}
            />
          )}
        </>
      )}
    </div>
  )
}
