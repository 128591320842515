import { put, take, takeEvery } from 'redux-saga/effects'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import { questionTemplatesActions } from '../../cache/questionTemplates'
import { domainQuestionTemplatesActions } from './questionTemplates.actions'
import { DomainQuestionsActionTypes } from './questionTemplates.model'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { generateIdentifier } from '../../restux/restux.utilities'

function* deleteQuestionFromDocumentTemplateSagaWorker({
  questionId,
  documentTemplate,
}: ReturnType<typeof domainQuestionTemplatesActions.deleteQuestionFromDocumentTemplate>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.deleteQuestionFromDocumentTemplate)
  yield put(
    questionTemplatesActions.actions.apiDeleteItem(questionId, {
      identifier: taskIdentifier,
    }),
  )
  const { identifier }: ReturnType<typeof questionTemplatesActions.actions.storeDeleteDetailsItem> =
    yield take(questionTemplatesActions.types.STORE_DELETE_ITEM)
  if (identifier === taskIdentifier) {
    yield put(documentTemplatesActions.actions.apiGetItemDetails(documentTemplate.id))
  }
}

function* deleteQuestionFromDocumentTemplateSagaWatcher() {
  yield takeEvery(
    DomainQuestionsActionTypes.DELETE_QUESTION_FROM_DOCUMENT_TEMPLATE,
    deleteQuestionFromDocumentTemplateSagaWorker,
  )
}

export const questionTemplatesSagas = {
  deleteQuestionFromDocumentTemplateSagaWatcher,
}
