import React, { FC, useEffect, useMemo } from 'react'
import { VariableEditorProps } from './VariableEditor.model'
import { EditorContent, EditorContext, useEditor } from '@tiptap/react'
import { TableBubbleMenu, VariableMenu, getVariablePlugins } from '@/plugins'
import { ImageResizeBubbleMenu } from '@/plugins/imageResize/ImageResizeBubbleMenu'
import { Toolbar } from '../Toolbar'
import styles from '../../styles/editor/elfe.module.scss'
import { VariableEditorConfig } from '@/model/Config'
import { useDefaultFontFamily, useEditorConfig } from '@/hooks'
import { VariableEditorContext, EditorConfigContext } from '@/context'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryContent } from '@/components/ErrorBoundaryContent'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const VariableEditor: FC<VariableEditorProps> = ({
  renderVariableMenu,
  variableMap,
  disabled,
  tools,
  initialContent,
  themes,
  toolbarStyle,
  extraTools,
  editorRef,
  defaultFontFamily,
  authenticatedImageRequest,
  sanitizeHtmlVariable,
  onChange,
}) => {
  const editorConfig = useEditorConfig({ disabled, tools, themes, authenticatedImageRequest })

  const variableEditorConfig = useMemo<VariableEditorConfig>(
    () => ({ variableMap, renderVariableMenu, sanitizeHtmlVariable }),
    [renderVariableMenu, sanitizeHtmlVariable, variableMap],
  )

  const extensions = useMemo(
    () => getVariablePlugins({ defaultFontFamily, tools }),
    [defaultFontFamily, tools],
  )

  const editor = useEditor({
    content: initialContent,
    extensions,
    onUpdate: onChange,
    editable: !editorConfig.disabled,
    editorProps: {
      attributes: {
        class: 'with-ui',
      },
    },
  })

  useEffect(() => {
    if (editorRef) {
      editorRef.current = editor
    }
  }, [editor, editorRef])

  const { style } = useDefaultFontFamily(defaultFontFamily)

  return (
    <div className={cx('elfe-base', 'elfe-container', themes)} style={style}>
      <EditorContext.Provider value={{ editor }}>
        <VariableEditorContext.Provider value={variableEditorConfig}>
          <EditorConfigContext.Provider value={editorConfig}>
            {!editorConfig.disabled && (
              <>
                <Toolbar extraTools={extraTools} toolbarStyle={toolbarStyle} />
                <ImageResizeBubbleMenu />
                <TableBubbleMenu />
                <VariableMenu />
              </>
            )}
            <EditorContent editor={editor} />
          </EditorConfigContext.Provider>
        </VariableEditorContext.Provider>
      </EditorContext.Provider>
    </div>
  )
}

const VariableEditorWithBoundary = withErrorBoundary(VariableEditor, {
  fallbackRender: (props) => <ErrorBoundaryContent {...props} />,
})

export { VariableEditorWithBoundary as VariableEditor }
