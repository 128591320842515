import { restuxQuestionTemplatesApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { QuestionTemplate } from '../../../model/Questionnaire'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<QuestionTemplate, QuestionTemplate>({
  apiConfig: restuxQuestionTemplatesApiConfig,
  refetchList: false,
  resourceName: RestuxResources.questionTemplate,
})

export const questionTemplatesActions = actions
export const questionTemplatesReducers = reducers
export const questionTemplatesSagas = sagas
