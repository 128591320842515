import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { BottomPanelComponentType } from '../../../store/ui/bottomPanel'
import { GridLayout, Input, MultiSelectInput, RoundedButton } from '../../../components/shared'
import { EditTeamPanelProps } from './EditTeamPanel.model'
import styles from './EditTeamPanel.module.scss'
import { LightUser, User } from '../../../model/User'
import {
  SharingConfigAccessLevel,
  SharingConfig,
  SharingConfigKeys,
  Team,
} from '../../../model/Team'
import { getFullName } from '../../../misc/user.utilities'
import { DropdownUserItem } from '../../../components/user'
import {
  AccessLevelOptionValues,
  defaultSharingConfig,
} from './AccessLevelSelect/AccessLevelSelect.model'
import { AccessLevelSelect } from './AccessLevelSelect'
import { Roles } from '../../../model/Roles'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { hasAdminRole } from '../../../misc/roles.utilities'
import { MultiSelectOption } from '../../../model/SelectOption'
import { useAtom } from 'jotai'
import { editTeamPanelAtom } from '../../../state/admin'
import { RESET } from 'jotai/utils'
import { useGetInfiniteUsers, usePostTeam, usePutTeam } from '../../../hooks/queries/admin'
import { searchLimit } from '../../../constants'

const userToOption = (user: LightUser) => ({
  label: getFullName(user),
  value: user,
})

const optionsToIds = (options: ReadonlyArray<MultiSelectOption<LightUser>>) =>
  options.map(({ value }) => value.id)

const areUsersEquals = (a: LightUser, b: LightUser) => a.id === b.id

const rehydrateSharingConfig = (team: Team | null): typeof defaultSharingConfig => {
  if (team) {
    team.sharingConfig.reduce((acc, config) => {
      acc[config.resourceName] = AccessLevelOptionValues[config.accessLevel]
      return acc
    }, defaultSharingConfig)
  }
  return defaultSharingConfig
}

const rehydrateMembers = (selectedTeam: Team | null, selectedUser: User | null) => {
  if (selectedTeam) {
    return {
      members: selectedTeam.memberUsers.map(userToOption),
      contacts: selectedTeam.contactUsers.map(userToOption),
    }
  } else if (selectedUser) {
    return {
      members: [userToOption(selectedUser)],
      contacts: [],
    }
  } else {
    return {
      members: [],
      contacts: [],
    }
  }
}

const EditTeamPanel: FunctionComponent<EditTeamPanelProps> = ({
  actions: Actions,
  setBottomPanelOptions,
}) => {
  const [{ selectedTeam, selectedUser }, setPanelAtom] = useAtom(editTeamPanelAtom)
  const [name, setName] = useState<string>(selectedTeam?.name ?? '')
  const [memberUsers, setMemberUsers] = useState(
    rehydrateMembers(selectedTeam, selectedUser).members,
  )
  const [contactUsers, setContactUsers] = useState(
    rehydrateMembers(selectedTeam, selectedUser).contacts,
  )
  const [sharingConfig, setSharingConfig] = useState<typeof defaultSharingConfig>(
    rehydrateSharingConfig(selectedTeam),
  )
  const [userSearch, setUserSearch] = useState('')
  const { currentUser } = useContext(ConnectedUserContext)

  const { mutate: updateTeam } = usePutTeam()
  const { mutate: createTeam } = usePostTeam()
  const { userList, cancelPendingQuery } = useGetInfiniteUsers({
    filters: {
      search: userSearch,
      roles: Roles.DOCTOR,
    },
    limit: searchLimit,
    enabled: userSearch.length > 0,
  })

  const isAdmin = hasAdminRole(currentUser?.roles ?? [])

  const handleUserSearchChange = useCallback(
    (value: string) => {
      setUserSearch(value)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const userOptions = useMemo(
    () => (userSearch.length ? userList.map(userToOption) : []),
    [userList, userSearch],
  )

  useEffect(() => {
    return () => {
      setPanelAtom(RESET)
    }
  }, [setPanelAtom])

  const handleClick = () => {
    const reformatedSharingConfig: SharingConfig = Object.entries(sharingConfig).map(
      ([label, option]) => ({
        resourceName: SharingConfigKeys[label],
        accessLevel: option.value,
      }),
    )
    const reformatedTeam = {
      name,
      sharingConfig: reformatedSharingConfig,
      memberUserIds: optionsToIds(memberUsers),
      contactUserIds: optionsToIds(contactUsers),
    }
    if (selectedTeam) {
      updateTeam({
        teamId: selectedTeam.id,
        variables: { ...selectedTeam, ...reformatedTeam },
      })
    } else {
      createTeam({ variables: reformatedTeam })
    }
    handleClosePanel()
  }

  const handleClosePanel = () => {
    setBottomPanelOptions({
      open: false,
      componentType: BottomPanelComponentType.EditTeam,
    })
  }

  const handleSharingConfigChange = (level: SharingConfigAccessLevel, key: SharingConfigKeys) =>
    setSharingConfig((previousConf) => ({ ...previousConf, [key]: AccessLevelOptionValues[level] }))

  const handleSelectMembers = (selected: MultiSelectOption<LightUser>[]) => {
    const filteredContacts = contactUsers.filter((contact) =>
      selected.find((selMember) => contact.value.id === selMember.value.id),
    )
    setMemberUsers(selected)
    setContactUsers(filteredContacts)
  }

  return (
    <div className={styles.container}>
      {isAdmin && (
        <Actions>
          <RoundedButton theme="transparent" label="Annuler" onClick={handleClosePanel} />
          <RoundedButton theme="primary" label="Valider" onClick={handleClick} />
        </Actions>
      )}
      <form className={styles.form}>
        <GridLayout columns={2} rowsTemplate="auto 1fr" width="100%" gap="medium" padding="medium">
          <div>
            <span className={styles.sectionTitle}>Informations Générales</span>
            <div className={styles.inputContainer}>
              <Input
                name="name"
                label="Nom de l'équipe"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                colorPreset="dark"
                disabled={!isAdmin}
              />
              <MultiSelectInput
                title="Membres de l'équipe"
                placeholder="Ajouter un membre"
                colorPreset="dark"
                mode="multiline"
                disabled={!isAdmin}
                value={memberUsers}
                options={userOptions}
                emitChange={handleUserSearchChange}
                onSelect={handleSelectMembers}
                areElementsEquals={areUsersEquals}
                renderOption={({ value }) => (
                  <DropdownUserItem
                    user={value}
                    selectedValues={memberUsers.map(({ value }) => value)}
                  />
                )}
              />
              {memberUsers.length > 0 && (
                <>
                  <MultiSelectInput
                    title="Contacts de l'équipe"
                    placeholder="Ajouter un contact"
                    colorPreset="dark"
                    mode="multiline"
                    disabled={!isAdmin}
                    value={contactUsers}
                    options={memberUsers}
                    onSelect={setContactUsers}
                    renderOption={({ value }) => (
                      <DropdownUserItem
                        user={value}
                        selectedValues={contactUsers.map(({ value }) => value)}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
          <div>
            <div className={styles.sectionTitle}>Partager avec l'équipe</div>
            <div className={styles.inputContainer}>
              <AccessLevelSelect
                value={sharingConfig.documentTemplate.value}
                setValue={(level) =>
                  handleSharingConfigChange(level, SharingConfigKeys.documentTemplate)
                }
                label="Modèle de documents"
                disabled={!isAdmin}
              />
              <AccessLevelSelect
                value={sharingConfig.questionnaire.value}
                setValue={(level) =>
                  handleSharingConfigChange(level, SharingConfigKeys.questionnaire)
                }
                label="Questionnaires"
                disabled={!isAdmin}
              />
              <AccessLevelSelect
                value={sharingConfig.tag.value}
                setValue={(level) => handleSharingConfigChange(level, SharingConfigKeys.tag)}
                label="Labels"
                disabled={!isAdmin}
              />
              <AccessLevelSelect
                value={sharingConfig.patient.value}
                setValue={(level) => handleSharingConfigChange(level, SharingConfigKeys.patient)}
                label="Patients"
                disabled={!isAdmin}
              />
              <AccessLevelSelect
                value={sharingConfig.medicalEvent.value}
                setValue={(level) =>
                  handleSharingConfigChange(level, SharingConfigKeys.medicalEvent)
                }
                label="Événements médicaux"
                disabled={!isAdmin}
              />
              <AccessLevelSelect
                value={sharingConfig.contact.value}
                setValue={(level) => handleSharingConfigChange(level, SharingConfigKeys.contact)}
                label="Correspondants"
                disabled={!isAdmin}
              />
              <AccessLevelSelect
                value={sharingConfig.documentLayout.value}
                setValue={(level) =>
                  handleSharingConfigChange(level, SharingConfigKeys.documentLayout)
                }
                label="Mise en page"
                disabled={!isAdmin}
              />
            </div>
          </div>
        </GridLayout>
      </form>
    </div>
  )
}

export default EditTeamPanel
