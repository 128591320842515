export enum RestuxResources {
  adminNotifications = 'adminNotifications',
  answerValue = 'answerValue',
  atcClassification = 'atcClassification',
  assigningAuthorities = 'assigningAuthorities',
  cities = 'cities',
  codedInformations = 'codedInformations',
  contact = 'contact',
  contactProfession = 'contactProfession',
  countries = 'countries',
  currentsTreatments = 'currentsTreatments',
  documentCategories = 'documentCategories',
  documentHistory = 'documentHistory',
  documentInstance = 'documentInstance',
  documentLayout = 'documentLayout',
  documentTemplate = 'documentTemplate',
  prescription = 'prescription',
  drugs = 'drugs',
  ephemeralNotification = 'ephemeralNotification',
  manualPrescription = 'manualPrescription',
  file = 'file',
  importantInformations = 'importantInformations',
  mailContacts = 'mailContacts',
  healthDataType = 'healthDataType',
  medicalEvent = 'medicalEvent',
  medicalEventContent = 'medicalEventContent',
  patient = 'patient',
  patientSearch = 'patientSearch',
  patientHealthData = 'patientHealthData',
  patientHealthDataHistory = 'patientHealthDataHistory',
  patientImportantInformations = 'patientImportantInformations',
  patientCurrentTreatments = 'patientCurrentTreatments',
  patientManualPrescriptions = 'patientManualPrescriptions',
  patientTodo = 'patientTodo',
  pdfInstance = 'pdfInstance',
  posology = 'posology',
  prescriptionTemplate = 'prescriptionTemplate',
  question = 'question',
  questionTemplate = 'questionTemplate',
  questionnaire = 'questionnaire',
  questionnaireInstance = 'questionnaireInstance',
  questionnaireAnswerValue = 'questionnaireAnswerValue',
  quoteLine = 'quoteLine',
  resourceHistory = 'resourceHistory',
  tag = 'tag',
  usedTag = 'usedTag',
  todo = 'todo',
  user = 'user',
  team = 'team',
  userTeams = 'userTeams',
  task = 'task',
  professions = 'professions',
  specialities = 'specialities',
  mailContactSearch = 'mailContactSearch',
  mailSubject = 'mailSubject',
  metrics = 'metrics',
}
