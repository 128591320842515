import { useMemo } from 'react'
import { NotificationListFilters } from '../../../data/notifications/mapper/notifications.model'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getUserNotifications } from '../../../data/notifications'
import { UserFriendlyError } from '../../../model/Error'
import { getNextPageParam } from '../../../misc/query.utilities'
import { usePaginationState } from '../../utils/query'
import { useFlatPaginatedData } from '../../utils/query/useFlatPaginatedData.hook'
import { notificationsKeys } from './notifications.keys'

interface GetInfiniteUserNotificationsParams {
  filters: NotificationListFilters
  enabled?: boolean
}

export const useGetInfiniteUserNotifications = ({
  filters,
  enabled = true,
}: GetInfiniteUserNotificationsParams) => {
  const queryKey = useMemo(() => notificationsKeys.unread(filters.types, filters.unread), [filters])

  const query = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1, signal }) => {
      const { data, ok } = await getUserNotifications(pageParam, filters, signal)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Impossible de récupérer la liste des notifications')
      }

      return data
    },
    {
      getNextPageParam,
      staleTime: Infinity,
      enabled,
    },
  )

  const notificationList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, notificationList, paginationState }
}
