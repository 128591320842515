import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { QuestionnaireTemplateMutationPayload } from '../../../data/questionnaireTemplates/mapper/questionnaireTemplate.model'
import { createQuestionnaireTemplate } from '../../../data/questionnaireTemplates'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { QuestionnaireTemplate } from '../../../model/Questionnaire'

export const useCreateQuestionnaireTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<QuestionnaireTemplate, Error, QuestionnaireTemplateMutationPayload>(
    async (payload) => {
      const { data, ok } = await createQuestionnaireTemplate(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du questionnaire a échoué')
      }
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(questionnaireTemplatesKeys.lists)
      },
    },
  )
}
