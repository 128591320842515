import {
  changeVariablePosition,
  editVariable,
  JSONToValue,
  removeVariable,
  VariableInsertionPosition,
} from '@follow/farte'
import { VariableKind, VariableKindPrefix, EditorVariableDisplayConfig } from '@follow/cdk'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce, useToggle } from 'react-use'
import { v4 as uuid } from 'uuid'
import { FullLoader } from '../../../shared'
import { defaultQuestionLabels } from '../../../../constants'
import AddContentToDocumentEditor from '../../../../containers/AddContentToDocumentEditor'
import DocumentEditor from '../../../../containers/DocumentEditor'
import { useHotKeyEffect, HotKeys } from '../../../../hooks/utils'
import { invokeAfter } from '../../../../misc/aspect.utilities'
import { FwTrackingEvent } from '../../../../misc/Tracking'
import { LightQuestionnaire, Question, QuestionType } from '../../../../model/Questionnaire'
import { SelectedContentType } from '../../../../store/ui/medicalEvents/medicalEventContent'
import { QuestionnaireInstance } from '../QuestionnaireInstance'
import { DocumentContactsForm } from './DocumentContactsForm'
import { PatientRecipient } from './DocumentContactsForm/DocumentContactsForm.model'
import { DocumentInstanceContentProps } from './DocumentInstanceContent.model'
import styles from './DocumentInstanceContent.module.scss'
import { DocumentLayoutForm } from './DocumentLayoutForm'
import { canAddContentToDocument } from '../../../../misc/documents.utilities'
import { RecipientContact } from '../../../../model/Contact'
import {
  computeManualPrescriptionVariableId,
  computePrescriptionVariableId,
} from '../../../../misc/drug.utilities'
import { Block } from 'slate'
import { BizoneMarker, DocumentCategoryKeys } from '../../../../model/DocumentCategory'
import { isDefined } from '../../../../misc/functions.utilities'
import { DocumentPosology } from '../../../document'
import { BottomPanelComponentType } from '../../../../store/ui/bottomPanel'
import { SamAlertModal } from '../../../drug'
import { DocumentInstanceBanners } from './DocumentInstanceBanners'
import { DocumentTemplateEditorVariantType } from '../../../../model/DocumentTemplate'
import { GridLayoutDocument } from '../../../documentLayout'
import { SearchQuestionnaireBottomPanel } from '../../../shared/bottomPanel/SearchQuestionnaireBottomPanel'
import { SearchQuestionBottomPanel } from '../../../shared/bottomPanel/SearchQuestionBottomPanel'
import { EditQuestionInstanceBottomPanel } from '../../../shared/bottomPanel/EditQuestionInstanceBottomPanel'
import { Acte, SelectedQuoteLinePayload } from '../../../../model/Acte'
import { QuoteLineForm } from '../../../ccam'
import { SearchActeBottomPanel } from '../../../shared/bottomPanel/SearchActeBottomPanel'
import { useDownloadDocuments, usePrintDocuments } from '../../../../hooks/queries/document'

export const DocumentInstanceContent: FunctionComponent<DocumentInstanceContentProps> = ({
  isDocumentHistoryOpen: autoSaveEnabled,
  documentInstance,
  medicalEvent,
  questionnaire,
  prescription,
  quoteLine,
  documentAlerts,
  displayableSamAlerts,
  vidalInfos,
  severityLabels,
  variablesData,
  addExistingQuestionToInUseDocumentInstance,
  addQuestionnaireToInUseDocumentInstance,
  addQuestionToInUseDocumentInstance,
  createDocumentHistoryRevision,
  onSelectionChange,
  documentQuestionList,
  editQuestion,
  searchBaseVariable,
  searchDrug,
  addManualPrescription,
  addQuoteLine,
  searchDocumentHistory,
  selectedContent,
  setSelectedContent,
  updateDocumentInstanceValue,
  updateDocumentInstanceThenRefetchVariableData,
  copyHtmlToClipboard,
  setInUseMedicalEvent,
  setBottomPanelOptions,
  getDocumentAlerts,
  lockDocument,
  disableCurrentSamAlerts,
  acknowledgeCurrentSamAlerts,
  getVariableData,
  setVariableData,
}) => {
  const [editQuestionId, setEditQuestionId] = useState<number | null>(null)
  const [localEditorValue, setLocalEditorValue] = useState(JSONToValue(documentInstance.template))
  const [displaySamAlertsModal, setDisplaySamAlertsModal] = useState(false)
  const [isOpenSearchQuestionnaireBottomPanel, toggleOpenSearchQuestionnaireBottomPanel] =
    useToggle(false)
  const [isOpenSearchQuestionBottomPanel, toggleOpenSearchQuestionBottomPanel] = useToggle(false)
  const [isOpenSearchActeBottomPanel, toggleSearchActeBottomPanel] = useToggle(false)
  const [onSelectQuestionnaireCallback, setOnSelectQuestionnaireCallback] = useState(
    () => (_item: object) => {},
  )
  const [onSelectQuestionCallback, setOnSelectQuestionCallback] = useState(
    () => (_item: object) => {},
  )
  const [onSelectActeCallback, setOnSelectActeCallback] = useState(() => (_item: object) => {})

  const forceVariableDataFetchAfterNextUpdate = useRef(false)

  const setSelectedInterval = useCallback(
    (intervalId: string | undefined) => {
      if (prescription) {
        setSelectedContent({
          type: SelectedContentType.PRESCRIPTION,
          prescriptionId: prescription.uuid,
          intervalId,
        })
      }
    },
    [prescription, setSelectedContent],
  )
  const print = usePrintDocuments()
  const download = useDownloadDocuments()

  useHotKeyEffect(
    HotKeys.ModP,
    (event) => {
      event.preventDefault()
      print({
        documents: [
          {
            id: documentInstance.id,
            category: documentInstance.category.id,
            type: documentInstance.type,
          },
        ],
      })
    },
    [documentInstance.id],
  )

  useHotKeyEffect(
    HotKeys.ModShiftS,
    (event) => {
      event.preventDefault()
      download({
        documents: [
          {
            id: documentInstance.id,
            category: documentInstance.category.id,
            type: documentInstance.type,
          },
        ],
      })
    },
    [documentInstance.id],
  )

  useHotKeyEffect(
    HotKeys.ModShitV,
    (event) => {
      event.preventDefault()
      setSelectedContent({
        type: SelectedContentType.ADD_VARIABLE_PANEL,
      })
    },
    [documentInstance.id],
  )

  useEffect(() => {
    getDocumentAlerts()
    return () => {
      setVariableData(null)
    }
  }, [getDocumentAlerts, setVariableData])

  useEffect(() => {
    if (variablesData === null && selectedContent.type === SelectedContentType.EDITOR) {
      getVariableData()
    }
    if (selectedContent.type !== SelectedContentType.EDITOR) {
      setVariableData(null)
    }
  }, [selectedContent.type, variablesData, getVariableData, setVariableData])

  useEffect(() => {
    if (!autoSaveEnabled) {
      setLocalEditorValue(JSONToValue(documentInstance.template))
    }
  }, [autoSaveEnabled, documentInstance.locked, documentInstance.template])

  // Création de révision lorsque l'utilisateur quitte l'éditeur
  useEffect(() => {
    return () => {
      createDocumentHistoryRevision(documentInstance.id, 'documents')
    }
  }, [createDocumentHistoryRevision, documentInstance.id])

  // Mise à jour du prix d'un document dans l'objet medical event après une édition
  useEffect(() => {
    if (isDefined(medicalEvent)) {
      const currentDocIndex = medicalEvent.documents.findIndex(
        ({ id }) => id === documentInstance.id,
      )
      const currentDoc = medicalEvent.documents[currentDocIndex]
      if (currentDocIndex > -1 && currentDoc.price !== documentInstance.price) {
        const { documents } = medicalEvent
        documents[currentDocIndex].price = documentInstance.price

        setInUseMedicalEvent({
          ...medicalEvent,
          documents,
        })
      }
    }
  }, [medicalEvent, documentInstance.price, documentInstance.id, setInUseMedicalEvent])

  useDebounce(
    () => {
      if (
        autoSaveEnabled &&
        JSON.stringify(documentInstance.template) !== JSON.stringify(localEditorValue)
      ) {
        updateDocumentInstanceValue(
          documentInstance.id,
          localEditorValue,
          forceVariableDataFetchAfterNextUpdate.current,
        )
        if (forceVariableDataFetchAfterNextUpdate.current) {
          forceVariableDataFetchAfterNextUpdate.current = false
        }
      }
    },
    500,
    [autoSaveEnabled, localEditorValue],
  )

  // Mise à jour de la valeur locale de l'éditeur quand une variable est supprimée hors de l'éditeur (i.e. via les chips)
  useEffect(() => {
    if (autoSaveEnabled) {
      setLocalEditorValue((currentLocalEditorValue) => {
        if (
          variablesData === null &&
          JSON.stringify(documentInstance.template) !== JSON.stringify(currentLocalEditorValue)
        ) {
          return JSONToValue(documentInstance.template)
        }
        return currentLocalEditorValue
      })
    }
  }, [variablesData, autoSaveEnabled, documentInstance.template])

  useEffect(() => {
    setDisplaySamAlertsModal(displayableSamAlerts.length > 0)
  }, [displayableSamAlerts.length])

  const selectEditorTab = () => {
    setSelectedContent({
      type: SelectedContentType.EDITOR,
    })
  }

  const handleCreateQuestion = (
    type: QuestionType,
    onVariableCreated: (question: Question) => void,
  ) => {
    const onQuestionCreated = (question: Question) => {
      // Notifie l'editeur de l'insertion d'une nouvelle variable
      onVariableCreated(question)
      setEditQuestionId(question.id)
    }
    addQuestionToInUseDocumentInstance(
      documentInstance.id,
      type,
      defaultQuestionLabels[type],
      onQuestionCreated,
    )
  }

  const handleSelectVariableDisplayConfig = (
    farteKey: string,
    variableId: string,
    displayConfig?: string,
  ) => {
    // /!\ Comportement très similaire à DocumentTemplateEditorContent.component.tsx
    // Edition de la variable
    const variableContext = displayConfig
      ? {
          displayConfig,
        }
      : undefined
    const newLocalEditorValue = editVariable(localEditorValue, farteKey, {
      variableId,
      variableContext,
    })
    // Modification de l'état local de l'editeur
    setLocalEditorValue(newLocalEditorValue)
    forceVariableDataFetchAfterNextUpdate.current = true
  }

  const handleVariablePosition = useCallback(
    (
      variableId: string,
      position?: VariableInsertionPosition,
      relativeBlock?: Block,
      removeBlock?: boolean,
    ) => {
      const newLocalEditorValue = changeVariablePosition(
        localEditorValue,
        variableId,
        position,
        relativeBlock,
        removeBlock,
      )
      // Modification de l'état local de l'editeur
      setLocalEditorValue(newLocalEditorValue)
    },
    [localEditorValue],
  )

  const handleRemoveVariable = useCallback(
    (variableId: string) => {
      const newLocalValue = removeVariable(localEditorValue, variableId)
      // Modification de l'état local de l'editeur
      setLocalEditorValue(newLocalValue)
    },
    [localEditorValue],
  )

  const handleInsertVariable = (
    type: VariableKind,
    onVariableCreated: (
      id: string,
      variableContext?: Record<string, string>,
      position?: VariableInsertionPosition,
      relativeBlock?: Block,
    ) => void,
  ) => {
    switch (type) {
      case VariableKind.Base:
        return searchBaseVariable(invokeAfter(onVariableCreated, selectEditorTab), 'document')
      case VariableKind.Drug:
      case VariableKind.MultiDrugs:
        return searchDrug(
          invokeAfter((selected) => {
            if (selected.drugs.length > 0) {
              if (
                documentInstance.category.id ===
                DocumentCategoryKeys.BizonePrescriptionForMedication
              ) {
                onVariableCreated(
                  computePrescriptionVariableId(selected.prescriptionVariableUuid),
                  undefined,
                  VariableInsertionPosition.START,
                  localEditorValue.document
                    .getBlocks()
                    .find((block) =>
                      selected.isAld
                        ? block?.data.get('marker') === BizoneMarker.ALD
                        : block?.data.get('marker') === BizoneMarker.NON_ALD,
                    ),
                )
              } else {
                onVariableCreated(computePrescriptionVariableId(selected.prescriptionVariableUuid))
              }
            }
          }, selectEditorTab),
          { multiSelect: type === VariableKind.MultiDrugs },
        )
      case VariableKind.ManualPrescription:
        return addManualPrescription(
          invokeAfter((manualPrescription) => {
            if (
              documentInstance.category.id === DocumentCategoryKeys.BizonePrescriptionForMedication
            ) {
              onVariableCreated(
                computeManualPrescriptionVariableId(manualPrescription.variableUuid),
                undefined,
                VariableInsertionPosition.START,
                localEditorValue.document
                  .getBlocks()
                  .find((block) => block?.data.get('marker') === BizoneMarker.NON_ALD),
              )
            } else {
              onVariableCreated(
                computeManualPrescriptionVariableId(manualPrescription.variableUuid),
              )
            }
          }, selectEditorTab),
        )
      case VariableKind.Question:
        toggleOpenSearchQuestionBottomPanel()
        setOnSelectQuestionCallback(() => selectQuestionCallback(onVariableCreated))
        break
      case VariableKind.Questionnaire:
        toggleOpenSearchQuestionnaireBottomPanel()
        setOnSelectQuestionnaireCallback(() => selectQuestionnaireCallback(onVariableCreated))
        break
      case VariableKind.Acte:
        toggleSearchActeBottomPanel()
        setOnSelectActeCallback(() => selectActeCallback(onVariableCreated))
        break
    }
  }

  const selectQuestionCallback =
    (
      onVariableCreated: (
        id: string,
        variableContext?: Record<string, string>,
        position?: VariableInsertionPosition,
        relativeBlock?: Block,
      ) => void,
    ) =>
    (question: Question) => {
      addExistingQuestionToInUseDocumentInstance(
        documentInstance.id,
        question,
        invokeAfter(
          ({ id }) => onVariableCreated(`${VariableKindPrefix.QUESTION}_${id}`),
          selectEditorTab,
        ),
      )
      toggleOpenSearchQuestionBottomPanel()
    }

  const selectQuestionnaireCallback =
    (
      onVariableCreated: (
        id: string,
        variableContext?: Record<string, string>,
        position?: VariableInsertionPosition,
        relativeBlock?: Block,
      ) => void,
    ) =>
    (questionnaire: LightQuestionnaire) => {
      addQuestionnaireToInUseDocumentInstance(
        documentInstance.id,
        questionnaire,
        invokeAfter(({ id, scoreConfig }) => {
          const variableContext = {
            displayConfig: scoreConfig
              ? EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE
              : EditorVariableDisplayConfig.FULL,
          }
          const { selection, document: farteDocument } = localEditorValue
          // L'insertion de questionnaire se fasse à la position courante du curseur
          // Sauf si le curseur est au début et dans ce cas c'est inséré à la fin.
          const position =
            selection.isCollapsed &&
            (selection.start.isAtStartOfNode(farteDocument) ||
              selection.end.isAtEndOfNode(farteDocument))
              ? VariableInsertionPosition.END
              : undefined
          return onVariableCreated(
            `${VariableKindPrefix.QUESTIONNAIRE}_${id}`,
            variableContext,
            position,
          )
        }, selectEditorTab),
      )
      toggleOpenSearchQuestionnaireBottomPanel()
    }

  const selectActeCallback =
    (
      onVariableCreated: (
        id: string,
        variableContext?: Record<string, string>,
        position?: VariableInsertionPosition,
        relativeBlock?: Block,
      ) => void,
    ) =>
    (acte: Acte) => {
      const variableUuid = uuid()
      const quoteLinePayload: SelectedQuoteLinePayload = { codeActe: acte.codeActe, variableUuid }
      addQuoteLine(
        quoteLinePayload,
        invokeAfter(
          () =>
            onVariableCreated(`${VariableKindPrefix.QUOTE_LINE}_${quoteLinePayload.variableUuid}`),
          selectEditorTab,
        ),
      )
      toggleSearchActeBottomPanel()
    }

  const handleEditQuestion = (question) => {
    setEditQuestionId(question.id)
  }

  const handleQuestionEdited = (question: Question) => {
    // Laisser cette action Redux pour rafraichir les ressources parentes
    editQuestion(
      question.id,
      {
        documentInstanceId: documentInstance.id,
      },
      () => {
        // Affichage de l'editeur
        selectEditorTab()
      },
    )
  }

  function handleChangeDocumentInstanceContacts(contacts: ReadonlyArray<RecipientContact>) {
    updateDocumentInstanceThenRefetchVariableData(documentInstance.id, {
      contacts,
      type: documentInstance.type,
    })
  }

  function handleChangePatientRecipient(updates: PatientRecipient) {
    updateDocumentInstanceThenRefetchVariableData(documentInstance.id, {
      ...updates,
      type: documentInstance.type,
    })
  }

  const openAlertsPanel = useCallback(() => {
    setBottomPanelOptions({
      open: true,
      componentType: BottomPanelComponentType.DocumentAlerts,
      title: 'Aide à la prescription : Signaux et Alertes',
    })
  }, [setBottomPanelOptions])

  const handleCloseSamModal = useCallback(
    (disabledAlerts: boolean) => {
      setDisplaySamAlertsModal(false)
      acknowledgeCurrentSamAlerts()
      if (disabledAlerts) {
        disableCurrentSamAlerts()
      }
    },
    [acknowledgeCurrentSamAlerts, disableCurrentSamAlerts],
  )

  const handleUnlockDocument = () => {
    lockDocument(documentInstance.id, false)
  }

  const disabled = !medicalEvent?.isEditable || documentInstance.locked

  return (
    <div className="flex flex-col w-full">
      <DocumentInstanceBanners
        document={documentInstance}
        isEventLocked={!!medicalEvent?.locked}
        selectedContent={selectedContent.type}
        alerts={documentAlerts}
        alertSeverityLabels={severityLabels}
        vidalInfos={vidalInfos}
        onUnlockDocument={handleUnlockDocument}
        onOpenAlertsPanel={openAlertsPanel}
      />
      <div className={styles.container}>
        {selectedContent.type === SelectedContentType.EDITOR &&
          (variablesData === null ? (
            <FullLoader />
          ) : (
            <GridLayoutDocument rowsTemplate="repeat(3, auto)" width="100%" gap="medium">
              {documentInstance?.category?.id !==
                DocumentCategoryKeys.BizonePrescriptionForMedication && (
                <DocumentLayoutForm documentInstance={documentInstance} isEditable={!disabled} />
              )}
              {documentInstance.contentType.BASE_VARIABLE_ADD && (
                <DocumentContactsForm
                  contacts={documentInstance.contacts}
                  isEditable={!disabled}
                  onChangeContacts={handleChangeDocumentInstanceContacts}
                  onChangePatientRecipient={handleChangePatientRecipient}
                  patientAsCopyRecipient={documentInstance.patientAsCopyRecipient}
                  patientAsMainRecipient={documentInstance.patientAsMainRecipient}
                />
              )}
              <DocumentEditor
                enableVariableFixedValues={false} // Désactivé le temps que la migration des fixed values soit faite, voir https://fwhealth.atlassian.net/browse/DEV-3466
                editableDocument={documentInstance}
                onEditQuestion={handleEditQuestion}
                onSelectVariableDisplayConfig={handleSelectVariableDisplayConfig}
                onChangeVariablePosition={handleVariablePosition}
                onRemoveVariable={handleRemoveVariable}
                onClickAddVariable={
                  canAddContentToDocument(documentInstance.contentType)
                    ? () =>
                        onSelectionChange({
                          type: DocumentTemplateEditorVariantType.ADD_VARIABLE_PANEL,
                        })
                    : undefined
                }
                onClickHistory={() =>
                  searchDocumentHistory(documentInstance.id, documentInstance.hash, 'documents')
                }
                value={localEditorValue}
                variablesData={variablesData ?? {}}
                setValue={setLocalEditorValue}
                isReadonly={disabled || !documentInstance.contentType.TEXT_INPUT}
                copyHtmlToClipboard={copyHtmlToClipboard}
              />
            </GridLayoutDocument>
          ))}
        {selectedContent.type === SelectedContentType.QUESTIONNAIRE && questionnaire && (
          <QuestionnaireInstance
            documentInstanceId={documentInstance.id}
            questions={questionnaire.questions}
            isReadonly={!questionnaire?.isEditable}
            disabled={disabled}
          />
        )}
        {selectedContent.type === SelectedContentType.VARIABLES && documentQuestionList && (
          <QuestionnaireInstance
            documentInstanceId={documentInstance.id}
            questions={documentQuestionList.questions}
            disabled={disabled}
          />
        )}
        {selectedContent.type === SelectedContentType.PRESCRIPTION && prescription && (
          <DocumentPosology
            prescription={prescription}
            activeIntervalId={selectedContent.intervalId}
            setActiveIntervalId={setSelectedInterval}
            disabled={disabled}
          />
        )}
        {selectedContent.type === SelectedContentType.ADD_VARIABLE_PANEL && (
          <AddContentToDocumentEditor
            value={localEditorValue}
            contentType={documentInstance.contentType}
            setValue={setLocalEditorValue}
            onCreateQuestion={handleCreateQuestion}
            onInsertVariable={handleInsertVariable}
            tracker={FwTrackingEvent.MEDICAL_EVENT_VARIABLE_ADD}
          />
        )}
        {selectedContent.type === SelectedContentType.ACTE && quoteLine && (
          <QuoteLineForm quoteLine={quoteLine} documentInstanceId={documentInstance.id} />
        )}
      </div>
      <SamAlertModal
        display={displaySamAlertsModal}
        alerts={displayableSamAlerts}
        onClose={handleCloseSamModal}
      />
      <SearchQuestionnaireBottomPanel
        display={isOpenSearchQuestionnaireBottomPanel}
        onRequestClose={toggleOpenSearchQuestionnaireBottomPanel}
        onSelect={onSelectQuestionnaireCallback}
      />
      <SearchQuestionBottomPanel
        display={isOpenSearchQuestionBottomPanel}
        onRequestClose={toggleOpenSearchQuestionBottomPanel}
        onSelect={onSelectQuestionCallback}
      />
      <SearchActeBottomPanel
        display={isOpenSearchActeBottomPanel}
        onRequestClose={toggleSearchActeBottomPanel}
        onSelect={onSelectActeCallback}
      />

      <EditQuestionInstanceBottomPanel
        display={isDefined(editQuestionId)}
        onRequestClose={() => setEditQuestionId(null)}
        itemId={editQuestionId}
        onItemEdited={handleQuestionEdited}
      />
    </div>
  )
}
