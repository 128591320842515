import styled from '../../../../styles/legacy/styled/styled'

export const CardMenu = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
`
