import React, { FunctionComponent, useMemo } from 'react'
import { Radio, RadioGroup, Loader } from '../../../components/shared'
import { EditorVariableDisplayConfig, retrieveVariableId } from '@follow/cdk'
import { CommonVariableActions } from '../CommonVariableActions'
import { QuestionnaireVariableConfigurationProps } from './QuestionnaireVariableConfiguration.model'
import styles from './QuestionnaireVariableConfiguration.module.scss'
import { Questionnaire } from '../../../model/Questionnaire'

export const QuestionnaireVariableConfiguration: FunctionComponent<
  QuestionnaireVariableConfigurationProps
> = ({
  variableId,
  displayConfig,
  renderTitle,
  questionnaires,
  onSelectQuestionnaireDisplayConfig,
  ...restProps
}) => {
  const questionnaire = useMemo<Questionnaire | undefined>(() => {
    const targetId = retrieveVariableId(variableId)
    if (!targetId) return undefined

    return questionnaires.find(({ id }) => id === targetId)
  }, [questionnaires, variableId])

  if (!questionnaire) {
    return (
      <div className={styles.loadingContainer}>
        <Loader isLoading={true} height={70} width={70} />
      </div>
    )
  }

  const handleDisplayConfigChange = (_event, value: string) => {
    switch (value) {
      case `${EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE)
        break
      case `${EditorVariableDisplayConfig.SCORE_VALUE}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.SCORE_VALUE)
        break
      case `${EditorVariableDisplayConfig.HIDDEN}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.HIDDEN)
        break
      case `${EditorVariableDisplayConfig.FULL}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.FULL)
        break
      default:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.HIDDEN)
        break
    }
  }
  return (
    <CommonVariableActions {...restProps}>
      {renderTitle}
      <div>
        <RadioGroup
          label="Mode d'affichage"
          name="displayMode"
          onChange={handleDisplayConfigChange}
          value={displayConfig || EditorVariableDisplayConfig.FULL}
        >
          <Radio label="Résultat complet" value={EditorVariableDisplayConfig.FULL} />
          {questionnaire.scoreConfig && (
            <>
              <Radio
                label="Titre et score"
                value={EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE}
              />
              <Radio label="Score seul" value={EditorVariableDisplayConfig.SCORE_VALUE} />
            </>
          )}{' '}
          <Radio label="Masqué" value={EditorVariableDisplayConfig.HIDDEN} />
        </RadioGroup>
      </div>
    </CommonVariableActions>
  )
}
