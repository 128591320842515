import styled from '../../../../styles/legacy/styled/styled'
import { StyledTimeLineItemProps } from './TimelineItem.model'

export const StyledTimeLineItem = styled.div<StyledTimeLineItemProps>`
  position: relative;
  margin-left: 16px;
  margin-bottom: ${({ headerLess = false }) => (headerLess ? '0' : '36px')};
  @media ${({ theme }) => theme.mediaQueries.smallScreen} {
    margin: 0px 5px 15px 5px;
    ${({ hiddenOnMobile = false }) => (hiddenOnMobile ? 'display: none' : '')};
  }
`
