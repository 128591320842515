import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnaireTemplatesKeys } from '../questionnaireTemplates/questionnaireTemplates.keys'
import { questionTemplatesKeys } from './questionTemplate.keys'
import { QuestionTemplate } from '../../../model/Questionnaire'
import { updateQuestionTemplate } from '../../../data/questionTemplates'
import { QuestionTemplateMutationPayload } from '../../../data/questionTemplates/mapper/questionTemplates.model'

interface QuestionTemplateMutateVariable {
  id: number
  payload: QuestionTemplateMutationPayload
}

export const useUpdateQuestionTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<QuestionTemplate, Error, QuestionTemplateMutateVariable>(
    async ({ id, payload }) => {
      const { data, ok } = await updateQuestionTemplate(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la template de question a échoué')
      }
      return data
    },
    {
      onSuccess: (question) => {
        queryClient.invalidateQueries(questionTemplatesKeys.lists)
        queryClient.invalidateQueries(questionTemplatesKeys.detail(question.id))
        question.questionnaires.map(({ id }) =>
          queryClient.invalidateQueries(questionnaireTemplatesKeys.detail(id)),
        )
      },
    },
  )
}
