import React, { FunctionComponent, useMemo } from 'react'
import { SelectInput } from '../../../../shared'
import { Profession } from '../../../../../model/Professions'
import { ProfessionSelectProps } from './ProfessionSelect.model'
import { useField } from 'formik'
import { SelectOption } from '../../../../../model/SelectOption'
import { useGetProfessions } from '../../../../../hooks/queries/professions'

export const ProfessionSelect: FunctionComponent<ProfessionSelectProps> = ({
  theme = 'light',
  disabled = false,
}) => {
  const { query: professionQuery } = useGetProfessions()

  const [formValue, { error }, helpers] = useField<Profession | null>('profession')

  const selectOptions: Array<SelectOption<Profession>> | null = useMemo(
    () =>
      professionQuery.data?.map((profession) => ({
        key: profession.oid,
        value: profession,
        label: profession.description,
      })) ?? null,
    [professionQuery.data],
  )

  const selectValue = useMemo(
    () => selectOptions?.find((option) => option.value.id === formValue?.value?.id),
    [formValue.value, selectOptions],
  )

  return (
    <SelectInput<Profession>
      title="Profession"
      disabled={disabled || !selectOptions}
      options={selectOptions ?? []}
      colorPreset={theme}
      value={selectValue}
      onSelect={({ value }) => {
        helpers.setValue(value)
      }}
      error={error}
    />
  )
}
