import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { DocumentAlertsPanelProps } from './DocumentAlertsPanel.model'
import createSearchPanel from '../../../containers/SearchPanel/SearchPanel.container'
import {
  DocumentAlertCategories,
  DocumentAlertsCategoryNames,
  DocumentAlertSeverity,
  DocumentAlert,
  DocumentAlertsFilters,
  NON_SAM_FILTER,
  SeverityShortNames,
} from '../../../model/DocumentAlerts'
import { DocumentAlertItem } from './DocumentAlertListItem'
import { DropdownItem, MultiSelectSearch, RoundedButton } from '../../shared'
import { mapSeverityToTagColor } from './DocumentAlertListItem/DocumentAlertItem.model'
import { openVidalDetailedReport } from '../../../misc/drug.utilities'
import { MultiSelectOption } from '../../../model/SelectOption'

const SearchPanel = createSearchPanel<DocumentAlert>()

export const DocumentAlertsPanel: FC<DocumentAlertsPanelProps> = ({
  actions,
  availableFilters,
  documentAlerts,
  filtersPreferences,
  document,
  getAlerts,
}) => {
  const [openId, setOpenId] = useState<number>()

  const severityOptions = useMemo(
    () =>
      availableFilters?.severityLevels.map((severityItem) => ({
        value: severityItem,
        label: SeverityShortNames[severityItem] ?? '',
        color: mapSeverityToTagColor[severityItem],
      })) ?? [],
    [availableFilters?.severityLevels],
  )

  const categoryOptions = useMemo(
    () =>
      availableFilters?.categories.map((category) => ({
        value: category,
        label: DocumentAlertsCategoryNames[category] ?? category,
      })) ?? [],
    [availableFilters?.categories],
  )

  const drugOptions = useMemo(
    () => availableFilters?.drugNames.map(({ id, name }) => ({ value: id, label: name })) ?? [],
    [availableFilters?.drugNames],
  )

  const typeOptions = useMemo(() => availableFilters?.types ?? [], [availableFilters?.types])

  const samOptions = useMemo(
    () =>
      availableFilters?.samTypes.map(({ name, displayName }) => ({
        value: name,
        label: displayName,
      })) ?? [],
    [availableFilters?.samTypes],
  )

  const [severities, setSeverities] = useState<MultiSelectOption<DocumentAlertSeverity>[]>(
    severityOptions.filter(({ value }) => filtersPreferences.severityLevels.includes(value)),
  )
  const [types, setTypes] = useState<MultiSelectOption<string>[]>(
    filtersPreferences.types === null
      ? typeOptions
      : typeOptions.filter(({ value }) => filtersPreferences.types?.includes(value)),
  )
  const [selectedDrugs, setSelectedDrugs] = useState<MultiSelectOption<number>[]>(drugOptions)
  const [categories, setCategories] =
    useState<MultiSelectOption<DocumentAlertCategories>[]>(categoryOptions)
  const [samTypes, setSamTypes] = useState<MultiSelectOption<string>[]>(
    filtersPreferences.samTypes !== null
      ? samOptions.filter(
          ({ value }) => filtersPreferences.samTypes?.includes(value) || value === NON_SAM_FILTER,
        )
      : samOptions,
  )

  const applyAllFilters = useCallback(() => {
    setSeverities(severityOptions)
    setTypes(typeOptions)
    setCategories(categoryOptions)
    setSelectedDrugs(drugOptions)
    setSamTypes(samOptions)
  }, [categoryOptions, drugOptions, samOptions, severityOptions, typeOptions])

  const emptyFilters = useCallback(() => {
    setSeverities([])
    setTypes([])
    setCategories([])
    setSelectedDrugs([])
    setSamTypes([])
  }, [])

  useEffect(() => {
    setOpenId(undefined)
    const filters: DocumentAlertsFilters = {
      severityLevels: severities.map(({ value }) => value),
      types,
      categories: categories.map(({ value }) => value),
      drugNames: selectedDrugs.map(({ value, label }) => ({ id: value, name: label })),
      samTypes: samTypes.map(({ value, label }) => ({ name: value, displayName: label })),
    }
    getAlerts(filters)
  }, [categories, getAlerts, samTypes, selectedDrugs, severities, types])

  useEffect(() => {
    return () => {
      getAlerts()
    }
  }, [getAlerts])

  return (
    <SearchPanel
      items={documentAlerts}
      loading={false}
      pageCount={1}
      itemCount={documentAlerts.length}
      getItems={() => {}}
      filterCapabilities={[]}
      actions={actions}
      onPrimaryAction={({ id }) => setOpenId(openId === id ? undefined : id)}
      renderActions={() => (
        <RoundedButton
          label="+ d'informations"
          onClick={() => {
            if (document) {
              openVidalDetailedReport(document.id)
            }
          }}
        />
      )}
      hideActionItems={true}
      renderItem={(alert) => <DocumentAlertItem alert={alert} open={alert.id === openId} />}
      renderCustomFilters={() => (
        <>
          <div className="flex justify-between">
            <span className="font-semibold text-shades-white">Filtres</span>
            <div className="space-x-2 text-shades-5 text-xs">
              <span
                className="border-shades-5 hover:border-b cursor-pointer"
                onClick={applyAllFilters}
              >
                Tous
              </span>
              <span>/</span>
              <span
                className="border-shades-5 hover:border-b cursor-pointer"
                onClick={emptyFilters}
              >
                Aucun
              </span>
            </div>
          </div>
          <MultiSelectSearch
            label="Sévérité"
            placeholder="Sévérité"
            colorPreset="dark"
            value={severities}
            options={severityOptions}
            onSelect={setSeverities}
          />
          <MultiSelectSearch
            label="Médicament"
            placeholder="Médicament"
            colorPreset="dark"
            value={selectedDrugs}
            options={drugOptions}
            onSelect={setSelectedDrugs}
            renderOption={(option, currentValue, isHovered) => (
              <DropdownItem
                selected={isHovered || !!currentValue.find(({ value }) => value === option.value)}
              >
                <div className="max-w-full whitespace-normal">{option.label}</div>
              </DropdownItem>
            )}
          />
          <MultiSelectSearch
            label="Type"
            placeholder="Type"
            colorPreset="dark"
            value={types}
            options={typeOptions}
            onSelect={setTypes}
          />
          <MultiSelectSearch
            label="Catégorie"
            placeholder="Catégorie"
            colorPreset="dark"
            value={categories}
            options={categoryOptions}
            onSelect={setCategories}
          />
          <MultiSelectSearch
            label="Systèmes d'aide à la décision indexée par médicaments (SAM)"
            placeholder="SAM"
            colorPreset="dark"
            value={samTypes}
            options={samOptions}
            onSelect={setSamTypes}
          />
        </>
      )}
    />
  )
}
