import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QuestionnaireTemplate } from '../../../model/Questionnaire'
import { UserFriendlyError } from '../../../model/Error'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { moveQuestionTemplate } from '../../../data/questionnaireTemplates'

interface MoveQuestionTemplateParams {
  questionnaireTemplateId: number
  questionTemplateIds: number[]
}

export const useMoveQuestionTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<QuestionnaireTemplate, Error, MoveQuestionTemplateParams>(
    async ({ questionnaireTemplateId, questionTemplateIds }) => {
      const { data, ok } = await moveQuestionTemplate(questionnaireTemplateId, questionTemplateIds)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "La mise à jour de l'ordre des templates de question a échoué",
        )
      }
      return data
    },
    {
      onSuccess: (questionnaireTemplate) => {
        queryClient.setQueryData(
          questionnaireTemplatesKeys.detail(questionnaireTemplate.id),
          questionnaireTemplate,
        )
      },
    },
  )
}
