import { atomWithReset } from 'jotai/utils'
import { Patient } from '../../model/Patient'

interface BeneficiaryDifferences {
  differences: Partial<Patient>
  onSelectDifferenceOptions: (applyDifferences: boolean) => void
}

export const beneficiaryDifferencesAtom = atomWithReset<BeneficiaryDifferences | null>(null)

interface BenefiariesState {
  selected: Partial<Patient> | null
  beneficiaries: Array<Partial<Patient>> | null
}

export const beneficiariesAtom = atomWithReset<BenefiariesState>({
  beneficiaries: null,
  selected: null,
})
