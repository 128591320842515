import React, { FunctionComponent, useContext, useEffect } from 'react'
import {
  isAudioExtension,
  isImageExtension,
  isPdfExtension,
  isUnsupportedExtension,
  isXMLExtension,
} from '../../../misc/files.utilities'
import { ImageViewer } from './ImageViewer'
import { PdfViewerLegacy } from './PdfViewerLegacy'
import { BackgroundColors, FileViewerProps } from './FileViewer.model'
import styles from './FileViewer.module.scss'
import { IconButton, RoundedButton, FullLoader, AudioPlayer } from '../../shared'
import classNames from 'classnames'
import { XmlViewer } from './XmlViewer'
import { scrollToTopSmoothly } from '../../../misc/window.utilities'
import { isAdmin, isDoctor } from '../../../misc/user.utilities'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { PdfViewer } from './PdfViewer'

export const FileViewer: FunctionComponent<FileViewerProps> = ({
  file,
  fieldValues,
  isInteractive,
  contentType,
  readOnly,
  enableScrollButton = false,
  scale,
  autoScale,
  backgroundColor = BackgroundColors.blue,
  onFocusInput,
  onChange,
  onInsertVariable,
  onClear,
  onBlur,
  onExitFullScreen,
  onEnterFullScreen,
}) => {
  const { currentUser } = useContext(ConnectedUserContext)
  const handleAddVariable =
    isInteractive && !!contentType?.BASE_VARIABLE_ADD && !readOnly ? onInsertVariable : undefined

  useEffect(
    () => () => {
      if (onClear) onClear()
    },
    [onClear],
  )

  const isEnabledPdfViewerV2 =
    isAdmin(currentUser) ||
    (isDoctor(currentUser) && !!currentUser?.preferences.enabledFeatures.pdfViewerV2)

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {file && file.base64 ? (
        <>
          <div
            className={classNames(
              `flex justify-center flex-grow p-4 mx-auto ${backgroundColor} rounded-xl w-full`,
              {
                'bg-transparent': isAudioExtension(file.extension),
                'w-150': isAudioExtension(file.extension),
              },
            )}
          >
            {isImageExtension(file.extension) && <ImageViewer image={file} />}
            {isPdfExtension(file.extension) && !isEnabledPdfViewerV2 && (
              <PdfViewerLegacy
                fieldValues={fieldValues}
                isInteractive={isInteractive}
                contentType={contentType}
                pdfBase64={file.base64}
                readOnly={readOnly}
                scale={scale}
                autoScale={autoScale}
                onFocusInput={onFocusInput}
                onChange={onChange}
                onBlur={onBlur}
                openVariablePanel={handleAddVariable}
              />
            )}
            {isPdfExtension(file.extension) && isEnabledPdfViewerV2 && (
              <PdfViewer
                fieldValues={fieldValues}
                fileContent={file.base64}
                fileName={file.visibleName}
                readOnly={readOnly || !isInteractive}
                onChange={onChange}
                onFocusInput={onFocusInput}
                onExitFullScreen={onExitFullScreen}
                onEnterFullScreen={onEnterFullScreen}
                onDbClick={handleAddVariable}
              />
            )}
            {isAudioExtension(file.extension) && <AudioPlayer file={file} />}
            {isXMLExtension(file.extension) && <XmlViewer base64={file.base64} />}
            {isUnsupportedExtension(file.extension) && (
              <div className={styles.unsuportedFile}>
                <div className={styles.icon}>
                  <svg
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinejoin="round"
                    strokeMiterlimit="1.414"
                  >
                    <path d="M3.411 35.732V12.804a3.028 3.028 0 0 1 3.026-3.026h34.65a3.03 3.03 0 0 1 3.028 3.026v22.928a3.03 3.03 0 0 1-3.028 3.027H6.437a3.028 3.028 0 0 1-3.026-3.027zm20.351 1.111v-25.15H6.437c-.613 0-1.111.498-1.111 1.111v22.928c0 .613.498 1.111 1.111 1.111h17.325zm16.165-12.28a5.906 5.906 0 0 0-5.901-5.901 5.901 5.901 0 1 0 5.901 5.901zm-10.737 0a4.841 4.841 0 0 1 4.836-4.837 4.843 4.843 0 0 1 4.837 4.837 4.842 4.842 0 0 1-4.837 4.837 4.84 4.84 0 0 1-4.836-4.837zm8.147 0a3.312 3.312 0 1 0-6.624.002 3.312 3.312 0 0 0 6.624-.002zm3.509-10.112c0-.966-.787-1.754-1.753-1.754a1.753 1.753 0 1 0 1.753 1.754zm-2.443 0c0-.377.31-.689.69-.689.377 0 .689.312.689.689a.693.693 0 0 1-.689.689.692.692 0 0 1-.69-.689z" />
                    <path d="M6.773 13.617a.92.92 0 1 1 .92.917.92.92 0 0 1-.92-.917zm2.6 0a.92.92 0 0 1 .917-.92c.507 0 .92.413.92.92a.92.92 0 0 1-1.837 0zm2.599 0a.92.92 0 0 1 .917-.92.92.92 0 0 1 .919.92.92.92 0 0 1-.919.917.92.92 0 0 1-.917-.917zM7.756 32.841c0-.292.238-.532.532-.532h3.292c.292 0 .532.24.532.532 0 .293-.24.531-.532.531H8.288a.532.532 0 0 1-.532-.531zm-.014-1.928c0-.292.238-.532.531-.532h4.669c.292 0 .532.24.532.532 0 .293-.24.531-.532.531H8.273a.531.531 0 0 1-.531-.531zM15.94 33.332V18.669h7.878v1.063h-6.814v12.536h6.814v1.064H15.94zM7.756 27.181c0-.292.238-.533.532-.533h3.292a.533.533 0 0 1 0 1.064H8.288a.532.532 0 0 1-.532-.531zm-.014-1.929c0-.291.238-.531.531-.531h4.669c.292 0 .532.24.532.531 0 .294-.24.532-.532.532H8.273a.532.532 0 0 1-.531-.532zM7.758 21.188c0-.291.239-.532.532-.532h3.292a.533.533 0 0 1 0 1.064H8.29a.532.532 0 0 1-.532-.532zm-.014-1.928c0-.291.238-.532.531-.532h4.669a.533.533 0 0 1 0 1.064H8.275a.532.532 0 0 1-.531-.532z" />
                  </svg>
                </div>
                <div className={styles.labels}>
                  <div>
                    <div className={styles.title}>Prévisualisation non supportée</div>
                    <div>Veuillez télécharger le fichier pour l'ouvrir</div>
                  </div>
                </div>
              </div>
            )}
            {(enableScrollButton || handleAddVariable) && (
              <div className={styles.floating}>
                {handleAddVariable && (
                  <RoundedButton
                    icon="add"
                    theme="primary"
                    label="Variable"
                    onClick={onInsertVariable}
                  />
                )}
                {enableScrollButton && !isEnabledPdfViewerV2 && (
                  <IconButton
                    icon="arrow"
                    size="macro"
                    theme="primary"
                    title="Retour en haut"
                    onClick={scrollToTopSmoothly}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <FullLoader />
      )}
    </div>
  )
}
