import { UnmountTransitionProps } from '../../../../misc/withUnmountTransition.hoc'
import { TreatmentsStatus } from '../../../../model/Treatment'
import { ComponentUsingBottomPanelProps } from '../BottomPanel/BottomPanel.model'

export const treatmentsStatusOptions = [
  { value: TreatmentsStatus.ALL, label: 'Tous' },
  { value: TreatmentsStatus.CURRENT, label: 'En cours' },
  { value: TreatmentsStatus.PAST, label: 'Terminés' },
]

export interface TreatmentsHistoryBottomPanelProps
  extends ComponentUsingBottomPanelProps,
    UnmountTransitionProps {
  patientId: number
}
