import React, { useState, FunctionComponent, useRef, MouseEvent, ReactNode } from 'react'
import styled from '@emotion/styled'

import { blueColor } from '../../../core/TextEditor.style'
import useOnClickOutsideElement from '../../../hooks/useClickOutsideElement.hook'
import { css } from '@emotion/react'

type DropDownSize = 'small' | 'large'

interface DropdownProps {
  children?: ReactNode
  renderTitle?: () => React.ReactNode
  withArrow?: boolean
  scrollable?: boolean
  /**
   * Les menus vont être affichés en dessous/à gauche. Par defaut ils sont affichés en dessous/à droite
   */
  pullLeft?: boolean
  size?: DropDownSize
}

const DropDownContainer = styled.div<{ opened: boolean }>`
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  transition: color 0.3s ease-in-out;
  ${({ opened }) =>
    opened &&
    css`
      position: relative;
    `}
  color: #555878;
  font-weight: bold;

  &:hover {
    color: ${blueColor};
  }
`
const Container = styled.div<{ opened: boolean; pullLeft: boolean; size: DropDownSize }>`
  min-width: ${({ size }) => (size === 'small' ? '150px' : '285px')};
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10000;
  ${({ opened }) =>
    opened &&
    css`
      opacity: 1;
    `}
  ${({ pullLeft }) =>
    pullLeft &&
    css`
      right: 0;
      left: auto;
    `}
`

const Menu = styled.div<{ scrollable: boolean }>`
  width: 100%;
  border: solid 1px white;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 18px 30px rgba(85, 88, 120, 0.2);
  padding: 8px 0;
  max-height: ${({ scrollable }) => (scrollable ? '215px' : 'auto')};
  overflow-y: auto;
`

const DropdownOpener = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 10px 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PictoSvg = styled.svg`
  height: 10px;
  width: 10px;
  margin-left: 0.2rem;
`

const DropDown: FunctionComponent<DropdownProps> = ({
  renderTitle,
  children,
  pullLeft,
  withArrow = true,
  scrollable = true,
  size = 'large',
}) => {
  const [opened, setOpened] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useOnClickOutsideElement(menuRef, () => {
    setOpened(false)
  })
  const toggleOpen = (event: MouseEvent) => {
    event.preventDefault()
    setOpened(!opened)
  }

  return (
    // Utilisation de onMouseDown pour ne pas perdre le focus dans l'éditeur
    <DropDownContainer ref={menuRef} role="button" opened={opened}>
      <DropdownOpener onMouseDown={toggleOpen}>
        {renderTitle && renderTitle()}
        {withArrow && (
          <PictoSvg viewBox="0 0 18 18">
            <g>
              <path
                d="M15.191 11.75a.995.995 0 0 1-.732.321H3.456a1 1 0 1 1 0-2h10.072V0a1 1 0 1 1 2 0V11c0 .299-.13.566-.337.75z"
                fill="#555878"
                transform="rotate(45 8.993 5.536)"
              />
            </g>
          </PictoSvg>
        )}
      </DropdownOpener>

      <Container opened={opened} pullLeft={!!pullLeft} size={size}>
        {opened && (
          <Menu role="menu" scrollable={scrollable}>
            {children}
          </Menu>
        )}
      </Container>
    </DropDownContainer>
  )
}
export default DropDown
