import React, { FunctionComponent } from 'react'
import { QuestionnaireForm } from './QuestionnaireForm'
import { QuestionnaireUpdateForm } from '../../../store/cache/questionnaires/api/Questionnaire.model'
import { CodeValue } from '../../../model/Code'
import { QuestionnaireTemplate } from '../../../model/Questionnaire'

interface QuestionnaireHeaderProps {
  questionnaire: QuestionnaireTemplate
  saving: boolean
  scoreCodes: ReadonlyArray<CodeValue>
  handleUpdateQuestionnaire: (id: number, questionnaireUpdate: QuestionnaireUpdateForm) => void
}

export const QuestionnaireHeader: FunctionComponent<QuestionnaireHeaderProps> = ({
  questionnaire,
  scoreCodes,
  handleUpdateQuestionnaire,
}) => {
  return (
    <QuestionnaireForm
      questionnaireTemplate={questionnaire}
      scoreCodes={scoreCodes}
      onUpdate={handleUpdateQuestionnaire}
    />
  )
}
