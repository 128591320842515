import getApi from '../../api/apisauceConfig'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { singleItemPageLimit } from '../../constants'
import { QuestionTemplateResource } from './mapper/questionTemplates.model'
import { deserializeQuestionTemplatesPageResponse } from './mapper/questionTemplates.mapper'

export const getQuestionTemplates = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<QuestionTemplateResource>>(
      '/question_templates',
      {
        page,
        limit,
        ...filters,
      },
      { signal },
    )
    .then(deserializeQuestionTemplatesPageResponse)
