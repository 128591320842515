import React from 'react'
import styles from './Loader.module.scss'

interface LoaderProps {
  width?: number
  height?: number
  isLoading?: boolean
  className?: string
}

export const Loader = ({ isLoading = true, className, width = 200, height = 200 }: LoaderProps) => (
  <div className={className ?? styles['loader']}>
    {isLoading && (
      <div>
        <svg
          style={{ background: 'none' }}
          width={width}
          height={height}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(25 50)">
            <circle r="8" fill="#4a90e2" transform="scale(.53881)">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.3333333333333333s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              />
            </circle>
          </g>
          <g transform="translate(50 50)">
            <circle
              r="8"
              fill="rgba(29.019607843137262%,56.47058823529414%,88.62745098039215%,0.42)"
              transform="scale(.10611)"
            >
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.16666666666666666s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              />
            </circle>
          </g>
          <g transform="translate(75 50)">
            <circle r="8" fill="#4a90e2" transform="scale(.06052)">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="0s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              />
            </circle>
          </g>
        </svg>
      </div>
    )}
  </div>
)
