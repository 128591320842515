import { FunctionComponent } from 'react'
import { QuestionInstance, AnswerInstance, QuestionConfig } from '../../../model/Questionnaire'
import { defaultAnswerTitle } from '../../../constants'
import { EditQuestionInstancePanelProps } from './EditQuestionInstancePanel.model'
import { useUpdateQuestionInstance } from '../../../hooks/queries/questionInstances'
import {
  useAddImageToAnswerInstance,
  useCreateAnswerInstance,
  useDeleteAnswerInstance,
  useUpdateAnswerInstance,
  useRemoveImageFromAnswerInstance,
} from '../../../hooks/queries/answerInstances'
import { getQuestionLevelPlaceholder } from '../../../components/questionnaire/Questionnaire.model'
import { AnswerInstanceMutationPayload } from '../../../data/answerInstances/mapper/answerInstance.model'
import { EditQuestionPanel } from '../EditQuestionPanel'

export const EditQuestionInstancePanel: FunctionComponent<EditQuestionInstancePanelProps> = ({
  questionInstance: inUseQuestion,
  isExtDsm,
  isAdmin,
  onQuestionEdited,
}) => {
  const questionnaire = inUseQuestion?.questionnaires[0]
  const { mutate: updateQuestionInstance } = useUpdateQuestionInstance()
  const { mutate: addAnswerInstanceToQuestion } = useCreateAnswerInstance()
  const { mutate: removeAnswerInstanceFromQuestionInstance } = useDeleteAnswerInstance()
  const { mutate: updateAnswerInstance } = useUpdateAnswerInstance()
  const { mutate: addImageToAnswerInstance } = useAddImageToAnswerInstance()
  const { mutate: removeImageFromAnswerInstance } = useRemoveImageFromAnswerInstance()

  const canHandleScore = isAdmin || isExtDsm
  const placeholder = getQuestionLevelPlaceholder(inUseQuestion)
  const questionLevelDefaultLabel = !!inUseQuestion.parentQuestionId
    ? 'Nom de la sous-question'
    : 'Nom de la question'

  const onMutationSuccess = (questionInstance?: QuestionInstance) => {
    // Temporaire pour les modifications autres que sur l'entité question.
    // Jusqu'a ce que packages/app/src/store/ui/bottomPanelQuestions/bottomPanelQuestions.sagas.ts:editQuestionInstanceSagaWorker ne soit plus utilisé
    onQuestionEdited && onQuestionEdited(questionInstance || inUseQuestion)
  }

  function handleDeleteAnswer(answer: AnswerInstance) {
    removeAnswerInstanceFromQuestionInstance(
      {
        id: answer.id,
        questionInstanceId: answer.questionId,
        questionnaireInstanceId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )
  }

  function handleUpdateAnswer(id: number, payload: AnswerInstanceMutationPayload) {
    updateAnswerInstance(
      {
        id,
        payload,
        questionnaireId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )
  }

  const handleAddAnswer = () =>
    addAnswerInstanceToQuestion(
      {
        payload: {
          questionId: inUseQuestion.id,
          title: defaultAnswerTitle,
          displayOrder: inUseQuestion.answers.length + 1,
        },
        questionnaireInstanceId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )

  const handleAddImageToAnswer = (answer: AnswerInstance, file: File): void =>
    addImageToAnswerInstance(
      {
        answerInstanceId: answer.id,
        file,
        questionnaireInstanceId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )
  const HandleRemoveImageFromAnswer = (answer: AnswerInstance): void =>
    removeImageFromAnswerInstance(
      {
        answerInstanceId: answer.id,
        questionnaireInstanceId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )
  const handleUpdateQuestion = (question: Partial<QuestionInstance>): void =>
    updateQuestionInstance(
      { id: inUseQuestion.id, payload: question },
      {
        onSuccess: (question) => onMutationSuccess(question),
      },
    )
  const onConfigValueChange = (config: QuestionConfig): void => {
    updateQuestionInstance(
      { id: inUseQuestion.id, payload: { config } },
      {
        onSuccess: (question) => onMutationSuccess(question),
      },
    )
  }
  return (
    <EditQuestionPanel
      question={inUseQuestion}
      questionLevelDefaultLabel={questionLevelDefaultLabel}
      placeholder={placeholder}
      isAdmin={isAdmin}
      canHandleScore={canHandleScore}
      handleAddAnswer={handleAddAnswer}
      handleAddImageToAnswer={handleAddImageToAnswer}
      handleDeleteAnswer={handleDeleteAnswer}
      HandleRemoveImageFromAnswer={HandleRemoveImageFromAnswer}
      handleUpdateAnswer={handleUpdateAnswer}
      handleUpdateQuestion={handleUpdateQuestion}
      onConfigValueChange={onConfigValueChange}
    />
  )
}
