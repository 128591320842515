import { call, put, select, takeEvery } from 'redux-saga/effects'

import { uiSagasFactory } from '../../../restux/ui/restuxUiSagas.factory'
import { identifierMatchScope } from '../../../restux/restux.utilities'
import { RESTUX_UI_IDENTIFIER } from '../../../restux/ui'

import { medicalEventDocumentInstancesActions } from './medicalEventDocumentInstances.actions'
import {
  medicalEventDocumentInstancesConfig,
  UiMedicalEventDocumentInstanceActionTypes,
} from './medicalEventDocumentInstances.model'
import { documentInstancesActions } from '../../../cache/documentInstances'
import {
  createDefaultQuestionTemplate,
  QuestionInstance,
  QuestionTemplate,
} from '../../../../model/Questionnaire'
import { inUseMedicalEventDocumentSelector } from './medicalEventDocumentInstances.selectors'
import {
  medicalEventContentUiActions,
  SelectedContent,
  SelectedContentType,
} from '../medicalEventContent'
import {
  createQuestionInstanceFromQuestionTemplate,
  createQuestionnaireInstanceFromQuestionnaireTemplate,
} from './api'
import { addError, addResponseError } from '../../../message'
import { createQuestionTemplate } from '../../../../data/questionTemplates'
import { ApiResponse } from 'apisauce'
import { isSuccessfulApiResponse } from '../../../restux/cache/restuxCacheSagas.factory'

const paginationSagas = uiSagasFactory(medicalEventDocumentInstancesConfig)

function* addExistingQuestionToInUseDocumentInstanceWorker({
  documentInstanceId,
  selectedQuestion,
  onQuestionAddedToDocumentInstance,
}: ReturnType<
  typeof medicalEventDocumentInstancesActions.addExistingQuestionToInUseDocumentInstance
>) {
  const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> = yield select(
    inUseMedicalEventDocumentSelector,
  )
  if (
    inUseDocumentInstance &&
    inUseDocumentInstance.id === documentInstanceId &&
    inUseDocumentInstance.type === 'farte'
  ) {
    const response = yield call(
      createQuestionInstanceFromQuestionTemplate,
      selectedQuestion.id,
      documentInstanceId,
    )

    if (!response.ok) {
      yield put(
        addError("Erreur lors de l'ajout de la question", `Impossible d'ajouter la question.`),
      )

      return
    }

    onQuestionAddedToDocumentInstance(response.data)
  } else {
    throw new Error(`Inconsitence entre le document cible et celui en cours d'edition`)
  }
}

function* addExistingQuestionToInUseDocumentInstanceWatcher() {
  yield takeEvery(
    UiMedicalEventDocumentInstanceActionTypes.ADD_EXISTING_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE,
    addExistingQuestionToInUseDocumentInstanceWorker,
  )
}

function* addQuestionnaireToInUseDocumentInstanceWorker({
  documentInstanceId,
  selectedQuestionnaire,
  onQuestionnaireAdded,
}: ReturnType<
  typeof medicalEventDocumentInstancesActions.addQuestionnaireToInUseDocumentInstance
>) {
  const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> = yield select(
    inUseMedicalEventDocumentSelector,
  )
  if (
    inUseDocumentInstance &&
    inUseDocumentInstance.id === documentInstanceId &&
    inUseDocumentInstance.type === 'farte'
  ) {
    const response = yield call(
      createQuestionnaireInstanceFromQuestionnaireTemplate,
      selectedQuestionnaire.id,
      documentInstanceId,
    )

    if (!response.ok) {
      yield put(
        addError(
          "Erreur lors de l'ajout du questionnaire",
          `Impossible d'ajouter le questionnaire.`,
        ),
      )

      return
    }

    const questionnaireInstance = response.data

    onQuestionnaireAdded(questionnaireInstance)
  } else {
    throw new Error(`Inconsitence entre le document cible et celui en cours d'edition`)
  }
}

function* addQuestionnaireToInUseDocumentInstanceWatcher() {
  yield takeEvery(
    UiMedicalEventDocumentInstanceActionTypes.ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_INSTANCE,
    addQuestionnaireToInUseDocumentInstanceWorker,
  )
}

function* addQuestionToInUseDocumentInstanceWorker({
  documentInstanceId,
  questionType,
  onQuestionAddedToDocumentInstance,
}: ReturnType<typeof medicalEventDocumentInstancesActions.addQuestionToInUseDocumentInstance>) {
  const response: ApiResponse<QuestionTemplate> = yield call(
    createQuestionTemplate,
    createDefaultQuestionTemplate(questionType, { documentInstanceId }),
  )
  if (isSuccessfulApiResponse(response) && response.data) {
    const instanceResponse: ApiResponse<QuestionInstance> = yield call(
      createQuestionInstanceFromQuestionTemplate,
      response.data.id,
      documentInstanceId,
    )
    if (isSuccessfulApiResponse(instanceResponse) && instanceResponse.data) {
      onQuestionAddedToDocumentInstance(instanceResponse.data)
    } else {
      yield put(addResponseError(instanceResponse))
    }
  } else {
    yield put(addResponseError(response))
  }
}

function* addQuestionToInUseDocumentInstanceWatcher() {
  yield takeEvery(
    UiMedicalEventDocumentInstanceActionTypes.ADD_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE,
    addQuestionToInUseDocumentInstanceWorker,
  )
}

function* onDocumentInstanceLoadedWorker({
  item,
  identifier,
}: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails>) {
  const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> = yield select(
    inUseMedicalEventDocumentSelector,
  )
  if (
    identifierMatchScope(identifier, RESTUX_UI_IDENTIFIER.useId) &&
    inUseDocumentInstance &&
    inUseDocumentInstance.id === item.id &&
    inUseDocumentInstance.type === 'farte' &&
    !inUseDocumentInstance.answerValues.find((answer) => answer.sharedFromAnswerValueId === null)
  ) {
    // Logique de selection par defaut
    // Autres variables, s'il y des variables question
    // Le premier questionnaires, s'il y en a
    // Le document en l'absence de variables et questionnaires.
    const currentLocation = new URLSearchParams(window.location.search)
    const contentType = currentLocation.get('contentType')
    let filter: SelectedContent = { type: SelectedContentType.EDITOR }
    switch (contentType) {
      case SelectedContentType.VARIABLES: {
        filter = { type: SelectedContentType.VARIABLES }
        break
      }
      case SelectedContentType.QUESTIONNAIRE: {
        const questionnaireId = currentLocation.get('questionnaireId')
        if (questionnaireId) {
          filter = {
            type: SelectedContentType.QUESTIONNAIRE,
            questionnaireId: parseInt(questionnaireId, 10),
          }
        }
        break
      }
      case SelectedContentType.PRESCRIPTION: {
        const prescriptionId = currentLocation.get('prescriptionId')
        const intervalId = currentLocation.get('intervalId')
        if (prescriptionId) {
          filter = {
            type: SelectedContentType.PRESCRIPTION,
            prescriptionId,
            ...(intervalId !== null && { intervalId }),
          }
        }
        break
      }
      default: {
        const { questionnaires, variables } = inUseDocumentInstance
        if (variables.length > 0) {
          filter = { type: SelectedContentType.VARIABLES }
        } else if (questionnaires.length > 0) {
          filter = {
            type: SelectedContentType.QUESTIONNAIRE,
            questionnaireId: questionnaires[0].id,
          }
        } else {
          filter = { type: SelectedContentType.EDITOR }
        }
        break
      }
    }
    yield put(medicalEventContentUiActions.selectMedicalEventContent(filter))
  }
}

function* onDocumentInstanceLoadedWatcher() {
  yield takeEvery(
    documentInstancesActions.types.STORE_SET_ITEM_DETAILS,
    onDocumentInstanceLoadedWorker,
  )
}

export const medicalEventDocumentInstancesSagas = {
  ...paginationSagas,
  addExistingQuestionToInUseDocumentInstanceWatcher,
  addQuestionnaireToInUseDocumentInstanceWatcher,
  addQuestionToInUseDocumentInstanceWatcher,
  onDocumentInstanceLoadedWatcher,
}
