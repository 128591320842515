import getApi from '../../../../../api'
import { QuestionInstance, QuestionnaireInstance } from '../../../../../model/Questionnaire'

export const createQuestionnaireInstanceFromQuestionnaireTemplate = (
  questionnaireTemplateId: number,
  documentId: number,
) =>
  getApi().post<QuestionnaireInstance>(`questionnaire_instances/create_from_template`, {
    questionnaireTemplateId,
    documentId,
  })

export const createQuestionInstanceFromQuestionTemplate = (
  questionTemplateId: number,
  documentId: number,
) =>
  getApi().post<QuestionInstance>(`question_instances/create_from_template`, {
    questionTemplateId,
    documentId,
  })
