import React, { FunctionComponent, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'

import { QuestionConfig } from '../../../../model/Questionnaire'
import { QuestionWrapper } from '../../atoms/QuestionWrapper/index'
import { QuestionTitle } from '../../atoms/QuestionTitle/QuestionTitle.component'
import { QuestionnaireMode } from '../../Questionnaire.model'
import {
  createMatchDevice,
  MatchDeviceProps,
  onlyDesktopConfig,
} from '../../../../misc/MatchDevice/index'
import { isDefined } from '../../../../misc/functions.utilities'
import { MobileInputValue } from './MobileInputValue'

import { SliderProps } from './Slider.model'
import { SliderInput } from '../../../shared'

const SliderComponent: FunctionComponent<SliderProps & MatchDeviceProps> = ({
  question,
  onDelete,
  onEdit,
  onChangeQuestionImportance,
  answerValue,
  setAnswerValue,
  disabled,
  isReadonly,
  matchDevice,
  mode,
}) => {
  const [value, setValue] = useState(answerValue)

  useEffect(() => {
    // undefined => en attente du retour de l'API
    if (value === undefined && answerValue !== undefined) {
      setValue(answerValue)
    }
  }, [answerValue, value])

  useDebounce(
    () => {
      if (isDefined(value) && value !== answerValue) {
        if (!(value < sliderConfig.leftValue || value > sliderConfig.rightValue))
          if (setAnswerValue) {
            setAnswerValue(value)
          }
      }
    },
    400,
    [answerValue, value],
  )

  const handleSetSliderValue = (sliderValue: number) => {
    if (isNaN(sliderValue)) {
      // undefined => attente retour API
      // null => La valeur du champ vient d'être effacée et on attend la nouvelle valeur
      setValue(answerValue ? null : undefined)
    } else {
      setValue(sliderValue)
    }
  }
  const mobileMode = !matchDevice
  const sliderConfig: QuestionConfig = question.config || {
    leftValue: 0,
    rightValue: 100,
    minPoints: 0,
    maxPoints: 100,
    stepValue: 1,
    inverted: false,
  }
  const hasData = isDefined(value)
  const valueToUse =
    value ??
    (mode === QuestionnaireMode.managerEdit
      ? sliderConfig.leftValue + (sliderConfig.rightValue - sliderConfig.leftValue) / 2
      : undefined)
  return (
    <QuestionWrapper
      mode={mode}
      question={question}
      filled={hasData}
      title={<QuestionTitle question={question} />}
      onEdit={isReadonly ? undefined : onEdit}
      onChangeQuestionImportance={isReadonly ? undefined : onChangeQuestionImportance}
      onDelete={isReadonly ? undefined : onDelete}
    >
      <div className="w-full">
        {mobileMode && (
          <MobileInputValue
            value={valueToUse}
            minValue={sliderConfig.leftValue}
            maxValue={sliderConfig.rightValue}
            disabled={!!disabled}
            onValueChange={(val) => handleSetSliderValue(parseInt(val, 10))}
          />
        )}
        {!mobileMode && (
          <SliderInput
            value={valueToUse}
            min={sliderConfig.leftValue}
            max={sliderConfig.rightValue}
            step={sliderConfig.stepValue}
            onChange={handleSetSliderValue}
            disabled={!!disabled}
            testId="slider-answer"
          />
        )}
      </div>
    </QuestionWrapper>
  )
}

export const Slider = createMatchDevice(onlyDesktopConfig)(SliderComponent)
