import { restuxQuestionsApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { QuestionInstance } from '../../../model/Questionnaire'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<QuestionInstance, QuestionInstance>({
  apiConfig: restuxQuestionsApiConfig,
  refetchList: false,
  resourceName: RestuxResources.question,
})

export const questionInstancesActions = actions
export const questionInstancesReducers = reducers
export const questionInstancesSagas = sagas
