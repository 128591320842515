import colors from './styles/legacy/variables/_colors_v2.scss'

export enum Colors {
  brandPrimary = 'brandPrimary',
  brandSecondary = 'brandSecondary',
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
  contentDark1 = 'contentDark1',
  contentDark2 = 'contentDark2',
  contentDark3 = 'contentDark3',
  contentLight1 = 'contentLight1',
  contentLight2 = 'contentLight2',
  contentLight3 = 'contentLight3',
  backgroundPrimary = 'backgroundPrimary',
  backgroundLight1 = 'backgroundLight1',
  backgroundLight2 = 'backgroundLight2',
  backgroundLight3 = 'backgroundLight3',
  backgroundDark1 = 'backgroundDark1',
  backgroundDark2 = 'backgroundDark2',
  backgroundDark3 = 'backgroundDark3',
  backgroundBlue1 = 'backgroundBlue1',
  backgroundBlue2 = 'backgroundBlue2',
  backgroundBlue3 = 'backgroundBlue3',
  backgroundBlue4 = 'backgroundBlue4',
  backgroundBlue5 = 'backgroundBlue5',
  backgroundBlue6 = 'backgroundBlue6',

  dsWhite = 'dsWhite',
  dsPrimaryDefault = 'dsPrimaryDefault',
  dsPrimaryDark = 'dsPrimaryDark',
  dsPrimaryLight = 'dsPrimaryLight',
  dsPrimaryWashed = 'dsPrimaryWashed',
  dsSecondaryDefault = 'dsSecondaryDefault',
  dsSecondaryDark = 'dsSecondaryDark',
  dsSecondaryLight = 'dsSecondaryLight',
  dsSecondaryWashed = 'dsSecondaryWashed',
  dsDark1 = 'dsDark1',
  dsDark2 = 'dsDark2',
  dsDark3 = 'dsDark3',
  dsDark4 = 'dsDark4',
  dsLight5 = 'dsLight5',
  dsLight6 = 'dsLight6',
  dsLight7 = 'dsLight7',
  dsLight8 = 'dsLight8',
  dsLight9 = 'dsLight9',
  dsGreyBlue = 'dsGreyBlue',
  dsDimmerDark = 'dsDimmerDark',
}

export enum BackgroundColors {
  backgroundPrimary = 'backgroundPrimary',
  backgroundLight1 = 'backgroundLight1',
  backgroundLight2 = 'backgroundLight2',
  backgroundLight3 = 'backgroundLight3',
  backgroundDark1 = 'backgroundDark1',
  backgroundDark2 = 'backgroundDark2',
  backgroundDark3 = 'backgroundDark3',
}

export enum BorderColors {
  borderLight1 = 'borderLight1',
  borderLight2 = 'borderLight2',
  borderDark1 = 'borderDark1',
  borderDark2 = 'borderDark2',
}

export enum TagBackgroundColors {
  dsTagVisibility = 'dsTagVisibility',
  dsTag00 = 'dsTag00',
  dsTag01 = 'dsTag01',
  dsTag02 = 'dsTag02',
  dsTag03 = 'dsTag03',
  dsTag04 = 'dsTag04',
  dsTag05 = 'dsTag05',
  dsTag06 = 'dsTag06',
  dsTag07 = 'dsTag07',
  dsTag08 = 'dsTag08',
  dsTag09 = 'dsTag09',
  dsTag10 = 'dsTag10',
}
export enum TagTextColors {
  dsTagVisibility = 'dsTagTextVisibility',
  dsTag00 = 'dsTagText00',
  dsTag01 = 'dsTagText01',
  dsTag02 = 'dsTagText02',
  dsTag03 = 'dsTagText03',
  dsTag04 = 'dsTagText04',
  dsTag05 = 'dsTagText05',
  dsTag06 = 'dsTagText06',
  dsTag07 = 'dsTagText07',
  dsTag08 = 'dsTagText08',
  dsTag09 = 'dsTagText09',
  dsTag10 = 'dsTagText10',
}

export function getColor(
  colorName: Colors | BackgroundColors | BorderColors | TagBackgroundColors | TagTextColors,
) {
  return colors[colorName]
}

export function getContrastColor(hex: string) {
  const red = parseInt(hex.substring(1, 1 + 2), 16)
  const green = parseInt(hex.substring(3, 3 + 2), 16)
  const blue = parseInt(hex.substring(5, 5 + 2), 16)
  // http://www.w3.org/TR/AERT#color-contrast
  const ratio = Math.round((red * 299 + green * 587 + blue * 114) / 1000)
  return ratio > 125 ? '#000000' : '#FFFFFF'
}

export type ColorPreset = 'dark' | 'light'
