import { FunctionComponent, useState } from 'react'
import { Colors } from '../../../colors'
import { Heading, Icon, ValidationModal } from '../../../components/shared'
import { withWidthLowerObserver } from '../../../misc/WidthObserver.hoc'
import { breakSmall } from '../../../misc/responsive'
import { ImportantQuestionActionProps } from './ImportantQuestionAction.model'
import styles from './ImportantQuestionAction.module.scss'

const ImportantQuestionAction: FunctionComponent<ImportantQuestionActionProps> = ({
  question,
  isImportant,
  isLowerThanBreakpoint,
  onChangeQuestionImportance,
}) => {
  const [showImportantDeleteModal, setShowImportantDeleteModal] = useState<boolean>(false)

  const handleClick = () => {
    if (!onChangeQuestionImportance) {
      return
    }

    if (isImportant) {
      setShowImportantDeleteModal(true)
    } else {
      onChangeQuestionImportance(question, true)
    }
  }

  const handleValidateModal = () => {
    if (!onChangeQuestionImportance) {
      return
    }

    onChangeQuestionImportance(question, false)
    setShowImportantDeleteModal(false)
  }

  return (
    <>
      <div
        className={styles.important}
        onClick={handleClick}
        title="Information importante"
        data-test-id="button-question-important"
      >
        <div className={styles.label}>
          {!isLowerThanBreakpoint && (
            <Heading theme="medium" size={6}>
              Important{isImportant ? '' : ' ?'}
            </Heading>
          )}
        </div>
        <Icon
          icon="pin"
          size="micro"
          color={isImportant ? Colors.dsPrimaryDefault : Colors.dsLight5}
        />
      </div>
      <ValidationModal
        display={showImportantDeleteModal}
        title={`Êtes-vous sûr(e) de vouloir retirer cette information de vos favoris pour tous vos patients ?`}
        onClose={() => setShowImportantDeleteModal(false)}
        onSubmit={handleValidateModal}
        testId="modal-question-unpin"
      />
    </>
  )
}

export default withWidthLowerObserver(breakSmall)(ImportantQuestionAction)
