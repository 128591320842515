import { formatISO } from 'date-fns'
import getApi from '../../../../api/apisauceConfig'
import { CurrentTreamentFormResource, Treatment } from '../../../../model/Treatment'
import { PaginatedList } from '../../../../model/Pagination'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

import { serializeTreatment } from './PatientCurrentTreatments.mapper'
import { deserializePatientTreatmentsPaginatedResponse } from '../../../../data/patientTreatments/mapper/patientTreatments.mapper'
import { SerializedTreatment } from '../../../../data/patientTreatments/patientTreatments.model'

export const treatmentsBaseURL = 'patients/:patientId/treatments/:treatmentId?'

export const restuxPatientCurrentTreatmentsApiConfig: RestuxApiConfig<Treatment, Treatment> = {
  url: treatmentsBaseURL,
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<SerializedTreatment>>(route)
      .then(deserializePatientTreatmentsPaginatedResponse),
  deleteItem: (route) => getApi().delete(route),
}

export const postCurrentTreatment = (patientId: number, treatment: CurrentTreamentFormResource) =>
  getApi().post<Treatment[]>(`patients/${patientId}/treatments`, serializeTreatment(treatment))

export const terminateTreatment = (id: string, endAt: Date) =>
  getApi().put<Treatment>(`/treatments/${id}/end`, {
    endAt: formatISO(endAt),
  })
