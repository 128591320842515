import { JSONToValue, listVariables, removeVariable, valueToJSON } from '@follow/farte'
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects'
import { Questionnaire } from '../../../model/Questionnaire'
import { documentInstancesActions } from '../../cache/documentInstances/index'
import { addError, addValid } from '../../message/index'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { generateIdentifier } from '../../restux/restux.utilities'
import { hideBottomPanel } from '../../ui/bottomPanel/index'
import {
  documentWithSingleSourceOfTruthMapper,
  craftAMinimalTypescriptCompatibleDocumentUpdate,
  pendingVariablesSelector,
  PendingVariables,
} from '../editor'
import { domainDocumentInstancesActions } from './documentInstances.actions'
import { DomainDocumentInstancesActionTypes } from './documentInstances.model'
import { DocumentInstance, FarteDocumentInstance } from '../../../model/DocumentInstance'
import { lockDocumentInstance, lockMultipleDocuments, renewPrescription } from './api'
import { ApiResponse } from 'apisauce'
import {
  inUseMedicalEventDocumentIdSelector,
  inUseMedicalEventDocumentSelector,
} from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { getVariableData, variableDataSelector } from '../../renderer'
import { inUseMedicalEventIdSelector, medicalEventUiActions } from '../../ui/medicalEvents'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { documentAlertsDomainActions } from '../documentAlerts'
import { uiPatientCurrentTreatmentsActions } from '../../ui/patientCurrentTreatments'
import { AnyAction } from 'redux'
import { medicalEventsActions } from '../../cache/medicalEvents'
import { patientManualPrescriptionsActions } from '../../cache/patientManualPrescriptions'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'
import { difference, xor } from 'lodash'
import { isManualPrescriptionVariable, isPrescriptionVariable } from '@follow/cdk'
import { domainEditorActions } from '../editor/editor.actions'
import { medicalEventDomainActions } from '../medicalEvents'
import { queryClient } from '../../../App'
import { patientStatisticsKeys } from '../../../hooks/queries/patientStatistics/patientStatistics.keys'
import { documentKeys } from '../../../hooks/queries/documents/documents.keys'

function* addQuestionnaireToDocumentInstanceWorker({
  documentInstance,
  questionnaires,
}: ReturnType<typeof domainDocumentInstancesActions.addQuestionnaire>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)

  const newQuestionnaires = [
    ...documentInstance.questionnaires,
    ...questionnaires,
  ] as Questionnaire[] // PARCE QUE !!!
  yield put(
    documentInstancesActions.actions.apiUpdateItem(
      documentInstance.id,
      {
        questionnaires: newQuestionnaires,
        type: documentInstance.type,
      },
      { identifier: taskIdentifier },
    ),
  )
  yield put(addValid('Ajout en cours'))
  const {
    succeed,
  }: {
    succeed: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails>
  } = yield race({
    succeed: take(documentInstancesActions.types.STORE_SET_ITEM_DETAILS),
    failed: take(documentInstancesActions.types.DISPATCH_ERROR),
  })
  if (succeed && succeed.identifier === taskIdentifier) {
    yield put(hideBottomPanel())
  }
}

function* addQuestionnaireToDocumentInstanceWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.ADD_QUESTIONNAIRE,
    addQuestionnaireToDocumentInstanceWorker,
  )
}

function* removeVariableFromDocumentInstanceWorker({
  documentInstance,
  variableId,
}: ReturnType<typeof domainDocumentInstancesActions.removeVariable>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  const documentValue = JSONToValue(documentInstance.template)
  const newDoc = removeVariable(documentValue, variableId)
  const updates = documentWithSingleSourceOfTruthMapper(valueToJSON(newDoc))
  const inUseDocumentInstance: DocumentInstance | null = yield select(
    inUseMedicalEventDocumentSelector,
  )
  const craftedUpdate = craftAMinimalTypescriptCompatibleDocumentUpdate(
    updates,
    documentInstance,
  ) as Partial<DocumentInstance>

  yield put(
    documentInstancesActions.actions.apiUpdateItem(documentInstance.id, craftedUpdate, {
      identifier: taskIdentifier,
    }),
  )

  const { succeed }: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails> =
    yield race({
      succeed: take(
        (action: AnyAction) =>
          action.type === documentInstancesActions.types.STORE_SET_ITEM_DETAILS &&
          action.identifier === taskIdentifier,
      ),
      failed: take(documentInstancesActions.types.DISPATCH_ERROR),
    })
  if (
    succeed &&
    inUseDocumentInstance &&
    inUseDocumentInstance?.type === 'farte' &&
    updates.prescriptionUuids.length !== inUseDocumentInstance.prescriptions.length
  ) {
    yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
    yield put(documentAlertsDomainActions.getAlerts())
  }
}

function* removeVariableFromDocumentInstanceWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.REMOVE_VARIABLE,
    removeVariableFromDocumentInstanceWorker,
  )
}

function* updateDocumentInstanceValueSagaWorker({
  documentInstanceId,
  documentInstanceValue,
  forceVariableDataRefetch,
}: ReturnType<typeof domainDocumentInstancesActions.updateDocumentInstanceValue>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  const currentJsonValue = valueToJSON(documentInstanceValue)
  const updates = documentWithSingleSourceOfTruthMapper(currentJsonValue, true)

  const inUseDocumentInstance: DocumentInstance | null = yield select(
    inUseMedicalEventDocumentSelector,
  )

  if (inUseDocumentInstance?.type === 'farte' && !inUseDocumentInstance.locked) {
    // On construit une instance de document suffisante pour l'API
    const pendingVariables: PendingVariables = yield select(pendingVariablesSelector)
    const craftedDocumentInstance = craftAMinimalTypescriptCompatibleDocumentUpdate(
      updates,
      inUseDocumentInstance,
      pendingVariables,
    ) as unknown as Partial<FarteDocumentInstance>
    yield put(
      documentInstancesActions.actions.apiUpdateItem(documentInstanceId, craftedDocumentInstance, {
        identifier: taskIdentifier,
      }),
    )
    const { succeed }: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails> =
      yield race({
        succeed: take(
          (action: AnyAction) =>
            action.type === documentInstancesActions.types.STORE_SET_ITEM_DETAILS &&
            action.identifier === taskIdentifier,
        ),
        failed: take(documentInstancesActions.types.DISPATCH_ERROR),
      })

    if (!succeed) {
      return
    }
    if (craftedDocumentInstance.questionnaires?.length) {
      const patientId = getCurrentPatientId()
      patientId && queryClient.invalidateQueries(patientStatisticsKeys.detail(patientId))
    }
    // ? Clear pending variables ?
    yield put(domainEditorActions.clearPendingVariables())

    const currentVariableData = yield select(variableDataSelector)
    const currentVariableIds = Object.keys(currentVariableData ?? {})
    const newVariableIds = listVariables(updates.template).map(({ id }) => id)

    const variableDiff = difference(newVariableIds, currentVariableIds)
    const variableXor = xor(newVariableIds, currentVariableIds)

    // Si des variables présentes dans le doc n'ont pas de variable data
    if (forceVariableDataRefetch || variableDiff.length > 0) {
      yield put(getVariableData())
    }

    // Si ajout ou retrait de variable prescription
    if (variableXor.some(isPrescriptionVariable)) {
      yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
      yield put(documentAlertsDomainActions.getAlerts())
    }

    // Si ajout ou retrait de variable prescription manuelle
    if (variableXor.some(isManualPrescriptionVariable)) {
      const patientId = getCurrentPatientId()
      if (patientId) {
        yield put(
          patientManualPrescriptionsActions.actions.getPaginatedItems({
            page: { currentPage: 1, pageSize: 100 },
            params: { patientId: `${patientId}` },
          }),
        )
      }
    }
  }
  queryClient.invalidateQueries(documentKeys.detail(Number(documentInstanceId)))
}

function* updateDocumentInstanceValueSagaWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.UPDATE_VALUE,
    updateDocumentInstanceValueSagaWorker,
  )
}

function* updateDocumentInstanceWithVariableDataRefetchWorker({
  id,
  documentInstance,
}: ReturnType<
  typeof domainDocumentInstancesActions.updateDocumentInstanceWithVariableDataRefetch
>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  if (documentInstance.type === 'pdf') {
    yield put(
      documentInstancesActions.actions.apiUpdateItem(id, documentInstance, {
        identifier: taskIdentifier,
      }),
    )
  } else {
    yield put(medicalEventDomainActions.updateDocumentThenFetchEvent(id, documentInstance))
  }
  const { succeed }: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails> =
    yield race({
      succeed: take(
        (action: AnyAction) =>
          action.type === documentInstancesActions.types.STORE_SET_ITEM_DETAILS &&
          action.identifier === taskIdentifier,
      ),
      failed: take(documentInstancesActions.types.DISPATCH_ERROR),
    })

  if (!succeed) {
    return
  }

  queryClient.invalidateQueries(documentKeys.detail(id))
  yield put(getVariableData())
}

function* updateDocumentInstanceWithVariableDataRefetchWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.UPDATE_DOCUMENT_WITH_VARIABLEDATA_REFETCH,
    updateDocumentInstanceWithVariableDataRefetchWorker,
  )
}

function* renewPrescriptionWorker({
  documentId,
}: ReturnType<typeof domainDocumentInstancesActions.renewPrescription>) {
  const response: ApiResponse<DocumentInstance> = yield call(renewPrescription, documentId)
  if (response.ok && response.data) {
    yield put(domainDocumentInstancesActions.lockDocument(documentId, true, false))
    const { medicalEventId: newMedicalEventId, id: newDocumentId } = response.data
    yield put(medicalEventUiActions.useId(newMedicalEventId))
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
        id: newDocumentId,
      }),
    )
  } else {
    yield put(
      addError('Erreur lors du renouvellement', "Le renouvellement de l'ordonnance a échoué."),
    )
  }
}

function* renewPrescriptionWatcher() {
  yield takeEvery(DomainDocumentInstancesActionTypes.RENEW_PRESCRIPTION, renewPrescriptionWorker)
}

function* lockDocumentWorker({
  documentId,
  locked,
  refreshDocumentLocked = true,
}: ReturnType<typeof domainDocumentInstancesActions.lockDocument>) {
  const response = yield call(lockDocumentInstance, documentId, locked)

  if (response.ok && response.data) {
    yield put(medicalEventsActions.actions.apiGetItemDetails(response.data.medicalEventId))

    if (refreshDocumentLocked) {
      //Cela force le reload du component de l'éditeur après un lock
      yield put(
        medicalEventUiActions.selectMedicalEventDocument({
          medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
        }),
      )
      yield put(
        medicalEventUiActions.selectMedicalEventDocument({
          medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
          id: documentId,
        }),
      )
    }
  }
}

function* lockDocumentWatcher() {
  yield takeEvery(DomainDocumentInstancesActionTypes.LOCK_DOCUMENT, lockDocumentWorker)
}

function* lockMultipleDocumentsWorker({
  documentIds,
  locked,
}: ReturnType<typeof domainDocumentInstancesActions.lockMultipleDocuments>) {
  const response = yield call(lockMultipleDocuments, documentIds, locked)
  const inUseDocumentId: number | null = yield select(inUseMedicalEventDocumentIdSelector)
  if (response.ok && inUseDocumentId !== null) {
    const inUseMedicalEventId: number | null = yield select(inUseMedicalEventIdSelector)
    if (inUseMedicalEventId !== null) {
      yield put(medicalEventsActions.actions.apiGetItemDetails(inUseMedicalEventId))
    }

    //Force le démontage du component de l'éditeur pour prendre en compte le lock
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
      }),
    )
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
        id: inUseDocumentId,
      }),
    )
  }
}

function* lockMultipleDocumentsWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.LOCK_MULTIPLE_DOCUMENTS,
    lockMultipleDocumentsWorker,
  )
}

export const domainDocumentInstancesSagas = {
  addQuestionnaireToDocumentInstanceWatcher,
  removeQuestionnaireFromDocumentInstanceWatcher: removeVariableFromDocumentInstanceWatcher,
  updateDocumentInstanceValueSagaWatcher,
  lockDocumentWatcher,
  renewPrescriptionWatcher,
  lockMultipleDocumentsWatcher,
  updateDocumentInstanceWithVariableDataRefetchWatcher,
}
