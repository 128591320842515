import getApi from '../../api/apisauceConfig'
import {
  QuestionnaireTemplateMutationPayload,
  QuestionnaireTemplateResource,
} from './mapper/questionnaireTemplate.model'
import {
  deserializeQuestionnaireTemplateResponse,
  serializeQuestionnaireTemplate,
} from './mapper/questionnaireTemplate.mapper'

export const updateQuestionnaireTemplate = (
  id: number,
  payload: QuestionnaireTemplateMutationPayload,
) =>
  getApi()
    .put<QuestionnaireTemplateResource>(
      `/questionnaire_templates/${id}`,
      serializeQuestionnaireTemplate(payload),
    )
    .then(deserializeQuestionnaireTemplateResponse)
