import { useFormikContext } from 'formik'
import { FC, useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { autocompletionPageLimit } from '../../../../constants'
import { computeAddressSummary } from '../../../../misc/address.utilities'
import { DEFAULT_ADDRESS } from '../../../../model/Address'
import { Contact, ContactOrganization } from '../../../../model/Contact'
import { SelectOption } from '../../../../model/SelectOption'
import { User } from '../../../../model/User'
import { ContactOption } from '../../../contact'
import { IdentityCard, SearchInput, SelectInput } from '../../../shared'
import { PublicContactSearchModalProps } from './PublicContactSearchModal.model'
import { ValidationModal } from '../../../shared/modal/ValidationModal/ValidationModal.component'
import { useGetProfessions } from '../../../../hooks/queries/professions'

export const PublicContactSearchModal: FC<PublicContactSearchModalProps> = ({
  contacts,
  isUnmounting,
  clearContact,
  fetchContact,
  onClose,
  onSubmit,
}) => {
  const { query: professionQuery } = useGetProfessions()

  const [contactSearch, setContactSearch] = useState('')
  const [selectedContact, setSelectedContact] = useState<Contact>()
  const [selectedOrganization, setSelectedOrganization] = useState<ContactOrganization>()

  const { setValues } = useFormikContext<User>()

  useDebounce(
    () => {
      if (contactSearch.length > 2) {
        fetchContact({
          page: { currentPage: 1, pageSize: autocompletionPageLimit },
          filters: {
            search: contactSearch,
            private: false,
          },
        })
      } else {
        clearContact()
      }
    },
    500,
    [contactSearch, fetchContact],
  )

  const organizationOptions: SelectOption<ContactOrganization>[] = useMemo(() => {
    if (selectedContact?.organizations) {
      return selectedContact.organizations.map((orga) => ({
        value: orga,
        label: computeAddressSummary(orga.address, false) ?? 'Adresse vide',
      }))
    } else {
      return []
    }
  }, [selectedContact?.organizations])

  const selectedOrganizationOption = useMemo(
    () => organizationOptions.find(({ value }) => value.id === selectedOrganization?.id),
    [organizationOptions, selectedOrganization?.id],
  )

  const handleSelectContact = useCallback((selected: Contact) => {
    setSelectedContact(selected)
    setSelectedOrganization(selected.organizations.at(0) ?? undefined)
  }, [])

  const handleSubmit = useCallback(() => {
    if (selectedContact) {
      const { firstName, familyName, ppIdentifier } = selectedContact
      const contactAddress = selectedOrganization?.address

      const profession = professionQuery.data?.find(
        (profession) => profession.id === selectedContact.profession?.id,
      )

      const address = contactAddress
        ? {
            street: contactAddress.street,
            street2: contactAddress.street2,
            zipCode: contactAddress.zipCode,
            city: contactAddress.city,
            country: contactAddress.country,
          }
        : DEFAULT_ADDRESS

      setValues((previous) => ({
        ...previous,
        firstName,
        familyName,
        practiceLocation: {
          name: '',
          address: address,
        },
        rppsNumber: ppIdentifier ?? '',
        ...(profession && { profession }),
      }))

      setSelectedContact(undefined)
      setSelectedOrganization(undefined)
      onSubmit()
    }
  }, [onSubmit, professionQuery.data, selectedContact, selectedOrganization?.address, setValues])

  return (
    <ValidationModal
      isUnmounting={isUnmounting}
      icon="documentText"
      size="medium"
      title="Pré-remplir le formulaire avec l'Annuaire Santé"
      onSubmit={handleSubmit}
      onClose={onClose}
    >
      <div className="w-full text-left space-y-4">
        <SearchInput
          value={contactSearch}
          onChange={(event) => setContactSearch(event.target.value)}
          placeholder="Rechercher un praticien"
          onSelect={handleSelectContact}
          results={contacts.items}
          renderResult={(option, isHovered) => (
            <ContactOption contact={option} selected={isHovered} />
          )}
        />
        {selectedContact && (
          <>
            <IdentityCard
              title={`${selectedContact.firstName} ${selectedContact.familyName}`}
              subtitle={selectedContact.profession?.description ?? ''}
              onRemove={() => setSelectedContact(undefined)}
            />
            <SelectInput
              title="Adresse à utiliser"
              value={selectedOrganizationOption}
              options={organizationOptions}
              onSelect={({ value }) => setSelectedOrganization(value)}
            />
          </>
        )}
      </div>
    </ValidationModal>
  )
}
