import React, { FC, memo, useEffect, useMemo } from 'react'
import { HeadlessEditorProps } from './HeadlessEditor.model'
import { getVariablePlugins } from '@/plugins'
import { EditorContent, EditorContext, useEditor } from '@tiptap/react'
import { EDITOR_ALL_TOOLS } from '@/model/Toolbar'
import { useDefaultFontFamily, useEditorConfig } from '@/hooks'
import { VariableEditorConfig } from '@/model/Config'
import { EditorConfigContext, VariableEditorContext } from '@/context'
import styles from '../../styles/editor/elfe.module.scss'
import { defaultImageFetch } from '@/utils/image.utils'
import { isDefined } from '@/utils/functions.utils'

const renderVariableMenu: FC = () => null
const defaultSanitizeHtml = (html: string) => html

export const HeadlessEditor: FC<HeadlessEditorProps> = memo(function ({
  content,
  variableMap = {},
  defaultFontFamily,
  authenticatedImageRequest = defaultImageFetch,
  sanitizeHtmlVariable = defaultSanitizeHtml,
}) {
  const editorConfig = useEditorConfig({
    disabled: true,
    tools: Array.from(EDITOR_ALL_TOOLS),
    themes: [],
    authenticatedImageRequest,
  })

  const variableEditorConfig = useMemo<VariableEditorConfig>(
    () => ({ variableMap, renderVariableMenu, sanitizeHtmlVariable }),
    [sanitizeHtmlVariable, variableMap],
  )

  const extensions = useMemo(() => getVariablePlugins({ defaultFontFamily }), [defaultFontFamily])

  const editor = useEditor({
    content,
    extensions,
    editable: false,
  })

  useEffect(() => {
    if (isDefined(editor) && editor.getHTML() !== content) {
      editor.commands.setContent(content)
    }
  }, [content, editor])

  const { style } = useDefaultFontFamily(defaultFontFamily)

  return (
    <div style={style} className={styles['elfe-base']}>
      <EditorContext.Provider value={{ editor }}>
        <VariableEditorContext.Provider value={variableEditorConfig}>
          <EditorConfigContext.Provider value={editorConfig}>
            <EditorContent editor={editor} content={content} />
          </EditorConfigContext.Provider>
        </VariableEditorContext.Provider>
      </EditorContext.Provider>
    </div>
  )
})
