import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { ContactCard, ContactSearchAutocomplete, ContactsProfessionSearch } from '../../../contact'
import { Contact, ContactConfiguration, RecipientContact } from '../../../../model/Contact'
import { RestuxLoadingState } from '../../../../store/restux/ui'
import {
  CONTACT_VISIBILITY_OPTIONS,
  PatientContactsFormBlockBase,
  PatientContactsFormBlockProps,
} from './PatientContactsFormBlock.model'
import { useField } from 'formik'
import { autocompletionPageLimit } from '../../../../constants'
import { BottomPanelContactsContext } from '../../../../store/ui/bottomPanelContacts'
import { Input, SelectInput, SliderInput, Switch } from '../../../shared'
import { departmentRegex } from '../../../../misc/regex'
import { isDefined } from '../../../../misc/functions.utilities'
import { mapContactToRecipientContact } from '../../../../misc/contact.utilities'
import { ContactConfigureBottomPanel } from '../../../shared/bottomPanel/ContactConfigureBottomPanel'
import { useGetProfessions } from '../../../../hooks/queries/professions'

export const PatientContactsFormBlock: FunctionComponent<
  PatientContactsFormBlockProps & PatientContactsFormBlockBase
> = ({
  predictions,
  patientAddress,
  cities,
  colorPreset,
  searchContact,
  clearPredictions,
  openCreateContactForm,
  openEditContactForm,
  searchCity,
}) => {
  const { query: professionQuery } = useGetProfessions()

  const [searchValue, setSearchValue] = useState('')
  const [contactsField, , helpers] = useField<ReadonlyArray<RecipientContact>>({
    name: 'contacts',
  })
  const [professionSearch, setProfessionSearch] = useState('')
  const [department, setDepartement] = useState('')
  const [isGeolocationActive, setIsGeolocationActive] = useState(false)
  const [geolocationRange, setGeolocationRange] = useState(50)
  const [contactVisibility, setContactVisibility] = useState(CONTACT_VISIBILITY_OPTIONS[0])
  const [inUseContactId, setInUseContactId] = useState<string | null>(null)

  const isGeolocatable = isDefined(cities.items[0])

  useEffect(() => {
    if (!isGeolocatable && isGeolocationActive) {
      setIsGeolocationActive(false)
    }
  }, [isGeolocatable, isGeolocationActive])

  useDebounce(
    () => {
      const firstCityResult = cities.items[0]
      if (patientAddress?.zipCode && patientAddress.city) {
        const { zipCode, city } = patientAddress
        if (
          !firstCityResult ||
          zipCode !== firstCityResult.zipCode ||
          city.toLocaleLowerCase() !== firstCityResult.name.toLocaleLowerCase()
        ) {
          searchCity({
            page: {
              currentPage: 1,
              pageSize: 1,
            },
            filters: {
              search: city,
              zipCode,
            },
          })
        }
      }
    },
    500,
    [patientAddress],
  )

  useDebounce(
    () => {
      handleSearchContact()
    },
    400,
    [searchValue],
  )

  const handleSearchContact = () => {
    if (searchValue.length > 1) {
      const firstCityResult = cities.items[0]
      const geolocationFields =
        isGeolocationActive && isDefined(firstCityResult)
          ? {
              latitude: firstCityResult.latitude ?? undefined,
              longitude: firstCityResult.longitude ?? undefined,
              radius: geolocationRange,
            }
          : undefined

      searchContact({
        page: { currentPage: 1, pageSize: autocompletionPageLimit },
        filters: {
          search: searchValue,
          withOrganizationOnly: 'true',
          profession: professionSearch,
          department,
          ...geolocationFields,
          private: contactVisibility?.value ?? '',
        },
      })
    } else {
      clearPredictions()
    }
  }

  const handleHideSuggestions = () => {
    clearPredictions()
  }

  const handleAddContact = (contact: Contact) => {
    clearPredictions()
    setSearchValue('')
    if (contactsField.value.every(({ id }) => id !== contact.id)) {
      const recipient: RecipientContact = {
        ...mapContactToRecipientContact(contact),
        // Le premier contact ajouté est le médecin traitant / destinataire principal
        ...(contactsField.value.length === 0 && {
          assignedDoctor: true,
          addressingDoctor: true,
          mainRecipient: true,
        }),
      }
      helpers.setValue([...contactsField.value, recipient])
    }
  }

  const updateEditedContact = useCallback(
    (updatedContact: Contact, publicContactId?: string) => {
      const isCloningPublicContact = !contactsField.value.some(({ id }) => updatedContact.id === id)
      const toBeReplacedId = isCloningPublicContact ? publicContactId : updatedContact.id

      const updated = contactsField.value.map((contact) => {
        if (contact.id === toBeReplacedId) {
          return {
            ...contact,
            ...updatedContact,
          }
        } else {
          return contact
        }
      })

      helpers.setValue(updated)
    },
    [contactsField.value, helpers],
  )

  const handleEditContact = useCallback(
    (id: string, readOnly: boolean) => {
      openEditContactForm(
        id,
        readOnly,
        BottomPanelContactsContext.PATIENT_CONTACT,
        updateEditedContact,
      )
    },
    [openEditContactForm, updateEditedContact],
  )

  const handleToggleConfigurationContact = useCallback((id: string) => {
    setInUseContactId(id)
  }, [])

  const handleEditedContact = useCallback(
    (item: ContactConfiguration) => {
      setInUseContactId(null)
      helpers.setValue(
        contactsField.value.map((contact) => ({
          ...contact,
          contactConfiguration:
            inUseContactId === contact.id ? { ...item } : contact.contactConfiguration,
        })),
      )
    },
    [contactsField.value, helpers, inUseContactId],
  )

  function handleChangeAssignedDoctor(contactId: string, value: boolean) {
    const addressingDoctor = contactsField.value.find(({ addressingDoctor }) => addressingDoctor)
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        assignedDoctor: contact.id === contactId ? value : false,
        // Met le médecin traitant en adressant si il n'y en a pas
        addressingDoctor:
          !addressingDoctor && contact.id === contactId ? true : contact.addressingDoctor,
      })),
    )
  }

  function handleChangeAdressingDoctor(contactId: string, value: boolean) {
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        addressingDoctor: contact.id === contactId ? value : false,
      })),
    )
  }

  function handleChangeCopyRecipient(contactId: string, value: boolean) {
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        copyRecipient: contact.id === contactId ? value : contact.copyRecipient,
        mainRecipient: contact.id === contactId ? false : contact.mainRecipient,
      })),
    )
  }

  function handleChangeMainRecipient(contactId: string, value: boolean) {
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        copyRecipient: contact.id === contactId ? false : contact.copyRecipient,
        mainRecipient: contact.id === contactId ? value : contact.mainRecipient,
      })),
    )
  }

  function handleChangeOrganization(contactId: string, organizationId: string) {
    helpers.setValue(
      contactsField.value.map((contact) => {
        const organization =
          contact.id === contactId
            ? contact.organizations.find(({ id }) => id === organizationId) || contact.organization
            : contact.organization
        return {
          ...contact,
          organization,
        }
      }),
    )
  }

  function handleChangeMssEmail(contactId: string, mssEmailId: string) {
    helpers.setValue(
      contactsField.value.map((contact) => {
        const mssEmail =
          contact.id === contactId
            ? contact.mssEmails.find(({ id }) => id === mssEmailId) || contact.mssEmail
            : contact.mssEmail
        return {
          ...contact,
          mssEmail,
        }
      }),
    )
  }

  function handleRemoveContact(contactId: string) {
    helpers.setValue(contactsField.value.filter(({ id }) => contactId !== id))
  }

  function handleCreateContact() {
    openCreateContactForm(BottomPanelContactsContext.PATIENT_CONTACT, handleAddContact)
  }

  return (
    <>
      <div
        className="w-full px-2 text-shades-4 text-xs font-medium mb-4"
        title={
          isGeolocatable
            ? undefined
            : "L'adresse du patient ne permet pas de recherche par géolocalisation"
        }
      >
        <Switch
          name="Rechercher autour de l'adresse du patient"
          checked={isGeolocationActive}
          disabled={!isGeolocatable}
          onChange={(value) => setIsGeolocationActive(value)}
        />
        {isGeolocationActive && (
          <div className="mt-4 flex flex-col w-full">
            <span className="mb-2">Rayon de recherche ({geolocationRange} km)</span>
            <SliderInput
              appearance="compact"
              value={geolocationRange}
              min={10}
              max={200}
              step={10}
              onChange={setGeolocationRange}
            />
          </div>
        )}
      </div>
      <div className="flex flex-nowrap w-full">
        <div className="flex-grow">
          <ContactsProfessionSearch
            search={professionSearch}
            onSearch={setProfessionSearch}
            loading={false}
            listOfProfessions={professionQuery.data ?? []}
            disabled={!isDefined(professionQuery.data)}
            colorPreset="light"
          />
        </div>
        <div className="ml-4 w-32">
          <Input
            label="Département"
            value={department}
            onChange={(e) => setDepartement(e.currentTarget.value)}
            name="contactDepartment"
            colorPreset="light"
            placeholder="09"
            valid={department.length > 0 ? departmentRegex.test(department) : undefined}
          />
        </div>
      </div>
      <div className="flex flex-nowrap w-full">
        <SelectInput<boolean | null>
          title="Type de contacts"
          options={CONTACT_VISIBILITY_OPTIONS}
          value={contactVisibility}
          onSelect={setContactVisibility}
        />
      </div>
      <ContactSearchAutocomplete
        searchValue={searchValue}
        predictions={predictions.items}
        loading={predictions.loadingState === RestuxLoadingState.LOADING}
        onSearchValueChange={(event) => setSearchValue(event.target.value)}
        onHideSuggestion={handleHideSuggestions}
        onSelectContact={handleAddContact}
        createContact={handleCreateContact}
        onFocus={handleSearchContact}
        colorPreset={colorPreset}
      />
      {contactsField.value.map((contact) => (
        <div key={contact.id}>
          <ContactCard
            context={BottomPanelContactsContext.PATIENT_CONTACT}
            showAddresses={true}
            contact={contact}
            loading={false}
            onClickDisplayDetails={handleEditContact}
            onClickDisplayConfiguration={handleToggleConfigurationContact}
            onClose={() => handleRemoveContact(contact.id)}
            onChangeAssignedDoctor={(event) =>
              handleChangeAssignedDoctor(contact.id, event.target.checked)
            }
            onChangeAddressingDoctor={(event) =>
              handleChangeAdressingDoctor(contact.id, event.target.checked)
            }
            onChangeCopyRecipient={(event) =>
              handleChangeCopyRecipient(contact.id, event.target.checked)
            }
            onChangeMainRecipient={(event) =>
              handleChangeMainRecipient(contact.id, event.target.checked)
            }
            onChangeOrganization={(organizationId) =>
              handleChangeOrganization(contact.id, organizationId)
            }
            onChangeMssEmail={(mssEmailId) => handleChangeMssEmail(contact.id, mssEmailId)}
          />
        </div>
      ))}
      <ContactConfigureBottomPanel
        itemId={inUseContactId}
        onItemEdited={(item) => handleEditedContact(item)}
        onRequestClose={() => {
          setInUseContactId(null)
        }}
        display={isDefined(inUseContactId)}
      />
    </>
  )
}
