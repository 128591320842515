import getApi from './apisauceConfig'
import { generateUuid, Survey, SurveyApi } from '../store/survey_legacy/index'
import { singleItemPageLimit } from '../constants'

interface SurveyDuplication {
  /**
   * survey id to duplicate
   */
  surveyId: number

  /**
   * user id to duplicate to
   */
  userId: string
}

const route = 'surveys'

export interface SurveyApiResponse {
  data: Survey[]
  currentPage: number
  pageCount: number
}

export const getSurveys = (page: number) =>
  getApi().get<SurveyApiResponse>(route, {
    page,
    limit: singleItemPageLimit,
  })

export const getSurvey = (surveyId: number) => getApi().get(`${route}/${surveyId}`)

export const addSurvey = (name: string) =>
  getApi().post(route, {
    name,
    finish: '',
    content: [
      {
        uuid: generateUuid(),
        level: 0,
        questions: [],
      },
    ],
    isActive: true,
  })

export const duplicateSurvey = (survey: SurveyApi) => getApi().post(route, survey)

export const updateSurvey = (surveyId: number, survey: Partial<SurveyApi>) =>
  getApi().put(`${route}/${surveyId}`, survey)

export const deleteSurvey = (surveyId: number) => getApi().delete(`${route}/${surveyId}`)

export const duplicateSurveyToAnotherUer = (surveyDuplication: SurveyDuplication) =>
  getApi().post(`admin/${route}/clone`, surveyDuplication)

export const getSurveyQuestionnaires = (surveyId: number) =>
  getApi().get(`${route}/${surveyId}/questionnaires`)

export const getSurveyQuestionnaire = (surveyId: number, questionnaireId: number) =>
  getApi().get(`${route}/${surveyId}/questionnaires/${questionnaireId}`)
