import { ApiResponse } from 'apisauce'
import { stringifyFromValueJSON } from '../../../../misc/editor.utilities'
import { DocumentInstance, DocumentInstanceListItem } from '../../../../model/DocumentInstance'
import {
  ApiDocumentInstanceListItemResource,
  ApiDocumentInstanceResource,
  ApiDocumentInstanceUpdateResource,
} from './DocumentInstances.model'
import { PaginatedList } from '../../../../model/Pagination'
import { formatPrescriptionForDocumentUpdate } from '../../../../misc/documents.utilities'
import { deserializeDocumentInstance } from '@follow/cdk'

export { deserializeDocumentInstance }

export function deserializeDocumentInstances(
  documentInstances: ApiDocumentInstanceResource[],
): DocumentInstance[] {
  return documentInstances.map((documentInstance) => deserializeDocumentInstance(documentInstance))
}

export function deserializeDocumentInstanceListItem(
  item: ApiDocumentInstanceListItemResource,
): DocumentInstanceListItem {
  return {
    ...item,
    category: item.documentCategory.id,
    contacts: item.contacts,
  }
}

export function deserializeDocumentInstancePaginatedResponse(
  response: ApiResponse<PaginatedList<ApiDocumentInstanceListItemResource>>,
): ApiResponse<PaginatedList<DocumentInstanceListItem>> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeDocumentInstanceListItem) },
  }
}

export function deserializeDocumentInstanceResponse(
  response: ApiResponse<ApiDocumentInstanceResource>,
): ApiResponse<DocumentInstance> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeDocumentInstance(data),
  }
}

export function serializeDocumentInstance(
  instance: Partial<DocumentInstance>,
): ApiDocumentInstanceUpdateResource {
  if (instance.type === 'farte') {
    const {
      questionnaires,
      category,
      variables,
      medicalEventId,
      template,
      contacts,
      prescriptions,
      ...restProps
    } = instance
    return {
      ...restProps,
      documentCategoryId: category?.id,
      questionnaireInstanceIds: questionnaires
        ? questionnaires.map((questionnaire) => questionnaire.id)
        : undefined,
      variableIds: variables ? variables.map((variable) => variable.id) : undefined,
      medicalEventId,
      template: template ? stringifyFromValueJSON(template) || undefined : undefined,
      contacts: contacts
        ? contacts.map(({ organization, mssEmail, ...contact }) => ({
            ...contact,
            organizationId: organization.id,
            mssEmailId: mssEmail && mssEmail.id,
          }))
        : undefined,
      prescriptions: prescriptions
        ? prescriptions.map(formatPrescriptionForDocumentUpdate)
        : undefined,
    }
  }
  if (instance.type === 'pdf') {
    const { category, ...restProps } = instance
    return {
      ...restProps,
      documentCategoryId: category?.id,
    }
  }

  return {}
}
