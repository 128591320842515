import getApi from '../../api/apisauceConfig'
import { QuestionnaireTemplateResource } from './mapper/questionnaireTemplate.model'
import { deserializeQuestionnaireTemplateResponse } from './mapper/questionnaireTemplate.mapper'

export const moveQuestionTemplate = (
  questionnaireTemplateId: number,
  questionTemplateIds: number[],
) =>
  getApi()
    .put<QuestionnaireTemplateResource>(`/questionnaire_templates/${questionnaireTemplateId}`, {
      questionTemplateIds,
    })
    .then(deserializeQuestionnaireTemplateResponse)
