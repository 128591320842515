import getApi from '../../../../api/index'

import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

import { QuestionInstance } from '../../../../model/Questionnaire'
import { PaginatedList } from '../../../../model/Pagination'
import {
  deserializeQuestionInstanceResponse,
  deserializeQuestionInstancesPageResponse,
  serializeQuestionInstance,
} from './QuestionInstance.mapper'
import { QuestionInstanceResource } from './QuestionInstances.model'

export const restuxQuestionsApiConfig: RestuxApiConfig<QuestionInstance, QuestionInstance> = {
  url: '/questions',
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<QuestionInstanceResource>>(route)
      .then(deserializeQuestionInstancesPageResponse),
  getItem: (route) =>
    getApi().get<QuestionInstanceResource>(route).then(deserializeQuestionInstanceResponse),
  createItem: (route, item) =>
    getApi().post<QuestionInstance>(route, serializeQuestionInstance(item)),
  updateItem: (route, item) =>
    getApi().put<QuestionInstance>(route, serializeQuestionInstance(item)),
  deleteItem: (route) => getApi().delete<void>(route),
}
