import { QuestionnaireTemplate, QuestionTemplate, QuestionType } from './Questionnaire'

export interface LightQuestionnaireTemplate
  extends Omit<QuestionnaireTemplate, 'questionTemplates'> {}

export function createDefaultQuestionTemplate(
  type: QuestionType,
  {
    questionnaireTemplateId,
    documentTemplateId,
    displayOrder,
    parentAnswerId,
  }: {
    questionnaireTemplateId?: number
    documentTemplateId?: number
    displayOrder?: number
    parentAnswerId?: number
  },
): Partial<Omit<QuestionTemplate, 'id'>> {
  const questionnaireTemplateIds = questionnaireTemplateId ? [questionnaireTemplateId] : undefined
  const documentTemplateIds = documentTemplateId ? [documentTemplateId] : undefined
  const baseQuestion = {
    type,
    questionnaireTemplateIds,
    documentTemplateIds,
    displayOrder,
    parentAnswerId,
  }
  return type === QuestionType.Slider
    ? {
        ...baseQuestion,
        config: {
          leftValue: 0,
          rightValue: 10,
          stepValue: 1,
          inverted: false,
        },
      }
    : baseQuestion
}
