import getApi from '../../api'
import { serializeQuestionTemplate } from './mapper/questionTemplates.mapper'
import { QuestionTemplateMutationPayload } from './mapper/questionTemplates.model'
import { QuestionTemplate } from '../../model/Questionnaire'

export const createQuestionTemplate = (questionTemplate: QuestionTemplateMutationPayload) =>
  getApi().post<QuestionTemplate>(
    '/question_templates',
    serializeQuestionTemplate(questionTemplate),
  )
