import styled from '../../../../../styles/legacy/styled/styled'

export const ModalContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 24px;
  justify-items: center;
  margin: 40px 40px 24px;
  text-align: center;
`
