import { useMutation } from '@tanstack/react-query'
import { parse, isValid, format } from 'date-fns'
import { useAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { useCallback } from 'react'
import { getSephiraVitalCardInfos } from '../../../../data/vitalCard/sephira'
import {
  SephiraVitalCardResponse,
  SEPHIRA_BDAY_FORMAT,
} from '../../../../data/vitalCard/sephira/mapper/sephira.model'
import { DATE_FORMAT_API } from '../../../../misc/date.utilities'
import { isDefined } from '../../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import { Patient, Sex, Title } from '../../../../model/Patient'
import {
  SephiraErrorCall,
  SEPHIRA_VITAL_CARD_ERROR_MAP,
  VitalCardContentGroupBeneficiary,
  VitalCardContentGroupGlobal,
} from '../../../../model/Sephira'
import { cpsCodeModalAtom } from '../../../../state/vitalCard'
import { addError } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { VitalCardReadReturn } from '../vitalCard.model'

interface VitalCardSephiraVariables {
  pincode: string | undefined
}

export const useVitalCardSephira = () => {
  const onError = useActionDispatch(addError)
  const [, setCpsModalState] = useAtom(cpsCodeModalAtom)

  const mutation = useMutation<SephiraVitalCardResponse, Error, VitalCardSephiraVariables>({
    mutationFn: async ({ pincode }) => {
      const { ok, data } = await getSephiraVitalCardInfos(new Date(), pincode)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Erreur lors de la lecture de carte vitale')
      }

      return data
    },
  })

  // Gestion du retour si lecture réussie
  // Se référer à la page 57 de la documentation SESAM-vital
  const onSuccessfullRead = useCallback(
    (data: SephiraVitalCardResponse) => {
      setCpsModalState({ onPinCodeInput: null })

      // id: 104 => Bénéficiaire
      const rawBeneficiaries =
        data.groups_o?.filter(
          (entry): entry is VitalCardContentGroupBeneficiary => entry.id === 104,
        ) ?? []
      // id: 101 => Données Assuré
      const globalData = data.groups_o
        ?.filter((entry): entry is VitalCardContentGroupGlobal => entry.id === 101)
        .at(0)

      const nir = globalData
        ? globalData.content.ass_nir + globalData.content.ass_cle_nir
        : undefined

      try {
        const beneficiaries: Array<Partial<Patient>> = rawBeneficiaries.map(
          ({
            content: { dob_name, dob_surname, dob_usual, dob_bday, dob_qualite_beneficiaire },
          }) => {
            const birthdate = parse(dob_bday, SEPHIRA_BDAY_FORMAT, new Date())
            const sex = nir?.charAt(0) === '1' ? Sex.MALE : Sex.FEMALE
            const title = nir?.charAt(0) === '1' ? Title.MR : Title.MS
            const isCardOwner = dob_qualite_beneficiaire === '0'

            return {
              inseeNumber: nir,
              birthPlaceCode: nir?.replace(/\s/g, '').substring(5, 10),
              birthFirstName: dob_surname,
              birthLastName: dob_name ? dob_name : dob_usual,
              usedLastName: dob_name ? dob_usual : undefined,
              birthDate: isValid(birthdate) ? format(birthdate, DATE_FORMAT_API) : '',
              sex: isCardOwner ? sex : undefined,
              title: isCardOwner ? title : undefined,
            }
          },
        )
        return beneficiaries
      } catch (e) {
        throw new UserFriendlyError(
          'error',
          'Une erreur est survenue',
          "Impossible d'interpréter les données lues sur la carte vitale",
        )
      }
    },
    [setCpsModalState],
  )

  const readVitalCard = useCallback(
    async (pincode?: string): Promise<VitalCardReadReturn> => {
      const data = await mutation.mutateAsync({ pincode })

      // -- Gestion d'erreur et de saisie de code PIN

      // `call` indique une erreur ou un warning
      if (data.report.call) {
        const messageFromErrorMap = SEPHIRA_VITAL_CARD_ERROR_MAP[data.report.call]

        // Erreurs en cas de code pin CPS manquants/incorrects
        if (
          [SephiraErrorCall.MISSING_PINCODE, SephiraErrorCall.WRONG_PINCODE, 96].includes(
            data.report.call,
          )
        ) {
          if (data.report.call === SephiraErrorCall.WRONG_PINCODE) {
            onError(messageFromErrorMap)
          }

          // Ouvre la modal d'input de code pin en fournissant un callback async
          const pinCodePromise = new Promise<string | null>((resolve, reject) => {
            const promiseCallback = (pincode: string | null) => resolve(pincode)
            setCpsModalState({ onPinCodeInput: promiseCallback })
          })

          // On peut alors await le callback, ce qui permettra de poursuivre quand l'utilisateur aura rentré un code pin dans la modale
          const pincode = await pinCodePromise

          // L'utilisateur a fermé la modale
          if (!isDefined(pincode)) {
            setCpsModalState(RESET)
            return null
          }

          // L'utilisateur a rentré un code pin
          return await readVitalCard(pincode)
        }

        // Erreurs connues
        if (messageFromErrorMap) {
          setCpsModalState(RESET)
          throw new UserFriendlyError(
            'error',
            'Erreur lors de la lecture de carte vitale',
            messageFromErrorMap,
          )
        }

        // Erreur par défaut
        if (data.report.exit) {
          if (!messageFromErrorMap) {
            setCpsModalState(RESET)
            throw new UserFriendlyError(
              'error',
              'Erreur lors de la lecture de carte vitale',
              `Une erreur inconnue est survenue (${data.report.call}-${data.report.exit})`,
            )
          }
        }
      }

      return onSuccessfullRead(data)
    },
    [mutation, onError, onSuccessfullRead, setCpsModalState],
  )

  return { mutation, readVitalCard }
}
