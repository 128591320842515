import { FeaturesType } from '@follow/cdk'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { UserFriendlyError } from '../../../model/Error'
import { beneficiariesAtom } from '../../../state/vitalCard'
import { useUserEnabledFeature } from '../../utils/user'
import { useVitalCardSephira } from './sephira'
import { useVitalcardStellair } from './stellair'
import { useHandleBeneficiary } from './useHandleBeneficiary.query'
import { VitalCardReadReturn } from './vitalCard.model'

export const useReadVitalCard = (isPatientFormActive: boolean) => {
  const setBeneficiariesState = useSetAtom(beneficiariesAtom)

  const { readVitalCard: readThroughSephira } = useVitalCardSephira()
  const { readVitalCard: readThroughStellair } = useVitalcardStellair()
  const { mutate: handleBeneficiary } = useHandleBeneficiary()

  const vitalCardService = useUserEnabledFeature(FeaturesType.vitalCardService)

  return useMutation<VitalCardReadReturn, Error>({
    mutationFn: async () => {
      // Lecture de la carte vitale
      if (vitalCardService === 'sephira') {
        return await readThroughSephira()
      } else if (vitalCardService === 'stellair') {
        return await readThroughStellair()
      } else {
        throw new UserFriendlyError('error', 'Aucun service de lecture de carte vitale paramétré')
      }
    },
    onSuccess: (data) => {
      if (!data) return

      if (data.length > 1) {
        setBeneficiariesState({
          beneficiaries: data,
          selected: null,
        })
      } else {
        const patient = data[0]
        setBeneficiariesState({
          beneficiaries: data,
          selected: patient,
        })
        handleBeneficiary({ patient, isPatientFormActive })
      }
    },
  })
}
