import classNames from 'classnames/bind'
import { FunctionComponent, useRef } from 'react'
import { NavigationMenu } from '../../components/layout/shared'
import { LayoutErrorBoundary } from '../../errors'
import { NoDoctorsAssignedError } from '../../errors/NoDoctorsAssignedError'
import { MainLayoutProps } from './MainLayout.model'
import styles from './MainLayout.module.scss'
import { Outlet } from 'react-router-dom'

const cx = classNames.bind(styles)

const MainLayout: FunctionComponent<MainLayoutProps> = ({
  bottomPanelIsOpen = false,
  currentUser,
}) => {
  const mainlayoutContainer = useRef<HTMLDivElement>(null)

  return (
    <LayoutErrorBoundary>
      <div className="flex flex-row h-screen">
        <NavigationMenu />
        <div ref={mainlayoutContainer} className={cx(styles.page, { hidden: bottomPanelIsOpen })}>
          <NoDoctorsAssignedError currentUser={currentUser}>
            <div className={cx(styles.pageContent)}>
              <Outlet />
            </div>
          </NoDoctorsAssignedError>
        </div>
      </div>
    </LayoutErrorBoundary>
  )
}

export default MainLayout
