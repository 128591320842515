import { FollowTypes } from '../model'
import { parseToValueJSON } from './editor.utils'

export function deserializeAnswer(
  answer: FollowTypes.Questionnaire.AnswerInstanceDTO | FollowTypes.Questionnaire.AnswerTemplateDTO,
): FollowTypes.Questionnaire.AnswerInstance | FollowTypes.Questionnaire.AnswerTemplate {
  return {
    ...answer,
    textOutput: answer.textOutput ? parseToValueJSON(answer.textOutput) : undefined,
    childQuestions: answer.childQuestions
      ? answer.childQuestions.map(deserializeQuestion)
      : undefined,
  }
}

export function deserializeQuestion(
  question:
    | FollowTypes.Questionnaire.QuestionInstanceDTO
    | FollowTypes.Questionnaire.QuestionTemplateDTO,
): FollowTypes.Questionnaire.QuestionTemplate | FollowTypes.Questionnaire.QuestionInstance {
  return {
    ...question,
    documentIds: question.documentIds || [],
    answers: question.answers.map(deserializeAnswer),
  }
}

export function deserializeQuestionnaire(
  resource:
    | FollowTypes.Questionnaire.QuestionnaireInstanceDTO
    | FollowTypes.Questionnaire.QuestionnaireTemplateDTO,
):
  | FollowTypes.Questionnaire.QuestionnaireInstance
  | FollowTypes.Questionnaire.QuestionnaireTemplate {
  return {
    ...resource,
    questions: resource.questions.map(deserializeQuestion),
  }
}
