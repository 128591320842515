import getApi from '../../api'
import { largePageLimit } from '../../constants'
import { PaginatedList } from '../../model/Pagination'
import { PatientImportantInformation } from '../../model/PatientImportantInformations'

export const getPatientImportantInformations = (
  page: number,
  patientId: number,
  signal?: AbortSignal,
  limit = largePageLimit,
) =>
  getApi().get<PaginatedList<PatientImportantInformation>>(
    `/important_informations/patient/${patientId}`,
    {
      page,
      limit,
    },
    { signal },
  )
