import { createSelector } from 'reselect'
import { AnswerValue } from '../../../model/AnswerValue'
import {
  DocumentQuestionListWithAnswerValues,
  MedicalEventDocumentType,
  WithFilledIndicatorDocumentQuestionList,
} from '../../../model/MedicalEvent'
import {
  QuestionInstance,
  QuestionnaireWithAnswerValues,
  WithFilledIndicatorQuestionnaireInstance,
} from '../../../model/Questionnaire'
import { RootState } from '../../reducers'
// Import hierachique pour eviter les problèmes de dépendances circulaires
import { documentAnswerValuesListSelector } from '../documentAnswerValues'
import { currentUserSelector } from '../me'
import {
  inUseDocumentQuestionnaireSelector,
  inUseMedicalEventSelector,
  selectedDocumentQuestionnairesSelector,
  selectedMedicalEventDocumentSelector,
} from '../../ui/medicalEvents'
import {
  inUseMedicalEventContentIdSelector,
  selectedContentFilterSelector,
  SelectedContentType,
} from '../../ui/medicalEvents/medicalEventContent'
import { inUseMedicalEventDocumentSelector } from '../../ui/medicalEvents/medicalEventDocumentInstances'

import { mapAnswerValuesToQuestion } from './questionnaireAnswerValues.mapper'
import { Prescription } from '../../../model/Prescription'
import { QuoteLine, WithFilledIndicatorQuoteLine } from '../../../model/Acte'

export const isOwnerOfSelectedMedicalEvent = createSelector(
  [currentUserSelector, inUseMedicalEventSelector],
  (currentUser, medicalEvent): boolean => {
    if (!currentUser || !medicalEvent) {
      return false
    }

    return currentUser.id === medicalEvent.owner.id
  },
)

export const inUseMedicalEventQuestionnaireWithAnswerValuesSelector = (
  state: RootState,
): QuestionnaireWithAnswerValues | null => {
  const inUseDocument = inUseMedicalEventDocumentSelector(state)
  const inUseQuestionnaire = inUseDocumentQuestionnaireSelector(state)
  if (!inUseDocument || !inUseQuestionnaire) {
    return null
  }
  const documentId = inUseDocument.id
  const answerValues = documentAnswerValuesListSelector(state, documentId)

  return {
    ...inUseQuestionnaire,
    questions: inUseQuestionnaire.questions.map((question) =>
      mapAnswerValuesToQuestion(answerValues, question),
    ),
  }
}

export const inUseMedicalEventPrescriptionSelector = (state: RootState): Prescription | null => {
  const inUseDocument = inUseMedicalEventDocumentSelector(state)
  const inUseDrug = inUseMedicalEventContentIdSelector(state)
  if (!inUseDocument || !inUseDrug || inUseDocument.type !== 'farte') {
    return null
  }
  const prescription = inUseDocument.prescriptions.find(({ uuid }) => uuid === inUseDrug)
  return prescription ?? null
}

export const inUseMedicalEventQuoteLineSelector = (state: RootState): QuoteLine | null => {
  const inUseDocument = inUseMedicalEventDocumentSelector(state)
  const inUseQuoteLineId = inUseMedicalEventContentIdSelector(state)
  if (!inUseDocument || !inUseQuoteLineId || inUseDocument.type !== 'farte') {
    return null
  }
  const quoteLine = inUseDocument.quoteLines.find(({ id }) => id === inUseQuoteLineId)
  return quoteLine ?? null
}

export const inUseDocumentQuestionListWithAnswerValuesSelector = (
  state: RootState,
): DocumentQuestionListWithAnswerValues | null => {
  const inUseDocument = inUseMedicalEventDocumentSelector(state)

  const selectedContent = selectedContentFilterSelector(state)
  if (
    !inUseDocument ||
    selectedContent.type !== SelectedContentType.VARIABLES ||
    inUseDocument.type !== 'farte'
  ) {
    return null
  }

  const documentId = inUseDocument.id
  const answerValues = documentAnswerValuesListSelector(state, documentId)

  return {
    documentId: inUseDocument.id,
    title: 'Variables',
    questions: inUseDocument.variables.map((question) =>
      mapAnswerValuesToQuestion(answerValues, question),
    ),
  }
}

export function inUseQuestionsWithAnswerValuesSelector(state: RootState) {
  const selectedContentFilter = selectedContentFilterSelector(state)

  if (selectedContentFilter.type === SelectedContentType.QUESTIONNAIRE) {
    const questionnaire: ReturnType<typeof inUseMedicalEventQuestionnaireWithAnswerValuesSelector> =
      inUseMedicalEventQuestionnaireWithAnswerValuesSelector(state)
    if (!questionnaire) {
      return null
    }
    return questionnaire.questions
  } else if (selectedContentFilter.type === SelectedContentType.VARIABLES) {
    const questionList: ReturnType<typeof inUseDocumentQuestionListWithAnswerValuesSelector> =
      inUseDocumentQuestionListWithAnswerValuesSelector(state)
    if (!questionList) {
      return null
    }
    return questionList.questions
  }
}

export function selectedDocumentQuestionnairesWithFilledIndicatorSelector(
  state: RootState,
): WithFilledIndicatorQuestionnaireInstance[] | null {
  const inUseMedicalEventDocument: ReturnType<typeof inUseMedicalEventDocumentSelector> =
    inUseMedicalEventDocumentSelector(state)
  const documentQuestionnaires = selectedDocumentQuestionnairesSelector(state)

  if (
    !inUseMedicalEventDocument ||
    !documentQuestionnaires ||
    inUseMedicalEventDocument.type !== 'farte'
  ) {
    return null
  }

  const answerValues =
    documentAnswerValuesListSelector(state, inUseMedicalEventDocument.id) ||
    inUseMedicalEventDocument.answerValues
  return documentQuestionnaires.map((documentQuestionnaire) => ({
    ...documentQuestionnaire,
    isFilled: checkQuestionnaireFilled(documentQuestionnaire.questions, answerValues),
  }))
}

export function selectedDocumentDrugs(state: RootState): ReadonlyArray<Prescription> | null {
  const inUseMedicalEventDocument: ReturnType<typeof inUseMedicalEventDocumentSelector> =
    inUseMedicalEventDocumentSelector(state)

  return inUseMedicalEventDocument && inUseMedicalEventDocument.type === 'farte'
    ? inUseMedicalEventDocument.prescriptions
    : null
}

export function selectedDocumentQuestionListWithFilledIndicatorSelector(
  state: RootState,
): WithFilledIndicatorDocumentQuestionList | null {
  const medicalEventDocument = selectedMedicalEventDocumentSelector(state)
  if (
    !medicalEventDocument ||
    medicalEventDocument.type !== MedicalEventDocumentType.FW_DOCUMENT ||
    medicalEventDocument.item.type !== 'farte'
  ) {
    return null
  }
  const documentInstance = medicalEventDocument.item

  return {
    documentId: documentInstance.id,
    title: 'Variables',
    questions: documentInstance.variables,
    isFilled: checkQuestionnaireFilled(
      documentInstance.variables,
      documentAnswerValuesListSelector(state, documentInstance.id) || documentInstance.answerValues,
    ),
  }
}

export const selectedDocumentActes = (state: RootState): WithFilledIndicatorQuoteLine[] | null => {
  const inUseMedicalEventDocument: ReturnType<typeof inUseMedicalEventDocumentSelector> =
    inUseMedicalEventDocumentSelector(state)

  if (
    !inUseMedicalEventDocument ||
    inUseMedicalEventDocument.type !== 'farte' ||
    !inUseMedicalEventDocument.quoteLines.length
  )
    return null

  return inUseMedicalEventDocument.quoteLines.map((acte) => ({
    ...acte,
    isFilled: !!acte.basePrice,
  }))
}
/**
 *
 * Renvoi `true` si au moins une des questions du questionnaire a été répondue
 */
function checkQuestionnaireFilled(
  questions: ReadonlyArray<QuestionInstance>,
  answerValues: ReadonlyArray<AnswerValue>,
) {
  return questions.some((question) =>
    answerValues.find((answerValue) => answerValue.questionId === question.id),
  )
}
