import getApi from '../../api'
import { Patient, Sex } from '../../model/Patient'
import { deserializePatientResponse } from './mapper/patients.mapper'
import { ApiPatientResource } from './mapper/patients.model'

export const getPatientByVitalCardInfos = ({
  sex,
  birthDate,
  birthLastName,
  birthFirstName,
  inseeNumber,
}: Partial<Patient>) =>
  getApi()
    .get<ApiPatientResource>(`/patients/find`, {
      sex: sex ?? Sex.UNKNOWN,
      birthDate,
      birthLastName,
      birthFirstName,
      nir: inseeNumber,
    })
    .then(deserializePatientResponse)
