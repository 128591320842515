import { connect } from 'react-redux'
import { RootState } from '../../../store'

import { EditQuestionPanelStateProps } from './EditQuestionPanel.model'

import { EditQuestionPanel } from './EditQuestionPanel.component'
import { editorFontFamilyPreferencesSelector } from '../../../store/domain/me'

const mapStateToProps = (state: RootState): EditQuestionPanelStateProps => ({
  editorFontFamily: editorFontFamilyPreferencesSelector(state),
})

const withConnect = connect(mapStateToProps, {})(EditQuestionPanel)

export { withConnect as EditQuestionPanel }
