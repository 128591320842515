import { restuxTagsApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { TagType } from '../../../model/Tag'

const {
  actions: tagsActions,
  reducers: tagsReducers,
  sagas: tagsSagas,
} = restuxCacheFactory<TagType, TagType>({
  apiConfig: restuxTagsApiConfig,
  refetchList: true,
  resourceName: RestuxResources.tag,
})

export { tagsActions, tagsReducers, tagsSagas }
