import { Editor, Node, SchemaProperties, SlateError, Value, ValueJSON } from 'slate'
import { FontFamily, FontSize } from '../FormatingPlugin/Toolbar/Toolbar.model'
import { VariableType } from '../../WithVariableEditor'

export type JSONValue = string | number | boolean | JSONObject | JSONArray

interface JSONObject {
  [x: string]: JSONValue
}

interface JSONArray extends Array<JSONValue> {}

export { Value as EditorValue }
export { ValueJSON as EditorValueJSON }

export type VariableContext = Record<string, JSONValue>

export type VariableProperties = {
  idvariable: string
  variableContext: VariableContext
  [property: string]: JSONValue
}

export interface VariableModel {
  id: string
  value?: VariableType
  context: VariableContext
}
export type RenderVariableDisplayMode = 'block' | 'inline' | 'none'

export interface VariableFixedValue {
  content: string
  isFallback: boolean
  renderer?: string
}

export const InvalidEditorEmptyJsonValue: ValueJSON = {
  document: {
    nodes: [],
    data: [],
  },
}

export const getEditorEmptyJsonValue = (
  fontFamily: FontFamily = 'Montserrat',
  fontSize: FontSize = 12,
  textContent = '',
): ValueJSON => ({
  object: 'value',
  document: {
    object: 'document',
    data: {},
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: { fontFamily, fontSize },
        nodes: [
          {
            object: 'text',
            text: textContent,
            marks: [],
          },
        ],
      },
    ],
  },
})

function validateNonEmptyString(value?: any): boolean {
  return value && typeof value === 'string' && value.length > 0
}

function validateNumber(value?: any): boolean {
  return value && typeof value === 'number'
}

export type BlockAlignment = 'left' | 'center' | 'right'

export enum NodeType {
  TITLE1 = 'title1',
  TITLE2 = 'title2',
  TITLE3 = 'title3',
  PARAGRAPH = 'paragraph',
  LIST = 'list',
  LIST_ITEM = 'list_item',
  NUMBERED_LIST = 'numbered_list',
  VARIABLE = 'variable',
  IMAGE = 'image',
  PAGE_BREAK = 'page-break',
}

export enum MarkType {
  BOLD = 'bold',
  CODE = 'code',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
}

export enum EditorCommands {
  INSERT_VARIABLE = 'insertVariable',
  IMAGE_FROM_URL = 'imageFromUrl',
}

export type EditorMode = 'edit' | 'preview' | 'print'

export const schema: SchemaProperties = {
  document: {
    nodes: [
      {
        match: [
          { type: NodeType.TITLE1 },
          { type: NodeType.TITLE2 },
          { type: NodeType.TITLE3 },
          { type: NodeType.PARAGRAPH },
          { type: NodeType.LIST },
          { type: NodeType.LIST_ITEM },
          { type: NodeType.NUMBERED_LIST },
          { type: NodeType.VARIABLE },
          { type: NodeType.IMAGE },
          { type: NodeType.PAGE_BREAK },
        ],
        min: 1,
      },
    ],
    normalize(editor: Editor, error: SlateError) {
      if (error.code === 'child_type_invalid' || error.code === 'child_object_invalid') {
        console.error(
          `Invalid type "${error.child.get(
            'type',
          )}". Look at editor schema, maybe you should add type "${error.child.get('type')}`,
          { error },
        )
        editor.setNodeByKey(error.child.key, { type: NodeType.PARAGRAPH })
      } else if (error.code === 'child_min_invalid') {
        const node = {
          object: 'block',
          type: NodeType.PARAGRAPH,
        } as unknown as Node
        editor.insertNodeByKey(error.node.key, error.index, node)
      } else {
        console.error('[ERROR] ', { error })
      }
    },
  },
  inlines: {
    [NodeType.VARIABLE]: {
      isVoid: true,
    },
  },
  blocks: {
    [NodeType.TITLE1]: {
      nodes: [
        {
          match: [{ type: NodeType.VARIABLE }, { object: 'text' }],
        },
      ],
    },
    [NodeType.TITLE2]: {
      nodes: [
        {
          match: [{ type: NodeType.VARIABLE }, { object: 'text' }],
        },
      ],
    },
    [NodeType.TITLE3]: {
      nodes: [
        {
          match: [{ type: NodeType.VARIABLE }, { object: 'text' }],
        },
      ],
    },

    [NodeType.PARAGRAPH]: {
      nodes: [
        {
          match: [
            { type: NodeType.VARIABLE },
            { type: NodeType.IMAGE },
            { type: NodeType.PAGE_BREAK },
            { type: NodeType.LIST },
            { type: NodeType.NUMBERED_LIST },
            { object: 'text' },
          ],
        },
      ],
    },

    [NodeType.LIST]: {
      nodes: [
        {
          match: [{ type: NodeType.LIST_ITEM }],
        },
      ],
      normalize(editor: Editor, error: SlateError) {
        if (error.code === 'child_type_invalid') {
          console.error(
            `Invalid child type "${error.child.get('type')}" for node "${NodeType.LIST}"`,
            { error },
          )
          editor.setNodeByKey(error.child.key, { type: NodeType.LIST_ITEM })
        }
      },
    },

    [NodeType.NUMBERED_LIST]: {
      nodes: [
        {
          match: [{ type: NodeType.LIST_ITEM }],
        },
      ],
      normalize(editor: Editor, error: SlateError) {
        if (error.code === 'child_type_invalid') {
          console.error(
            `Invalid child type "${error.child.get('type')}" for node "${NodeType.NUMBERED_LIST}"`,
            { error },
          )
          editor.setNodeByKey(error.child.key, { type: NodeType.LIST_ITEM })
        }
      },
    },

    [NodeType.LIST_ITEM]: {
      nodes: [
        {
          match: [
            { object: 'text' },
            {
              object: 'inline',
            },
            { type: NodeType.VARIABLE },
          ],
        },
      ],
      normalize(editor: Editor, error: SlateError) {
        if (error.code === 'child_type_invalid' || error.code === 'child_object_invalid') {
          console.error(
            `Invalid child type "${error.child.get('type')}" for node "${NodeType.LIST_ITEM}"`,
            { error },
          )
          // Si le type n'est pas compatible => on l'enlève pour ne mettre que du simple texte
          // Cela permet entre autre de supporter le html en provenance des éditeurs de textes
          editor.unwrapNodeByKey(error.child.key)
        }
      },
    },

    [NodeType.IMAGE]: {
      isVoid: true,
      data: {
        url: validateNonEmptyString,
      },
    },
    [NodeType.PAGE_BREAK]: {
      isVoid: true,
    },
    [NodeType.VARIABLE]: {
      isVoid: true,
      data: {
        idvariable: validateNumber,
      },
    },
  },
}

export const DEFAULT_NODE_TYPE = NodeType.PARAGRAPH

export enum VariableInsertionPosition {
  START = 'START',
  END = 'END',
}
