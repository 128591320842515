import { deserializeQuestion } from '@follow/cdk'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'
import { QuestionInstance } from '../../../../model/Questionnaire'
import {
  QuestionInstanceCreationResource,
  QuestionInstanceResource,
} from './QuestionInstances.model'

export { deserializeQuestion }

export function deserializeQuestionInstances(
  questions: QuestionInstanceResource[],
): QuestionInstance[] {
  return questions.map(deserializeQuestion)
}

export function serializeQuestionInstance({
  answers,
  parentAnswerId,
  ...restProps
}: Partial<QuestionInstance>): Partial<QuestionInstanceCreationResource> {
  return {
    ...restProps,
    parentAnswerId: parentAnswerId,
    answers: answers && answers.map(({ id }) => id),
  }
}

export function deserializeQuestionInstanceResponse(
  response: ApiResponse<QuestionInstanceResource>,
): ApiResponse<QuestionInstance> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestion(data),
  }
}

export function deserializeQuestionInstancesPageResponse(
  questionsPage: ApiResponse<PaginatedList<QuestionInstanceResource>>,
) {
  const { data, ...responseRest } = questionsPage
  // Error case => no deserialization
  if (!questionsPage.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: deserializeQuestionInstances(data.items) },
  }
}
