import { connect } from 'react-redux'

import { RootState } from '../../../store'
import { PatientsList } from './PatientsList.component'

import { PatientsListStoreProps } from './PatientsList.model'
import {
  currentUserSelector,
  loggedUserSelector,
  userMeTeamsSelector,
} from '../../../store/domain/me'

const mapStateToProps = (state: RootState): PatientsListStoreProps => ({
  userTeams: userMeTeamsSelector(state),
  currentUser: currentUserSelector(state),
  loggedUser: loggedUserSelector(state),
})

const withConnect = connect(mapStateToProps)(PatientsList)
export { withConnect as PatientsList }
