import { useMemo } from 'react'
import { Features } from '../../../model/Preference'
import { useUserPreference } from './useUserPreference.hook'

type Options = Partial<{
  user: 'current' | 'logged' | 'usurped'
}>

export const useUserEnabledFeature = <
  Key extends keyof Features,
  Feat extends Features[Key] = Features[Key],
>(
  featureName: Key,
  options?: Options,
): Feat | undefined => {
  const features = useUserPreference('doctor', 'enabledFeatures', options)

  const feature = useMemo<Feat | undefined>(() => {
    if (!features) return undefined

    return features[featureName] as Feat | undefined
  }, [featureName, features])

  return feature
}
