import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Question } from '../../../model/Questionnaire'
import { UserFriendlyError } from '../../../model/Error'
import {
  addImportantInformation,
  removeImportantInformation,
} from '../../../data/importantInformations'
import { questionnaireTemplatesKeys } from '../questionnaireTemplates/questionnaireTemplates.keys'
import { questionTemplatesKeys } from '../questionTemplates/questionTemplate.keys'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'

interface ToggleImportantInformationMutateVariable {
  toggle: boolean
  questionId: number
  questionnaireId?: number
  onSuccessCallback?: () => void
}

export const useToggleImportantInformation = () => {
  const queryClient = useQueryClient()

  return useMutation<Question | void, Error, ToggleImportantInformationMutateVariable>(
    async ({ toggle, questionId }) => {
      const { data, ok } = toggle
        ? await addImportantInformation(questionId)
        : await removeImportantInformation(questionId)

      if (!ok) {
        throw new UserFriendlyError('error', `Le mise à jour de l'information importante a échoué`)
      }

      return data
    },
    {
      onSuccess: (_, { questionId, questionnaireId }) => {
        queryClient.invalidateQueries(questionTemplatesKeys.detail(questionId))
        queryClient.invalidateQueries(patientImportantInformationsKeys.lists)

        if (questionnaireId) {
          queryClient.invalidateQueries(questionnaireTemplatesKeys.detail(questionnaireId))
        } else {
          queryClient.invalidateQueries(questionnaireTemplatesKeys.all)
        }
      },
    },
  )
}
