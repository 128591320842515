import { AnswerTemplateResource, AnswerTemplateSerializedResource } from './answerTemplate.model'
import { parseToValueJSON } from '../../../misc/editor.utilities'
import {
  deserializeQuestionTemplates,
  serializeQuestionTemplate,
} from '../../questionTemplates/mapper/questionTemplates.mapper'
import { ApiResponse } from 'apisauce'
import { QuestionTemplateResource } from '../../questionTemplates/mapper/questionTemplates.model'
import { AnswerTemplate } from '../../../model/Questionnaire'

export function deserializeAnswerTemplate(answerTemplate: AnswerTemplateResource): AnswerTemplate {
  return {
    ...answerTemplate,
    textOutput: answerTemplate.textOutput ? parseToValueJSON(answerTemplate.textOutput) : undefined,
    childQuestions: answerTemplate.childQuestions
      ? deserializeQuestionTemplates(answerTemplate.childQuestions)
      : undefined,
  }
}

export function deserializeAnswerTemplateResponse(
  response: ApiResponse<AnswerTemplateResource>,
): ApiResponse<AnswerTemplate> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeAnswerTemplate(data),
  }
}

export function serializeAnswerTemplate(
  answerTemplate: Partial<AnswerTemplate>,
): Partial<AnswerTemplateSerializedResource> {
  const { imageFile, ...values } = answerTemplate
  return {
    ...values,
    textOutput:
      answerTemplate.textOutput === null ? null : JSON.stringify(answerTemplate.textOutput), // valeur "null" sert à supprimer le texte géneré de l'API
    imageFileId: imageFile ? imageFile.id : imageFile,
    childQuestions: answerTemplate.childQuestions
      ? (answerTemplate.childQuestions.map(
          serializeQuestionTemplate,
        ) as unknown as QuestionTemplateResource[])
      : undefined,
  }
}
