import React, { FunctionComponent, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { Spring } from 'react-spring/renderprops'
import { withWidthLowerObserver } from '../../../../misc/WidthObserver.hoc'
import { breakMedium } from '../../../../misc/responsive'

import { Chip } from '../Chip/Chip.component'
import { IconChip } from '../IconChip'
import { MenuChipProps } from './MenuChip.model'

import styles from './MenuChip.module.scss'
import { Theme as ButtonThemes } from '../../../../design-system/themes/button.model'
import { IconButton } from '../../buttons'
import { GridLayout } from '../../layout'

const stylesCx = classNames.bind(styles)

const MenuChipComponent: FunctionComponent<MenuChipProps> = ({
  icon,
  label,
  collapsed,
  colorPreset = 'dark',
  borderColor,
  onView,
  onPrint,
  onSendToDMP,
  onDeleteFromDMP,
  onDelete,
  onTitleSave,
  onLock,
  onConfigure,
  onRenewal,
  downloadConfig,
  isLowerThanBreakpoint,
  isLock,
  disablePrintDownload,
  disableLocker,
  ...restProps
}) => {
  const [title, setTitle] = useState(label)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setTitle(label)
  }, [label])

  function handleSaveTitle() {
    setEditMode(false)
    if (onTitleSave) {
      onTitleSave(title)
    }
  }

  const actionsClasses = stylesCx({
    actions: true,
    [colorPreset]: true,
  })
  const iconButtonTheme: ButtonThemes = colorPreset === 'dark' ? 'light' : 'transparent-dark'

  return (
    <div className={styles.container}>
      <div className={styles.chipContainer}>
        {icon && (
          <IconChip
            icon={icon}
            label={title}
            colorPreset={colorPreset}
            borderColor={borderColor}
            editMode={editMode}
            onTitleEdit={setTitle}
            onTitleSave={handleSaveTitle}
            {...restProps}
          />
        )}
        {!icon && <Chip colorPreset={colorPreset} label={title} {...restProps} />}
      </div>
      <Spring
        from={{ width: collapsed ? 'auto' : 0, opacity: collapsed ? 1 : 0 }}
        to={{ width: collapsed ? 0 : 'auto', opacity: collapsed ? 0 : 1 }}
      >
        {(springProps) => (
          <div
            className={stylesCx(actionsClasses, {
              [styles.collapsed]: springProps.width === 0,
            })}
            style={springProps}
          >
            <GridLayout
              columnsTemplate="auto"
              padding={['none', 'extraSmall']}
              gap="extraSmall"
              shouldShrinkOnMobile={false}
              flow="column"
              height="100%"
              alignColumns="center"
            >
              {onView && (
                <IconButton
                  icon="eye"
                  title="Visualiser"
                  onClick={onView}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                />
              )}

              {onPrint && (
                <IconButton
                  icon="print"
                  title="Imprimer (Ctrl+P)"
                  onClick={onPrint}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                  disabled={disablePrintDownload}
                />
              )}

              {downloadConfig && (
                <IconButton
                  icon="save"
                  title="Télécharger (Ctrl+Shift+S)"
                  onClick={Array.isArray(downloadConfig) ? undefined : downloadConfig}
                  options={Array.isArray(downloadConfig) ? downloadConfig : undefined}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                  disabled={disablePrintDownload}
                />
              )}

              {onSendToDMP && (
                <IconButton
                  icon={onDeleteFromDMP ? 'dmpCheck' : 'dmpCross'}
                  title="Dossier médical partagé"
                  options={
                    onDeleteFromDMP
                      ? [
                          { label: 'Valider et mettre à jour', onClick: onSendToDMP },
                          { label: 'Supprimer du DMP', onClick: onDeleteFromDMP },
                        ]
                      : [{ label: 'Valider et envoyer', onClick: onSendToDMP }]
                  }
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'micro' : 'normal'}
                  noBorder
                />
              )}

              {onLock && (
                <IconButton
                  icon={isLock ? 'lock' : 'unlock'}
                  title={
                    (disableLocker && 'Déverrouillage impossible') ||
                    (isLock && 'Cliquer pour déverrouiller') ||
                    'Cliquer pour verrouiller'
                  }
                  onClick={onLock}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                  disabled={disableLocker}
                />
              )}

              {onTitleSave && (
                <IconButton
                  icon="pencil"
                  title="Editer"
                  onClick={() => setEditMode(true)}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                />
              )}

              {onRenewal && (
                <IconButton
                  icon="arrowCircle"
                  title="Renouveller l'ordonnance"
                  onClick={onRenewal}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                />
              )}

              {onConfigure && (
                <IconButton
                  icon="settings"
                  title="Configurer"
                  onClick={onConfigure}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                />
              )}

              {onDelete && (
                <IconButton
                  icon="trash"
                  title="Supprimer"
                  onClick={onDelete}
                  theme={iconButtonTheme}
                  size={isLowerThanBreakpoint ? 'nano' : 'micro'}
                  noBorder
                />
              )}
            </GridLayout>
          </div>
        )}
      </Spring>
    </div>
  )
}

export const MenuChip = withWidthLowerObserver(breakMedium)(MenuChipComponent)
