import FontFamilyPlugin, { FontFamilyOptions } from '@tiptap/extension-font-family'
import { FONT_FAMILIES, FontFamily } from '@/model/Font'
import { isDefined } from '@/utils/functions.utils'

type FontFamilyExtendedOptions = FontFamilyOptions & {
  defaultFontFamily: FontFamily
  fontFamilies: ReadonlyArray<FontFamily>
}

export const FontFamilyExtended = FontFamilyPlugin.extend<FontFamilyExtendedOptions>({
  addOptions(): FontFamilyExtendedOptions {
    return {
      ...this.parent?.(),
      types: ['textStyle', 'paragraph'],
      fontFamilies: FONT_FAMILIES,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            parseHTML: (element) => element.style.fontFamily?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              const fontFamily = attributes['fontFamily']

              if (this.options.fontFamilies.includes(fontFamily)) {
                return {
                  style: `font-family: ${fontFamily}`,
                }
              }

              if (isDefined(this.options.defaultFontFamily)) {
                return {
                  style: `font-family: ${this.options.defaultFontFamily}`,
                }
              }

              return {}
            },
          },
        },
      },
    ]
  },
})
