import { TopBarProps } from './TopBar.model'
import styles from './TopBar.module.scss'
import classNames from 'classnames/bind'
import { IconButton, Button } from '../../../buttons'
import { Icon } from '../../../Icon'
import { Colors } from '../../../../../colors'
import { TooltipWrapper } from '../../../tooltip'

const cx = classNames.bind(styles)

export const TopBar = ({
  onRequestClose,
  onRequestExpand,
  maximized,
  title,
  actions,
  hidden,
  displayCloseButton,
  hint,
  hintTooltip,
}: TopBarProps) => (
  <div className={cx(styles.topBar, { hidden, maximized })}>
    <div className={styles.defaultActionsDesktop}>
      {displayCloseButton && (
        <IconButton
          icon="cross"
          theme="transparent"
          size="normal"
          title="Fermer le volet"
          testId="button-bottom_panel-close"
          onClick={onRequestClose}
        />
      )}
      <IconButton
        icon="caret"
        theme="transparent"
        size="normal"
        testId="button-bottom_panel-toggle"
        title={maximized ? 'Réduire la taille du volet' : 'Augmenter la taille du volet'}
        rotate={maximized ? 180 : 0}
        onClick={onRequestExpand}
      />
    </div>
    <div className={styles.defaultActionsMobile}>
      <Button
        testId="button-bottom_panel-cancel"
        theme="dark"
        label="Annuler"
        onClick={() => onRequestClose()}
      />
    </div>

    <div className={styles.titleWrapper}>
      <div className={styles.title}>
        <div data-test-id="title-bottom_panel">{title}</div>
      </div>
      {hint && (
        <div className={styles.hintWrapper}>
          <div className={styles.message}>{hint}</div>
          <TooltipWrapper
            display={!!hintTooltip}
            content={<div className={styles.tooltip}>{hintTooltip}</div>}
            size="small"
            pointerDirection="top"
          >
            <div className={styles.icon}>
              <Icon size="micro" icon={'infoCircle'} color={Colors.dsWhite} />
            </div>
          </TooltipWrapper>
        </div>
      )}
    </div>
    <div className={styles.actions} data-test-id="title-bottom_panel-actions">
      {actions}
    </div>
  </div>
)
