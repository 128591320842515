import {
  QuestionTemplateCreationResource,
  QuestionTemplateResource,
} from './questionTemplates.model'
import { QuestionnaireTemplate, QuestionTemplate } from '../../../model/Questionnaire'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../model/Pagination'
import { deserializeAnswerTemplate } from '../../answerTemplates/mapper/answerTemplate.mapper'
import { QuestionnaireTemplateResource } from '../../questionnaireTemplates/mapper/questionnaireTemplate.model'
import { deserializeQuestionnaireTemplate } from '../../questionnaireTemplates/mapper/questionnaireTemplate.mapper'

export function deserializeQuestionTemplate(
  questionTemplate: QuestionTemplateResource,
): QuestionTemplate {
  return {
    ...questionTemplate,
    answers: questionTemplate.answers.map(deserializeAnswerTemplate),
  }
}

export function deserializeQuestionTemplates(
  questionTemplates: QuestionTemplateResource[],
): QuestionTemplate[] {
  return questionTemplates.map(deserializeQuestionTemplate)
}

export function serializeQuestionTemplate({
  answers,
  parentAnswerId,
  ...restProps
}: Partial<QuestionTemplate>): Partial<QuestionTemplateCreationResource> {
  return {
    ...restProps,
    parentAnswerId: parentAnswerId,
    answers: answers && answers.map(({ id }) => id),
  }
}

export function deserializeQuestionTemplateResponse(
  response: ApiResponse<QuestionTemplateResource>,
): ApiResponse<QuestionTemplate> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestionTemplate(data),
  }
}

export function deserializeQuestionTemplatesPageResponse(
  questionTemplatesPage: ApiResponse<PaginatedList<QuestionTemplateResource>>,
) {
  const { data, ...responseRest } = questionTemplatesPage
  // Error case => no deserialization
  if (!questionTemplatesPage.ok || !data) {
    return { ...responseRest, data: null }
  }
  return {
    ...responseRest,
    data: { ...data, items: deserializeQuestionTemplates(data.items) },
  }
}

export function deserializeQuestionnaireTemplates(
  questionnaires: ReadonlyArray<QuestionnaireTemplateResource>,
): QuestionnaireTemplate[] {
  return questionnaires.map((questionnaire) => deserializeQuestionnaireTemplate(questionnaire))
}
