import React from 'react'

import classNames from 'classnames'
import { SwitchProps } from './Switch.model'
import BaseThemes from '../../../../design-system/themes/base.theme'
import { isDefined } from '../../../../misc/functions.utilities'
import { Icon } from '../../Icon'
import { getCssVariable } from '../../../../design-system/utils'

export const Switch = React.forwardRef<HTMLDivElement, SwitchProps>(
  (
    {
      label,
      name,
      theme = 'primary',
      checked = false,
      disabled = false,
      infos,
      showIcon,
      onChange,
      borderBottom = false,
    },
    ref,
  ) => {
    const status = disabled ? 'disabled' : 'active'
    const backgroundColor =
      checked || disabled ? BaseThemes[theme][status].colors.backgroundColor : 'bg-gray-300'

    return (
      <div ref={ref} className={classNames({ 'pb-2': !!infos })}>
        <div className={classNames({ 'border-b pb-2 px-2': borderBottom })}>
          {label && <span className="mr-4 text-xs text-shades-4">{label}</span>}
          <div
            className={classNames('inline-flex items-center justify-between', {
              'w-full': isDefined(name),
            })}
          >
            {name && (
              <span className={classNames('mr-4', { 'text-shades-5': disabled })}>{name}</span>
            )}
            <div
              onClick={() => !disabled && onChange(!checked)}
              className={classNames(
                backgroundColor,
                { 'cursor-pointer': !disabled },
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              )}
            >
              <div
                className={classNames(
                  checked ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                )}
              >
                <span
                  className={classNames(
                    checked
                      ? 'opacity-0 ease-out duration-100'
                      : 'opacity-100 ease-in duration-200',
                    'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                  )}
                  aria-hidden="true"
                >
                  {showIcon && (
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </span>
                <span
                  className={classNames(
                    checked
                      ? 'opacity-100 ease-in duration-200'
                      : 'opacity-0 ease-out duration-100',
                    'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                  )}
                  aria-hidden="true"
                >
                  {showIcon && (
                    <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {infos && (
          <div className="text-xs mt-2 inline-flex items-center space-x-1">
            <Icon icon="infoCircle" color={getCssVariable('shades', 'shade4')} size="pico" />
            <span>{infos}</span>
          </div>
        )}
      </div>
    )
  },
)
