import { put, call, takeEvery, select } from 'redux-saga/effects'
import { patientCurrentTreatmentsActions } from '../../cache/patientCurrentTreatments'
import { addError } from '../../message'
import { uiSagasFactory } from '../../restux/ui/restuxUiSagas.factory'
import { uiPatientCurrentTreatmentsActions } from './patientCurrentTreatments.actions'
import {
  patientCurrentTreatmentsConfig,
  UiPatientCurrentTreatmentsActionTypes,
} from './patientCurrentTreatments.model'
import { documentInstancesActions } from '../../cache/documentInstances'
import { medicalEventsActions } from '../../cache/medicalEvents'
import { setBottomPanelOptions } from '../bottomPanel'
import { batchPosology } from '../../domain/posology/api'
import { postCurrentTreatment, terminateTreatment } from '../../cache/patientCurrentTreatments/api'
import { TreatmentsStatus } from '../../../model/Treatment'
import { inUseMedicalEventPrescriptionSelector } from '../../domain/medicalEvents'
import { Prescription } from '../../../model/Prescription'
import { medicalEventUiActions } from '../medicalEvents/medicalEvents.actions'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { isDefined } from '../../../misc/functions.utilities'
import { documentAlertsDomainActions } from '../../domain/documentAlerts'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'
import { queryClient } from '../../../App'
import { patientTreatmentsKeys } from '../../../hooks/queries/patientTreatments/patientTreatments.keys'

const paginationSagas = uiSagasFactory(patientCurrentTreatmentsConfig)

function* getCurrentTreatmentsWorker() {
  const currentPatientId = getCurrentPatientId()
  if (currentPatientId) {
    yield put(
      patientCurrentTreatmentsActions.actions.getPaginatedItems({
        page: { currentPage: 1, pageSize: 100 },
        params: { patientId: `${currentPatientId}` },
        filters: { status: TreatmentsStatus.CURRENT },
      }),
    )
    queryClient.removeQueries(patientTreatmentsKeys.lists(currentPatientId.toString()))
  }
}

function* getCurrentTreatmentsWatcher() {
  yield takeEvery(
    UiPatientCurrentTreatmentsActionTypes.GET_CURRENTS_TREATMENTS,
    getCurrentTreatmentsWorker,
  )
}

function* createCurrentTreatmentWorker({
  treatmentForm,
}: ReturnType<typeof uiPatientCurrentTreatmentsActions.createCurrentTreatment>) {
  const currentPatientId = getCurrentPatientId()
  if (currentPatientId !== null) {
    const response = yield call(postCurrentTreatment, currentPatientId, treatmentForm)
    if (response.ok) {
      yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
      yield put(
        setBottomPanelOptions({
          open: false,
        }),
      )
      queryClient.invalidateQueries(patientTreatmentsKeys.lists(currentPatientId.toString()))
    } else {
      yield put(
        addError(
          'Échec de la sauvegarde',
          "Le traitement n'a pas pu être enregistré, vérifiez votre saisie.",
        ),
      )
    }
  }
}

function* createCurrentTreatmentWatcher() {
  yield takeEvery(
    UiPatientCurrentTreatmentsActionTypes.CREATE_CURRENTS_TREATMENTS,
    createCurrentTreatmentWorker,
  )
}

function* updateCurrentTreatmentWorker({
  treatmentForm,
  prescriptionUuid,
}: ReturnType<typeof uiPatientCurrentTreatmentsActions.updateCurrentTreatment>) {
  const { posologyIntervals } = treatmentForm
  const currentPatientId = getCurrentPatientId()
  if (posologyIntervals.length) {
    if (prescriptionUuid) {
      const response = yield call(
        batchPosology,
        {
          posologyIntervals: posologyIntervals,
        },
        prescriptionUuid,
      )
      if (response.ok) {
        yield put(
          setBottomPanelOptions({
            open: false,
          }),
        )
        yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
        if (currentPatientId) {
          queryClient.invalidateQueries(patientTreatmentsKeys.lists(currentPatientId.toString()))
        } else {
          queryClient.invalidateQueries(patientTreatmentsKeys.all)
        }
      } else {
        yield put(addError('Erreur pendant la sauvegarde', 'La mise à jour du traitement a échoué'))
      }
    }
  }
}

function* updateCurrentTreatmentWatcher() {
  yield takeEvery(
    UiPatientCurrentTreatmentsActionTypes.UPDATE_CURRENTS_TREATMENTS,
    updateCurrentTreatmentWorker,
  )
}

/* 
 Refresh traitements en cours sur : 
 - MàJ document
 - Suppression document
 - Supression medical event
 - Suppression traitement manuel

 Également fait à la duplication de documents, voir la saga liée (car event non catchable via un watcher externe).
*/
function* updateCurrentTreatmentsOnDrugsUpdate() {
  yield takeEvery(
    [
      documentInstancesActions.types.STORE_DELETE_ITEM,
      medicalEventsActions.types.STORE_DELETE_ITEM,
      patientCurrentTreatmentsActions.types.STORE_DELETE_ITEM,
    ],
    getCurrentTreatmentsWorker,
  )
}

function* terminateTreatmentWorker({
  treatment,
  endAt,
}: ReturnType<typeof uiPatientCurrentTreatmentsActions.terminateTreatment>) {
  const response = yield call(terminateTreatment, treatment.drugConfig.prescriptionUuid, endAt)
  if (response.ok) {
    yield put(uiPatientCurrentTreatmentsActions.getCurrentTreatments())
    // Refetch des alertes documents car les traitements du patient ont changés
    yield put(documentAlertsDomainActions.getAlerts())

    // Si on est sur la posologie de la prescription, on redirige vers l'éditeur pour assurer la cohérence des données
    const inUsePrescription: Prescription | null = yield select(
      inUseMedicalEventPrescriptionSelector,
    )
    if (
      treatment.drugConfig.prescriptionUuid === inUsePrescription?.uuid &&
      isDefined(treatment.documentId)
    ) {
      yield put(
        medicalEventUiActions.selectMedicalEventDocument({
          medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
          id: treatment.documentId,
        }),
      )
    }
    const currentPatientId = getCurrentPatientId()
    if (currentPatientId) {
      queryClient.removeQueries(patientTreatmentsKeys.lists(currentPatientId.toString()))
    } else {
      queryClient.removeQueries(patientTreatmentsKeys.all)
    }
  } else {
    yield put(addError("Une erreur s'est produite", "Le traitement n'a pas pu être mis à jour."))
  }
}

function* terminateTreatmentWatcher() {
  yield takeEvery(
    UiPatientCurrentTreatmentsActionTypes.TERMINATE_TREATMENT,
    terminateTreatmentWorker,
  )
}

export const patientCurrentTreatmentsSagas = {
  ...paginationSagas,
  getCurrentTreatmentsWatcher,
  createCurrentTreatmentWatcher,
  updateCurrentTreatmentWatcher,
  updateCurrentTreatmentsOnDrugsUpdate,
  terminateTreatmentWatcher,
}
