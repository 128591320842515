import { FunctionComponent } from 'react'
import { QuestionType, BaseQuestion, Question } from '../../../model/Questionnaire'
import { QuestionList, QuestionnaireMode, QuestionnaireAddQuestion } from '..'
import { QuestionnaireContentProps } from './QuestionnaireContent.model'
import { createDefaultQuestionTemplate } from '../../../model/QuestionnaireTemplate'

export const QuestionnaireContent: FunctionComponent<QuestionnaireContentProps> = ({
  questionnaireTemplate,
  onEditQuestion,
  onChangeQuestionImportance,
  moveQuestion,
  createQuestion,
  onDeleteQuestion,
  onDeleteSubQuestion,
}) => {
  const handleAddQuestion = (type: QuestionType) => {
    createQuestion(
      createDefaultQuestionTemplate(type, {
        questionnaireTemplateId: questionnaireTemplate.id,
        displayOrder: questionnaireTemplate.questions.length + 1,
      }),
    )
  }

  const handleAddSubQuestion = (
    type: QuestionType,
    parentAnswerId: number,
    displayOrder: number,
  ) => {
    createQuestion(
      createDefaultQuestionTemplate(type, {
        questionnaireTemplateId: questionnaireTemplate.id,
        displayOrder,
        parentAnswerId,
      }),
    )
  }

  const handleMoveQuestion = (questionId: number, newIndex: number) => {
    const questionIds = questionnaireTemplate.questions.map(({ id }) => id)
    const previousIndex = questionIds.findIndex((id) => id === questionId)

    const actualDestinationQuestionId = questionIds.find((id, index) => index === newIndex)

    if (!actualDestinationQuestionId) {
      throw new Error('This should never happen. Algorithm is wrong')
    }
    if (previousIndex === newIndex) {
      return
    }

    const newQuestionsIds = questionIds.map((id, index) => {
      if (index === previousIndex) {
        return actualDestinationQuestionId
      } else if (index === newIndex) {
        return questionId
      }
      return id
    })

    moveQuestion(questionnaireTemplate.id, newQuestionsIds)
  }

  const editHandler = questionnaireTemplate.isEditable
    ? (question: BaseQuestion) => onEditQuestion(question.id)
    : undefined
  const changeQuestionImportanceHandler = questionnaireTemplate.isEditable
    ? (question: Question, toggle: boolean) => onChangeQuestionImportance(question, toggle)
    : undefined
  const addSubQuestionHandler = questionnaireTemplate.isEditable ? handleAddSubQuestion : undefined
  const deleteHandler = questionnaireTemplate.isDeletable
    ? (question: BaseQuestion) => onDeleteQuestion(question.id, questionnaireTemplate.id)
    : undefined
  const deleteSubQuestionHandler = questionnaireTemplate.isDeletable
    ? (question: BaseQuestion) => onDeleteSubQuestion(question.id, questionnaireTemplate.id)
    : undefined
  const onMoveQuestion = questionnaireTemplate.isEditable ? handleMoveQuestion : undefined

  return (
    <QuestionList
      mode={QuestionnaireMode.managerEdit}
      questions={questionnaireTemplate.questions}
      onAddSubQuestion={addSubQuestionHandler}
      onDelete={deleteHandler}
      onDeleteSubQuestion={deleteSubQuestionHandler}
      onEdit={editHandler}
      onChangeQuestionImportance={changeQuestionImportanceHandler}
      onMoveQuestion={onMoveQuestion}
      renderAfter={
        questionnaireTemplate.isEditable && (
          <QuestionnaireAddQuestion onAddQuestion={handleAddQuestion} />
        )
      }
      disabled={true}
      isReadonly={!questionnaireTemplate.isEditable}
    />
  )
}
