import React, { FC, useEffect, useMemo } from 'react'
import { useEditor, EditorContent, EditorContext } from '@tiptap/react'
import { SimpleEditorProps } from './SimpleEditor.model'
import { Toolbar } from '../Toolbar'
import { TableBubbleMenu, getCommonPlugins } from '@/plugins'
import { ImageResizeBubbleMenu } from '@/plugins/imageResize/ImageResizeBubbleMenu'
import styles from '../../styles/editor/elfe.module.scss'
import { useDefaultFontFamily, useEditorConfig } from '@/hooks'
import { EditorConfigContext } from '@/context'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryContent } from '@/components/ErrorBoundaryContent'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const SimpleEditor: FC<SimpleEditorProps> = ({
  initialContent,
  disabled,
  tools,
  toolbarStyle,
  themes,
  extraTools,
  editorRef,
  defaultFontFamily,
  authenticatedImageRequest,
  onChange,
}) => {
  const editorConfig = useEditorConfig({ disabled, tools, themes, authenticatedImageRequest })

  const extensions = useMemo(
    () =>
      getCommonPlugins({
        defaultFontFamily,
        tools,
      }),
    [tools, defaultFontFamily],
  )

  const editor = useEditor({
    content: initialContent,
    extensions,
    editable: !editorConfig.disabled,
    ...(onChange && { onUpdate: onChange }),
    editorProps: {
      attributes: {
        class: 'with-ui',
      },
    },
  })

  useEffect(() => {
    if (editorRef) {
      editorRef.current = editor
    }
  }, [editor, editorRef])

  const { style } = useDefaultFontFamily(defaultFontFamily)

  return (
    <div className={cx('elfe-base', 'elfe-container', themes)} style={style}>
      <EditorContext.Provider value={{ editor }}>
        <EditorConfigContext.Provider value={editorConfig}>
          {!editorConfig.disabled && (
            <>
              <Toolbar extraTools={extraTools} toolbarStyle={toolbarStyle} />
              <ImageResizeBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
          <EditorContent editor={editor} className={styles['editor']} />
        </EditorConfigContext.Provider>
      </EditorContext.Provider>
    </div>
  )
}

const SimpleEditorWithBoundary = withErrorBoundary(SimpleEditor, {
  fallbackRender: (props) => <ErrorBoundaryContent {...props} />,
})

export { SimpleEditorWithBoundary as SimpleEditor }
