import { QuestionInstance, QuestionType } from '../../../model/Questionnaire'
import { AnswerValue } from './../../../model/AnswerValue'

function answerValuesFinder(
  values: ReadonlyArray<AnswerValue>,
  question: QuestionInstance,
): AnswerValue[] | AnswerValue | undefined | null {
  // undefined dans le cas du manager et null si aucune réponse en instance
  if (question.type === QuestionType.QCM) {
    const answerValues = values.filter(({ questionId }) => questionId === question.id)
    return answerValues.length > 0 ? answerValues : null
  }
  const answerValue = values.find(({ questionId }) => questionId === question.id)
  if (answerValue && question.type === QuestionType.Slider) {
    answerValue.value =
      typeof answerValue.value === 'string' ? parseInt(answerValue.value, 10) : answerValue.value
  }
  return answerValue ?? null
}

function answerValuesMapper(
  value?: AnswerValue[] | AnswerValue | null,
): string | number | number[] | undefined | null {
  if (Array.isArray(value)) {
    return value.map((answerValue) => answerValue.answerId)
  }
  if (value) {
    return value.answerId || value.value
  }
  return value
}

export function mapAnswerValuesToQuestion(
  values: ReadonlyArray<AnswerValue> | undefined,
  question: QuestionInstance,
): QuestionInstance {
  const answerValues = values ? answerValuesFinder(values, question) : undefined

  // On determine si la réponse est partagée
  const sharedInMedicalEvent = !!(Array.isArray(answerValues) ? answerValues : [answerValues]).find(
    (answerValue) => (answerValue && answerValue.sharedFromAnswerValueId ? true : undefined),
  )

  const answers = question.answers.map((answer) => ({
    ...answer,
    childQuestions: answer.childQuestions
      ? answer.childQuestions.map((childQuestion) =>
          mapAnswerValuesToQuestion(values, childQuestion),
        )
      : undefined,
  }))
  return {
    ...question,
    answerValues: answerValuesMapper(answerValues),
    answers,
    sharedInMedicalEvent,
  }
}
