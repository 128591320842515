import styled from '../../../styles/legacy/styled/styled'
import { LineContainerProps } from './Timeline.model'

export const LineContainer = styled.div<LineContainerProps>`
  width: ${({ invisible = false }) => (invisible ? '0' : '2px')};
  @media ${({ theme }) => theme.mediaQueries.notMobile} {
    margin: 10px auto;
    min-height: 20px;
    height: calc(100% - 20px);
  }
`
