import { ComponentType } from 'react'

import {
  BottomPanelComponentType,
  BottomPanelState,
  setBottomPanelOptions,
  toggleExpandBottomPanel,
} from '../../store/ui/bottomPanel'

import EditContactPanel from '../../containers/Contact/EditContactPanel'
import EditTagPanel from '../../containers/Tag/EditTagPanel'
import EditTeamPanel from '../../containers/Team/EditTeamPanel'
import { SearchQuestionnairesSurvey } from '../../components/questionnaire'
import SearchBaseVariable from '../BottomSearchPanel/SearchBaseVariable'
import SearchDrug from '../BottomSearchPanel/SearchDrug'
import SearchDocumentHistory from '../BottomSearchPanel/SearchDocumentHistory'
import {
  SearchExportColumnDocumentTemplates,
  SearchExportFilterDocumentTemplates,
} from '../../components/exports'
import { MailEditor } from '../../components/mail'
import { ManualPrescriptionBottomPanel, PosologyBottomPanel } from '../../components/drug'
import { DocumentAlertsPanel } from '../../components/document'
import { ResourceHistoryPanel } from '../../components/history'
import { AssistantPermissions } from '../../components/user/AssistantPermissions'
import { EditNotificationPanel } from '../Notification'
import { PublishEphemeralNotificationPanel } from '../EphemeralNotification'
import { SearchDocumentTemplates } from '../../components/medicalEvent'
import { EditLegalDocumentPanel } from '../../components/legal'

export interface BottomPanelWrapperDispatchProps {
  setBottomPanelOptions: typeof setBottomPanelOptions
  toggleExpandBottomPanel: typeof toggleExpandBottomPanel
}

export type BottomPanelWrapperStateProps = BottomPanelState
export type BottomPanelWrapperProps = BottomPanelWrapperStateProps & BottomPanelWrapperDispatchProps

type ComponentTypes = { [code in BottomPanelComponentType]: ComponentType<any> }

export const BOTTOM_PANEL_COMPONENTS: ComponentTypes = {
  [BottomPanelComponentType.SearchBaseVariable]: SearchBaseVariable,
  [BottomPanelComponentType.EditContact]: EditContactPanel,
  [BottomPanelComponentType.EditNotification]: EditNotificationPanel,
  [BottomPanelComponentType.EditLegalDocument]: EditLegalDocumentPanel,
  [BottomPanelComponentType.EditTag]: EditTagPanel,
  [BottomPanelComponentType.EditTeam]: EditTeamPanel,
  [BottomPanelComponentType.SearchDocuments]: SearchDocumentTemplates,
  [BottomPanelComponentType.SearchDocumentHistory]: SearchDocumentHistory,
  [BottomPanelComponentType.SearchDrug]: SearchDrug,
  [BottomPanelComponentType.AddManualPrescription]: ManualPrescriptionBottomPanel,
  [BottomPanelComponentType.SearchQuestionnairesSurvey]: SearchQuestionnairesSurvey,
  [BottomPanelComponentType.SearchDocumentsFilterExport]: SearchExportFilterDocumentTemplates,
  [BottomPanelComponentType.SearchDocumentsColumnExport]: SearchExportColumnDocumentTemplates,
  [BottomPanelComponentType.MailEditor]: MailEditor,
  [BottomPanelComponentType.Posology]: PosologyBottomPanel,
  [BottomPanelComponentType.PublishEphemeralNotification]: PublishEphemeralNotificationPanel,
  [BottomPanelComponentType.DocumentAlerts]: DocumentAlertsPanel,
  [BottomPanelComponentType.ResourceHistory]: ResourceHistoryPanel,
  [BottomPanelComponentType.AssistantPermissions]: AssistantPermissions,
}
