import { put, select, take, takeEvery } from 'redux-saga/effects'
import {
  documentAlertsDomainActions,
  DocumentAlertsDomainActionTypes,
} from '../../domain/documentAlerts'
import { documentAlertsFiltersPreferencesSelector, updatePreferences } from '../../domain/me'
import {
  DocumentAlert,
  DocumentAlertsFiltersPreference,
  NON_SAM_FILTER,
} from '../../../model/DocumentAlerts'
import { uiDocumentAlertsActions } from './documentAlerts.actions'
import { documentAlertsSelector } from '../../domain/documentAlerts/documentAlerts.selectors'
import { uiDocumentAlertsActionTypes } from './documentAlerts.model'
import {
  documentAlertsAcknowledgedSamAlertsSelector,
  documentAlertsDisplayableSamAlertsSelector,
} from './documentAlerts.selector'
import { MedicalEventsUiActionTypes } from '../medicalEvents'

function* filterSamAlertsOnAlertFetchWorker({
  availableFilters,
}: ReturnType<typeof documentAlertsDomainActions.setAvailableFilters>) {
  if (availableFilters) {
    const actualSamTypes = availableFilters.samTypes.filter(({ name }) => name !== NON_SAM_FILTER)
    if (actualSamTypes.length > 0) {
      const preferences: DocumentAlertsFiltersPreference = yield select(
        documentAlertsFiltersPreferencesSelector,
      )
      const displayableSamTypes = actualSamTypes.filter(({ name }) => {
        if (preferences.samTypes) {
          return preferences.samTypes?.some((preferenceName) => name === preferenceName)
        } else return true
      })
      if (displayableSamTypes.length > 0) {
        const alerts: DocumentAlert[] = yield select(documentAlertsSelector)
        const displayableAlerts = alerts.filter(
          ({ type, samType }) =>
            type === 'SAM' && displayableSamTypes.some(({ name }) => samType === name),
        )

        // Filtrage des alertes ayant déjà été affichées
        const acknowledgedSamAlerts: DocumentAlert[] = yield select(
          documentAlertsAcknowledgedSamAlertsSelector,
        )
        const someSamAlertsAreNew = displayableAlerts.some(
          ({ severityLevel, title, samType }) =>
            !acknowledgedSamAlerts.some(
              (acknowledged) =>
                acknowledged.severityLevel === severityLevel &&
                acknowledged.title === title &&
                acknowledged.samType === samType,
            ),
        )

        // Si nouvelle alerte SAM, on réaffiche toutes les alertes SAM (en prenant en compte les préférences)
        if (someSamAlertsAreNew) {
          yield put(uiDocumentAlertsActions.storeDisplayableSamAlerts(displayableAlerts))
        } else {
          yield put(uiDocumentAlertsActions.storeDisplayableSamAlerts([]))
        }
      }
    }
  } else {
    yield put(uiDocumentAlertsActions.clearAlerts())
  }
}

function* filterSamAlertsOnAlertFetchWatcher() {
  yield takeEvery(DocumentAlertsDomainActionTypes.SET_FILTERS, filterSamAlertsOnAlertFetchWorker)
}

function* clearStoredAlertsOnDocumentChangeWorker() {
  yield put(uiDocumentAlertsActions.clearAlerts())
}
function* clearStoredAlertsOnDocumentChangeWatcher() {
  yield takeEvery(
    MedicalEventsUiActionTypes.SELECT_DOCUMENT,
    clearStoredAlertsOnDocumentChangeWorker,
  )
}

function* disableCurrentSamTypesWorker() {
  const preferences: DocumentAlertsFiltersPreference = yield select(
    documentAlertsFiltersPreferencesSelector,
  )

  if (preferences.samTypes === null) {
    yield put(documentAlertsDomainActions.getTypes())
    const { types: alertTypes }: ReturnType<typeof documentAlertsDomainActions.setTypes> =
      yield take(DocumentAlertsDomainActionTypes.SET_TYPES)
    preferences.samTypes = alertTypes.samTypes.map(({ name }) => name)
  }

  if (preferences.samTypes) {
    const currentSamAlerts: DocumentAlert[] = yield select(
      documentAlertsDisplayableSamAlertsSelector,
    )
    const acknowledgedAlerts: DocumentAlert[] = yield select(
      documentAlertsAcknowledgedSamAlertsSelector,
    )
    const updatedSamFilters = [...currentSamAlerts, ...acknowledgedAlerts].reduce(
      (acc, { samType }) => {
        const index = acc.findIndex((value) => value === samType)
        if (index > -1) {
          acc.splice(index, 1)
        }
        return acc
      },
      [...preferences.samTypes],
    )
    yield put(
      updatePreferences({
        documentAlertsFilters: {
          ...preferences,
          samTypes: updatedSamFilters,
        },
      }),
    )
  }
}

function* disableCurrentSamTypesWatcher() {
  yield takeEvery(
    uiDocumentAlertsActionTypes.DISABLE_CURRENT_SAM_TYPES,
    disableCurrentSamTypesWorker,
  )
}

export const uiDocumentAlertsSagas = {
  filterSamAlertsOnAlertFetchWatcher,
  disableCurrentSamTypesWatcher,
  clearStoredAlertsOnDocumentChangeWatcher,
}
