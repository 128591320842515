import { useField } from 'formik'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames/bind'
import { useAddressAutocomplete } from '../../../../../../hooks/utils'
import { Address } from '../../../../../../model/Address'
import { FormikTextInput } from '../../Inputs/FormikTextInput.component'
import styles from '../Fields.module.scss'
import { AddressFieldsProps } from './AddressFields.model'
import { SearchInput } from '../../../../search'
import { SelectOption } from '../../../../../../model/SelectOption'
import { DropdownItem } from '../../../../dropdown'
import { IconsType } from '../../../../Icon'

const cx = classNames.bind(styles)

const isAddressEmpty = (address: Address) => {
  const values = [address.street, address.street2, address.zipCode, address.city]
  return values.every((item) => item === null || item === '')
}

const CREATE_MANUAL_ADDRESS_ID = '-1'

export const AddressFields: FunctionComponent<AddressFieldsProps> = ({
  fieldNamePrefix,
  disabled,
  colorPreset,
  type,
}) => {
  const formatedPrefix = fieldNamePrefix ? `${fieldNamePrefix}.` : ''

  const [field, , helpers] = useField<Address>({ name: `${formatedPrefix}address` })
  const [searchStreetValue, setSearchStreetValue] = useState(field.value.street || '')
  const [showSearchField, setShowSearchField] = useState(isAddressEmpty(field.value))

  const [addressPredictions, addressLoading, { onSelectAddress: onSelectAddressByStreet }] =
    useAddressAutocomplete(searchStreetValue, (address) => {
      helpers.setValue(address)
      helpers.setTouched(true)
      setSearchStreetValue(address.street || '')
      setShowSearchField(false)
    })

  const addressOptions = useMemo<SelectOption<string>[] | undefined>(() => {
    if (addressLoading) {
      return [{ value: '', label: 'Chargement...' }]
    } else if (addressPredictions.length > 0) {
      const predictions = addressPredictions.map(({ place_id, description }) => ({
        value: place_id,
        label: description,
        icon: 'map' as IconsType,
      }))
      return [
        ...predictions,
        { value: CREATE_MANUAL_ADDRESS_ID, label: "Entrer l'adresse manuellement", icon: 'add' },
      ]
    } else {
      return undefined
    }
  }, [addressLoading, addressPredictions])

  const isContact = type === 'contact'

  const renderToggleButton = () => (
    <div className="px-2 py-3 w-full flex justify-end">
      <button
        onClick={() => setShowSearchField(!showSearchField)}
        className="text-xs bg-transparent text-primary-default hover:text-primary-dark"
      >
        {showSearchField ? "Saisir l'adresse manuellement" : "Effectuer une recherche d'adresse"}
      </button>
    </div>
  )

  const handleSelectAddress = (selected: SelectOption<string>) => {
    if (selected.value === CREATE_MANUAL_ADDRESS_ID) {
      helpers.setValue({ ...field.value, street: searchStreetValue })
      setShowSearchField(false)
    } else {
      onSelectAddressByStreet(selected.value)
    }
  }

  useEffect(() => {
    setShowSearchField((showSearch) =>
      showSearch && !isAddressEmpty(field.value) ? false : showSearch,
    )
  }, [field.value])

  return (
    <>
      {showSearchField ? (
        <div>
          <SearchInput
            value={searchStreetValue}
            onChange={(e) => setSearchStreetValue(e.currentTarget.value)}
            icon="search"
            label="Recherche d'adresse"
            placeholder="1 Bd de la Liberté 35000 Rennes"
            disabled={disabled}
            colorPreset={colorPreset}
            results={addressOptions}
            onSelect={handleSelectAddress}
            renderResult={(result, isHovered) => (
              <DropdownItem icon={result?.icon} selected={isHovered}>
                <div className="max-w-full whitespace-normal">{result.label}</div>
              </DropdownItem>
            )}
          />
          {renderToggleButton()}
        </div>
      ) : (
        <>
          {type === 'doctor' && (
            <FormikTextInput
              fieldName={`${formatedPrefix}name`}
              label="Nom du lieu"
              autocomplete={false}
              disabled={disabled}
              colorPreset={colorPreset}
            />
          )}
          <FormikTextInput
            fieldName={`${formatedPrefix}address.street`}
            label="Rue"
            autocomplete={false}
            disabled={disabled}
            colorPreset={colorPreset}
          />
          <div className={styles.inputSpacing}>
            <FormikTextInput
              fieldName={`${formatedPrefix}address.street2`}
              label="Complément d'adresse"
              autocomplete={false}
              disabled={disabled}
              colorPreset={colorPreset}
            />
          </div>
          <div className="flex flex-row justify-between">
            <div className={cx(styles.zipcode, { inputSpacing: isContact })}>
              <FormikTextInput
                fieldName={`${formatedPrefix}address.zipCode`}
                label="Code Postal"
                autocomplete={false}
                disabled={disabled}
                colorPreset={colorPreset}
              />
            </div>
            <div className={cx(styles.city, { inputSpacing: isContact })}>
              <FormikTextInput
                fieldName={`${formatedPrefix}address.city`}
                label="Ville"
                autocomplete={false}
                disabled={disabled}
                colorPreset={colorPreset}
              />
            </div>
          </div>
          <div className={cx({ inputSpacing: isContact })}>
            <FormikTextInput
              fieldName={`${formatedPrefix}address.country`}
              label="Pays"
              autocomplete={false}
              disabled={disabled}
              colorPreset={colorPreset}
            />
            {renderToggleButton()}
          </div>
        </>
      )}
    </>
  )
}
