import { useCallback, useContext, useMemo } from 'react'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { getTeams } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { getNextPageParam } from '../../../../misc/query.utilities'
import { isAdmin } from '../../../../misc/user.utilities'
import { PaginatedList } from '../../../../model/Pagination'
import { Team } from '../../../../model/Team'
import { usePaginationState } from '../../../utils/query'
import { useFlatPaginatedData } from '../../../utils/query/useFlatPaginatedData.hook'
import { Filters } from '../../../../model/Filters'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { teamKeys } from './teams.keys'

interface GetInfiniteTeamsParams {
  filters?: Filters
  limit?: number
  enabled?: boolean
}

export const useGetTeams = ({ filters, limit, enabled = true }: GetInfiniteTeamsParams) => {
  const queryKey = useMemo(() => teamKeys.list(filters), [filters])
  const queryClient = useQueryClient()
  const { currentUser } = useContext(ConnectedUserContext)

  const query = useInfiniteQuery<PaginatedList<Team> | undefined>(
    queryKey,
    async ({ pageParam = 1, signal }) => {
      const { data, ok } = await getTeams(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Impossible de récupérer la listes des équipes')
      }
      return data
    },
    {
      getNextPageParam,
      enabled: isAdmin(currentUser) && enabled,
    },
  )

  const teamList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, teamList, paginationState, cancelPendingQuery }
}
