import getApi from '../../api/apisauceConfig'
import { autocompletionPageLimit } from '../../constants'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { deserializePatientTreatmentsPaginatedResponse } from './mapper/patientTreatments.mapper'
import { SerializedTreatment } from './patientTreatments.model'

export const getPatientTreatments = (
  patientId: string,
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = autocompletionPageLimit,
) =>
  getApi()
    .get<PaginatedList<SerializedTreatment>>(
      `/patients/${patientId}/treatments`,
      {
        page,
        limit,
        ...filters,
      },
      { signal },
    )
    .then(deserializePatientTreatmentsPaginatedResponse)
