import { combineReducers } from 'redux'
import { documentAnswerValuesReducer as documentAnswerValues } from './documentAnswerValues/documentAnswerValues.reducer'
import { documentTemplatesReducer as documentTemplates } from './documentTemplates/documentTemplates.reducer'
import { meReducer as me } from './me/me.reducer'
import { codesReducer as codes } from './codes/codes.reducer'
import { DomainState } from './domain.model'
import { externalServicesReducer as externalServices } from './externalServices/externalServices.reducer'
import { documentCategoriesReducer as documentCategories } from './documentCategories/documentCategories.reducer'
import { mailReducer as mail } from './mail/mail.reducer'
import { activePrinciplesDomainReducer as activePrinciples } from './activePrinciples'
import { drugsReducer as drugs } from './drugs/drugs.reducer'
import { posologyReducer as posology } from './posology/posology.reducer'
import { prescriptionTemplateReducer as prescriptionTemplates } from './prescriptionTemplate/prescriptionTemplate.reducer'
import { documentAlertsReducer as documentAlerts } from './documentAlerts/documentAlerts.reducer'
import { indicationsDomainReducer as indications } from './indications'
import { sideEffectsDomainReducer as sideEffects } from './sideEffects'
import { assigningAuthoritiesReducer as assigningAuthorities } from './assigningAuthorities'
import { insiDomainReducer as insi } from './insi/insi.reducer'
import { dmpReducer as dmp } from './dmp'
import { metricsReducer as metrics } from './metrics/metrics.reducer'
import { domainEditorReducer } from './editor/editor.reducer'

export default combineReducers<DomainState>({
  activePrinciples,
  documentAnswerValues,
  documentCategories,
  documentTemplates,
  documentAlerts,
  drugs,
  externalServices,
  editor: domainEditorReducer,
  me,
  mail,
  codes,
  posology,
  prescriptionTemplates,
  indications,
  sideEffects,
  assigningAuthorities,
  insi,
  dmp,
  metrics,
})
