import { FC, useCallback } from 'react'
import { SearchExportFilterDocumentTemplateQuestionProps } from './SearchExportFilterDocumentTemplateQuestion.model'
import { useSetAtom } from 'jotai'
import { exportFilterAddQuestion } from '../../../../state/configurableExport'
import { QuestionTemplate } from '../../../../model/Questionnaire'
import { SearchQuestionBottomPanel } from '../../../shared/bottomPanel/SearchQuestionBottomPanel'

export const SearchExportFilterDocumentTemplateQuestion: FC<
  SearchExportFilterDocumentTemplateQuestionProps
> = ({ open, onRequestClose }) => {
  const selectItem = useSetAtom(exportFilterAddQuestion)

  const handleSelectQuestion = useCallback(
    (question: QuestionTemplate) => {
      selectItem(question)
      onRequestClose()
    },
    [selectItem, onRequestClose],
  )

  return (
    <SearchQuestionBottomPanel
      display={open}
      onRequestClose={onRequestClose}
      onSelect={handleSelectQuestion}
    />
  )
}
