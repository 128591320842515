import React, { FunctionComponent } from 'react'
import { LabeledButton } from '../../shared'
import { QuestionList, QuestionnaireMode } from '../../questionnaire'
import { BaseQuestion, QuestionTemplate } from '../../../model/Questionnaire'
import { FormCard } from './FormCard.component'
import { SetAnswerValue } from '../../../model/AnswerValue'

interface QuestionFormCardProps {
  questions: QuestionTemplate[]
  addQuestion: () => void
  removeQuestion: (id: number) => void
  updateFilterQuestion: (questionId: number, answerValue: SetAnswerValue) => void
}

export const QuestionFormCard: FunctionComponent<QuestionFormCardProps> = ({
  questions,
  addQuestion,
  updateFilterQuestion,
  removeQuestion,
}) => {
  const handleRemove = (question: BaseQuestion) => {
    removeQuestion(question.id)
  }

  const handleEditAnswerValue = (questionId: number, answerValue: SetAnswerValue) => {
    updateFilterQuestion(questionId, answerValue)
  }

  return (
    <FormCard
      title="Question"
      label="Les patients doivent avoir toutes les questions suivantes et celles-ci doivent avoir la valeur spécifiée :"
    >
      <LabeledButton icon="add" label="Question" size="small" onClick={addQuestion} />
      <QuestionList
        mode={QuestionnaireMode.exportForm}
        questions={questions}
        setAnswerValue={handleEditAnswerValue}
        onDelete={handleRemove}
        disabled={false}
      />
    </FormCard>
  )
}
